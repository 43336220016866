import React from "react";
import { fetchGet, objectToQuery } from "utils/functions";
import CenterTableComponent from "../center-table-comp";

function BikeModelTableComponent({ setSelected, contentRefresh }) {
  const defaultModelTitles = new Map([
    ["bike_maker", { title: "제조사", visible: true, sorted: 1 }],
    ["bike_model", { title: "차량 모델", visible: true }],
    ["bike_aged", { title: "차량 연식", visible: true }],
    ["bike_cc", { title: "차량 배기량", visible: true }],
    ["additional_memo", { title: "비고 및 특이사항", visible: false }],
    ["createdAt", { title: "등록일", visible: false }],
    ["_id", { title: "db_id", visible: false, sortable: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "bikeModelTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultModelTitles}
      getDataPromise={async (queryObject) => {
        const query = objectToQuery(queryObject);
        const res = await fetchGet(`/model${query}`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        // console.log(...res.headers);
        header.totalPost = res.headers.get("Total-Post");
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];

          for (const row of data) {
            // reorder
            const newMapped = new Map();
            for await (const key of titleMap.keys()) {
              // process
              if (key === "bike_cc") {
                newMapped.set(key, `${row[key]}cc`);
              } else if (key === "createdAt") {
                newMapped.set(key, row[key].slice(0, 10));
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default BikeModelTableComponent;
