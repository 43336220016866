import React from "react";

const homeLink = "/jinbike";
const baseLink =
  process.env.REACT_APP_MODE === "DEV"
    ? `http://${document.location.hostname}:3333/api`
    : `${document.location.origin}/system/api`;
const fileSizeLimit = 4 * 1024 * 1024; // 4MB

function checkPermission(toCheck) {
  const userType = sessionStorage.getItem("user_type");
  if (userType && !(userType === "ADMIN" || userType === toCheck)) {
    sessionStorage.clear();
    return false;
  }
  return true;
}

function fetchGet(url) {
  return fetch(baseLink + url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
}

function fetchPost(url, data, header, useRawBody) {
  console.log(`${url}\n${JSON.stringify(data)}`);
  return fetch(baseLink + url, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: header ?? {
      "Content-Type": "application/json",
    },
    body: useRawBody ? data : JSON.stringify(data),
  });
}

function fetchPut(url, data) {
  return fetch(baseLink + url, {
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

function fetchDelete(url) {
  return fetch(baseLink + url, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
}

const emptyPromise = (data) =>
  new Promise((res) => {
    res(data);
  });

/**
 *
 * @param id
 * @param options.storageName
 * @param options.getUrl
 * @param options.dbColumn
 * @returns {Promise<string>} name of id from storage or db
 */
async function getNameById(
  id,
  options = {
    storageName: "item-name-storage",
    getUrl: `/item/${id}`,
    dbColumn: "item_name",
  }
) {
  if (id === undefined) return options.alt ?? "-";
  if (id === null) return options.alt ?? "!null!";
  if (id === "") return options.alt ?? "!empty!";

  const names = new Map(
    Object.entries(
      JSON.parse(localStorage.getItem(options.storageName) ?? "{}")
    )
  );

  if (names.has(id)) {
    return names.get(id);
  }
  const res = await fetchGet(options.getUrl);
  if (!res.ok) return options.alt ?? id;
  const json = await res.json();

  if (typeof options.dbColumn === "string") {
    names.set(id, json[options.dbColumn]);
    localStorage.setItem(
      options.storageName,
      JSON.stringify(Object.fromEntries(names.entries()))
    );
  } else if (typeof options.dbColumn === "object" && options.dbColumn.length) {
    const valArr = options.dbColumn.map((ele) => json[ele]);
    const valToSave = valArr.join(" ");
    names.set(id, valToSave);
    localStorage.setItem(
      options.storageName,
      JSON.stringify(Object.fromEntries(names.entries()))
    );
  }

  return names.get(id);
}

const usingStorageForName = [
  "item-name-storage",
  "user-name-storage",
  "customer-name-storage",
  "customer-phone-storage",
  "bike-name-storage",
  "bike-model-storage",
];

function getItemNameById(id) {
  return getNameById(id, {
    storageName: "item-name-storage",
    getUrl: `/item/${id}`,
    dbColumn: "item_name_kr",
  });
}

function getUserNameById(id, alt) {
  return getNameById(id, {
    storageName: "user-name-storage",
    getUrl: `/auth/${id}`,
    dbColumn: "user_name",
    alt,
  });
}

function getCustomerNameById(id, alt) {
  return getNameById(id, {
    storageName: "customer-name-storage",
    getUrl: `/customer/${id}`,
    dbColumn: "customer_name",
    alt,
  });
}

function getCustomerPhoneById(id, alt) {
  return getNameById(id, {
    storageName: "customer-phone-storage",
    getUrl: `/customer/${id}`,
    dbColumn: "customer_phone",
    alt,
  });
}

function getBikeNameById(id, alt) {
  return getNameById(id, {
    storageName: "bike-name-storage",
    getUrl: `/bike/${id}`,
    dbColumn: "bike_name_id",
    alt,
  });
}

function getBikeModelById(id, alt) {
  return getNameById(id, {
    storageName: "bike-model-storage",
    getUrl: `/bike/${id}`,
    dbColumn: "model_info",
    alt,
  }).then((modelInfo) => {
    console.log(modelInfo);
    return `${modelInfo.bike_model} ${modelInfo.bike_aged}`;
  });
}

function getAccCompanyById(id, alt) {
  return getNameById(id, {
    storageName: "acc-comp-storage",
    getUrl: `/acc/${id}`,
    dbColumn: "acc_insurance_company",
    alt,
  });
}

function mapToArrayObject(map) {
  const output = [];
  for (const [key, value] of map) {
    output.push({ key, value });
  }
  return output;
}

function arrayObjectToMap(array) {
  const output = new Map();
  for (const { key, value } of array) {
    output.set(key, value);
  }
  return output;
}

function saveTitleMapData(storageName, map) {
  console.log(map);
  localStorage.setItem(storageName, JSON.stringify(mapToArrayObject(map)));
}

function loadTitleMapData(storageName) {
  return arrayObjectToMap(
    JSON.parse(localStorage.getItem(storageName) ?? "[]")
  );
}

function objectToQuery(payload) {
  if (Object.keys(payload).length === 0) return "";
  return `?${Object.entries(payload)
    .map((e) => e.join("="))
    .join("&")}`;
}

function queryToObject(queryString) {
  const result = {};
  const entries = new URLSearchParams(queryString).entries();
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const fixTypeArr = [
  "TYPE_NORMAL",
  "TYPE_MAINTAIN",
  "TYPE_ACCIDENT",
  "TYPE_GUARANTEE",
];

const fixSubTypeArr = [
  "SUB_CHANGE",
  "SUB_FIX",
  "SUB_MAINTAIN",
  "SUB_TACH",
  "SUB_OVERHAUL",
];

const fixTypeKorDictBase = {
  TYPE_NORMAL: "일반정비",
  TYPE_GUARANTEE: "보증AS",
  TYPE_MAINTAIN: "운영리스정비",
  TYPE_ACCIDENT: "사고",
  SUB_CHANGE: "교환",
  SUB_FIX: "수리",
  SUB_MAINTAIN: "점검",
  SUB_TACH: "탈부착",
  SUB_OVERHAUL: "오버홀",
};

const fixTypeKorDict = (() => {
  const toReturn = { ...fixTypeKorDictBase };

  for (const [_, value] of Object.entries(fixTypeKorDictBase)) {
    toReturn[value] = value;
  }

  return toReturn;
})();

const fixTypeEngDictBase = {
  일반정비: "TYPE_NORMAL",
  보증AS: "TYPE_GUARANTEE",
  운영리스정비: "TYPE_MAINTAIN",
  사고: "TYPE_ACCIDENT",
  교환: "SUB_CHANGE",
  수리: "SUB_FIX",
  탈부착: "SUB_TACH",
  오버홀: "SUB_OVERHAUL",
  점검: "SUB_MAINTAIN",
};

const fixTypeEngDict = (() => {
  const toReturn = { ...fixTypeEngDictBase };

  for (const [_, value] of Object.entries(fixTypeEngDictBase)) {
    toReturn[value] = value;
  }

  return toReturn;
})();

const imageIdKorDict = {
  front: "전방",
  back: "후방",
  left: "좌측",
  right: "우측",
  dashboard: "계기판",
  part1: "부품사진1",
  part2: "부품사진2",
  part3: "부품사진3",
};

const leaseTypeKorDict = {
  NORMAL: "일반",
  LEASE: "리스",
};

function generateGuid(length = 32) {
  let guid = "";
  for (let i = 0; i < length; i += 1) {
    guid += Math.floor(Math.random() * 16).toString(16);
  }
  return guid;
}

function createEnteredText(stringArr = []) {
  const returnArr = [];
  let i = 0;
  for (i = 0; i < stringArr.length - 1; i += 1) {
    returnArr.push(stringArr[i]);
    returnArr.push(<br key={i} />);
  }
  returnArr.push(stringArr[i]);

  return returnArr;
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "toMobileString", {
  value() {
    if (this.length === 0) return "";
    if (this.startsWith("02")) {
      // 서울 02
      if (this.length === 9) {
        return `${this.slice(0, 2)}-${this.slice(2, 5)}-${this.slice(5)}`;
      }
      if (this.length === 10) {
        return `${this.slice(0, 2)}-${this.slice(2, 6)}-${this.slice(6)}`;
      }
    }
    if (this.length < 11) {
      // 기타 지역 전화번호의 경우
      return `${this.slice(0, 3)}-${this.slice(3, 6)}-${this.slice(6)}`;
    }
    return `${this.slice(0, 3)}-${this.slice(3, 7)}-${this.slice(7)}`;
  },
});

/**
 * Check input is bike number (지역+지역구+가+0000)
 * @example
 * "서울강남나1234" -> true
 * "광주서다4321" -> true
 * @param {string} numberStr
 * @returns is input is bike number
 */
function isBikeNumber(numberStr) {
  const carIdRegexs = [
    /^[가-힣]{5}\d{4}$/,
    /^[가-힣]{4}\d{4}$/,
    /^[가-힣]{6}\d{4}$/,
  ];
  return carIdRegexs.some((e) => e.test(numberStr));
}

export {
  homeLink,
  baseLink,
  checkPermission,
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  emptyPromise,
  usingStorageForName,
  getItemNameById,
  getUserNameById,
  getCustomerNameById,
  getCustomerPhoneById,
  getAccCompanyById,
  getBikeNameById,
  getBikeModelById,
  mapToArrayObject,
  arrayObjectToMap,
  saveTitleMapData,
  loadTitleMapData,
  objectToQuery,
  queryToObject,
  fixTypeArr,
  fixSubTypeArr,
  fixTypeKorDict,
  fixTypeEngDict,
  fileSizeLimit,
  imageIdKorDict,
  leaseTypeKorDict,
  generateGuid,
  createEnteredText,
  isBikeNumber,
};
