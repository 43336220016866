import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Stack, Table, Modal } from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { utils, writeFileXLSX } from "xlsx";
import {
  createEnteredText,
  emptyPromise,
  loadTitleMapData,
  saveTitleMapData,
} from "utils/functions";

/**
 *
 * @param {*} tableMetadata.name table name
 * @param tableMetadata.paginationOption 0- 불가능, 1- pagination만 가능, 2- 둘 다 가능
 * @returns
 */
function CenterTableComponent({
  tableMetadata = {
    name: "",
    paginationOption: 0,
  },
  setSelected,
  matchedTitle = new Map(),
  alteredTitle = new Map(),
  getDataPromise = emptyPromise,
  processPromise = emptyPromise,
  contentRefresh = {},
}) {
  const [ready, setReady] = useState(false);
  const [titleData, setTitleData] = useState(matchedTitle);
  const [titleJSX, setTitleArray] = useState([]);
  const [dataJSX, setDataArray] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [outputData, setOutputData] = useState([]);
  const [searchValue, setSearchValue] = useState({
    title: "선택",
    option: "",
    value: "",
  });
  const tableRef = useRef(null);
  const [showColumnListModal, setShowColumnListModal] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(true); // 1: 오름차순, 2: 내림차순
  const [usePagination, setPagination] = useState(false);
  const [page, setPage] = useState(0);
  const [postCount, setPostCount] = useState(-1);
  const [pageInputValue, setPageInputValue] = useState(page);

  const contentsOnPage = 20;

  function getPagenated() {
    if (!tableMetadata.paginationOption) {
      return false;
    }
    if (tableMetadata.paginationOption === 1) {
      return true;
    }
    const stored = localStorage.getItem(`${tableMetadata.name}-pagenated`);
    if (stored === null) {
      localStorage.setItem(`${tableMetadata.name}-pagenated`, true);
      return true;
    }
    return stored === "true";
  }

  function handleRowClick(event, value) {
    setSelected(value);
    console.log(value);
  }

  function rowSort(val1, val2) {
    if (val1 === undefined || val2 === undefined) return 0;
    // eslint-disable-next-line no-restricted-globals
    const type = isNaN(val1) ? "string" : "number";
    switch (type) {
      case "number":
        return sortOrder > 1
          ? parseFloat(val2, 10) - parseFloat(val1, 10)
          : parseFloat(val1, 10) - parseFloat(val2, 10);
      case "string":
        return sortOrder > 1
          ? val2.toString().localeCompare(val1.toString())
          : val1.toString().localeCompare(val2.toString());
      default:
        return 0;
    }
  }

  useEffect(() => {
    // load title data
    let loadedTitleData = loadTitleMapData(tableMetadata.name);
    if (loadedTitleData.size === 0) {
      loadedTitleData = matchedTitle;
      saveTitleMapData(tableMetadata.name, loadedTitleData);
    }
    setTitleData(loadedTitleData);
    loadedTitleData.forEach((val, key) => {
      if (val.sorted > 0) {
        setSortKey(key);
        setSortOrder(val.sorted);
      }
    });
    setPagination(getPagenated());
    setPage(1);
    setPageInputValue(1);
    setReady(true);
  }, []);

  function fetchAction(reset = false) {
    if (!ready) return;
    if (!titleData) return;

    setSelected(new Map());

    const queryObject = {};
    if (usePagination) {
      queryObject.page = reset ? 1 : page;
      if (searchValue.option && !reset) {
        queryObject[searchValue.option] = searchValue.value;
      }
    }

    // load data
    getDataPromise(queryObject)
      .then(({ header, data }) => {
        if (header.total) setPostCount(parseInt(header.total, 10));
        // [{}, {}, ...]
        setFullData(data);
        return processPromise(data, titleData);
      })
      .then((processed) => {
        // [[[], [], ...], [], ...] : list of maps
        processed.sort((v1, v2) => rowSort(v1.get(sortKey), v2.get(sortKey)));
        return setOutputData([...processed]);
      })
      .catch((err) => {
        console.error(err);
        setFullData([]);
        setOutputData([]);
      });
  }

  function refreshData() {
    processPromise(fullData, titleData).then((data) => {
      if (!searchValue.value || !searchValue.option) {
        data.sort((v1, v2) => rowSort(v1.get(sortKey), v2.get(sortKey)));
        setOutputData(data);
        return;
      }
      const filtered = data.filter((row) => {
        return row.get(searchValue.option)?.includes(searchValue.value);
      });
      filtered.sort((v1, v2) => rowSort(v1.get(sortKey), v2.get(sortKey)));
      setOutputData(filtered);
    });
  }

  useEffect(fetchAction, [usePagination, page]);

  function onTitleClicked(key, clickedTitle) {
    // console.log(clickedTitle);
    if (clickedTitle.sortable === false) return;

    const newTitleData = new Map(titleData);
    if (sortKey !== key) {
      newTitleData.set(sortKey, {
        ...newTitleData.get(sortKey),
        sorted: 0,
      });
      newTitleData.set(key, {
        ...clickedTitle,
        sorted: 1,
      });
      setSortKey(key);
      setSortOrder(1);
    } else {
      const newSortOrder = (clickedTitle.sorted + 1) % 3;
      newTitleData.set(sortKey, {
        ...newTitleData.get(sortKey),
        sorted: newSortOrder,
      });
      setSortKey(newSortOrder === 0 ? "" : key);
      setSortOrder(newSortOrder);
    }
    setTitleData(newTitleData);
    saveTitleMapData(tableMetadata.name, newTitleData);
  }

  function getSortedIcon(sortedVal) {
    switch (sortedVal) {
      case 1: // 오름차순
        return " ⩚";
      case 2: // 내림차순
        return " ⩛";
      default:
        return " ";
    }
  }

  useEffect(() => {
    const newTitleArray = [];
    titleData.forEach((val, key) => {
      if (val.visible) {
        const { title } = val;
        if (typeof title === "object") {
          newTitleArray.push(
            <th
              key={`${val.title[0] + val.title[1]}header`}
              className="color-on-hover"
              onClick={() => {
                onTitleClicked(key, val);
              }}
            >
              {createEnteredText(val.title)}
              {getSortedIcon(val.sorted)}
            </th>
          );
        } else {
          newTitleArray.push(
            <th
              key={`${val.title}header`}
              className="color-on-hover"
              onClick={() => {
                onTitleClicked(key, val);
              }}
            >
              {val.title}
              {getSortedIcon(val.sorted)}
            </th>
          );
        }
      }
    });
    setTitleArray(newTitleArray);
    refreshData();
  }, [titleData]);

  useEffect(() => {
    refreshData();
  }, [sortKey, sortOrder]);

  useEffect(() => {
    if (!usePagination) refreshData();
  }, [searchValue]);

  useEffect(() => {
    if (contentRefresh._id) {
      const changed = fullData.findIndex(
        (ele) => ele._id === contentRefresh._id
      );
      if (changed !== -1) {
        fullData[changed] = { ...fullData[changed], ...contentRefresh };
      }
      setSelected(new Map());
      setSelectedTarget(null);
      refreshData();
    }
  }, [contentRefresh]);

  useEffect(() => {
    setDataArray(
      outputData.map((rowVal) => (
        <tr
          className={selectedTarget === rowVal.get("_id") ? "selected-row" : ""}
          key={rowVal.get("_id")}
          onClick={(e) => {
            setSelectedTarget(rowVal.get("_id"));
            handleRowClick(
              e,
              new Map(
                Object.entries(
                  fullData.find((data) => data._id === rowVal.get("_id"))
                )
              )
            );
          }}
        >
          {(() => {
            const innerDataJSX = [];
            rowVal.forEach((val, key) => {
              if (titleData.get(key)?.visible) {
                innerDataJSX.push(
                  <td
                    key={`${val + key}val`}
                    className={
                      titleData.get(key)?.leftAlign ? "text-start" : ""
                    }
                  >
                    {val}
                  </td>
                );
              }
            });
            return innerDataJSX;
          })()}
        </tr>
      ))
    );
  }, [outputData, titleData, selectedTarget]);

  return (
    <>
      <Stack direction="horizontal" gap={2} className="text-nowrap mb-3 mt-3">
        <Dropdown>
          <Dropdown.Toggle>{searchValue.title}</Dropdown.Toggle>

          <Dropdown.Menu>
            {Array.from(titleData.entries()).map(
              ([key, val]) =>
                (!usePagination || !val.generated) && (
                  <Dropdown.Item
                    key={key}
                    onClick={() =>
                      setSearchValue((prev) => {
                        return {
                          ...prev,
                          option: alteredTitle.get(key) || key,
                          title: val.title,
                          value: "",
                        };
                      })
                    }
                  >
                    {val.title}
                  </Dropdown.Item>
                )
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Control
          value={searchValue.value}
          onChange={(e) =>
            setSearchValue((prev) => {
              return { ...prev, value: e.target.value };
            })
          }
          onKeyDownCapture={(e) => {
            if (!usePagination) return;
            if (e.key === "Enter") {
              if (usePagination) fetchAction();
              setPage(1);
              setPageInputValue(1);
            }
          }}
        />
        {usePagination && (
          <>
            <Button
              onClick={() => {
                setPage(1);
                setPageInputValue(1);
                if (usePagination) fetchAction();
              }}
            >
              검색
            </Button>
            <Button
              onClick={() => {
                setSearchValue({
                  title: "선택",
                  option: "",
                  value: "",
                });
                setPage(1);
                setPageInputValue(1);
                if (usePagination) fetchAction(true);
              }}
            >
              전체
            </Button>
          </>
        )}
        <div>{usePagination ? postCount : outputData.length}개</div>
        <Button
          id="downloadXlsxButton"
          onClick={(e) => {
            e.preventDefault();

            const elt = tableRef.current;
            const wb = utils.table_to_book(elt, { raw: true });
            writeFileXLSX(wb, "SheetJSReactHTML.xlsx");
          }}
        >
          엑셀 다운로드
        </Button>
        <Button
          id="columnListModalButton"
          onClick={() => {
            setShowColumnListModal(true);
          }}
        >
          컬럼 선택
        </Button>
      </Stack>

      <div className="table-fixed">
        <Table size="sm" striped hover ref={tableRef}>
          <thead>
            <tr>{titleJSX}</tr>
          </thead>
          <tbody>{dataJSX}</tbody>
        </Table>
      </div>

      {usePagination && (
        <Stack className="mx-auto" direction="horizontal" gap={3}>
          <Button
            onClick={() => {
              if (
                page - 1 < 1 ||
                page - 1 > Math.ceil(postCount / contentsOnPage)
              ) {
                alert("유효하지 않은 페이지입니다");
                return null;
              }
              setPageInputValue(page - 1);
              setPage(page - 1);
            }}
          >
            &lt;
          </Button>
          <Form.Control
            className="w-25 text-center"
            type="number"
            value={pageInputValue}
            onChange={(e) => {
              setPageInputValue(e.target.value);
            }}
          />
          <div>/ {Math.ceil(postCount / contentsOnPage)}</div>
          <Button
            onClick={() => {
              if (
                pageInputValue < 1 ||
                pageInputValue > Math.ceil(postCount / contentsOnPage)
              ) {
                alert("유효하지 않은 페이지입니다");
                setPageInputValue(page);
                return null;
              }
              setPage(pageInputValue);
            }}
          >
            이동
          </Button>
          <Button
            onClick={() => {
              if (
                page + 1 < 1 ||
                page + 1 > Math.ceil(postCount / contentsOnPage)
              ) {
                alert("유효하지 않은 페이지입니다");
                return null;
              }
              setPageInputValue(page + 1);
              setPage(page + 1);
            }}
          >
            &gt;
          </Button>
        </Stack>
      )}

      <Modal
        backdrop="static"
        id="columnListModal"
        show={showColumnListModal}
        onHide={() => setShowColumnListModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>컬럼 목록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack className="mb-2 text-nowrap" gap={2} direction="horizontal">
            {tableMetadata.paginationOption === 2 && (
              <Button
                onClick={() => {
                  localStorage.setItem(
                    `${tableMetadata.name}-pagenated`,
                    !usePagination
                  );
                  setPagination(!usePagination);
                }}
              >
                {usePagination ? `${contentsOnPage}개씩` : "전체보기"}
              </Button>
            )}
            <Button
              onClick={() => {
                setTitleData(matchedTitle);
                saveTitleMapData(tableMetadata.name, matchedTitle);
              }}
            >
              컬럼 초기화
            </Button>
            <Button
              onClick={() => {
                const newTitleData = new Map(titleData);
                newTitleData.forEach((val) => {
                  val.visible = true;
                });
                setTitleData(newTitleData);
              }}
            >
              모두 선택
            </Button>
            <Button
              onClick={() => {
                const newTitleData = new Map(titleData);
                newTitleData.forEach((val) => {
                  val.visible = false;
                });
                setTitleData(newTitleData);
              }}
            >
              모두 해제
            </Button>
          </Stack>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>컬럼명</th>
                <th>표시 여부</th>
                <th colSpan={2}>위치 조정</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(titleData.entries()).map(([key, val]) => (
                <tr key={key}>
                  <td>{val.title}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={key}
                      label=""
                      checked={val.visible}
                      onChange={(e) => {
                        const newTitleData = new Map(titleData);
                        newTitleData.set(key, {
                          ...val,
                          visible: e.target.checked,
                        });
                        setTitleData(newTitleData);
                        saveTitleMapData(tableMetadata.name, newTitleData);
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        const newTitleData = new Map(titleData);
                        const newTitleArray = Array.from(
                          newTitleData.entries()
                        );
                        const index = newTitleArray.findIndex(
                          ([k, v]) => k === key && v
                        );
                        if (index === 0) return;
                        const temp = newTitleArray[index - 1];
                        newTitleArray[index - 1] = newTitleArray[index];
                        newTitleArray[index] = temp;
                        setTitleData(new Map(newTitleArray));
                        saveTitleMapData(
                          tableMetadata.name,
                          new Map(newTitleArray)
                        );
                      }}
                    >
                      <CaretUpFill />
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        const newTitleData = new Map(titleData);
                        const newTitleArray = Array.from(
                          newTitleData.entries()
                        );
                        const index = newTitleArray.findIndex(
                          ([k, v]) => k === key && v
                        );
                        if (index === newTitleArray.length - 1) return;
                        const temp = newTitleArray[index + 1];
                        newTitleArray[index + 1] = newTitleArray[index];
                        newTitleArray[index] = temp;
                        setTitleData(new Map(newTitleArray));
                        saveTitleMapData(
                          tableMetadata.name,
                          new Map(newTitleArray)
                        );
                      }}
                    >
                      <CaretDownFill />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CenterTableComponent;
