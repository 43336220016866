import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "utils/functions";

function BikeModelActionComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
  children,
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);

  const [bikeMakerList, setBikeMakerList] = useState([]);
  const [bikeAgedList, setBikeAgedList] = useState([]);

  function handleFormValueChangeBase(name, value) {
    setFormValues((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function handleFormValueChange(e) {
    handleFormValueChangeBase(e.target.name, e.target.value);
  }

  useEffect(() => {
    fetchGet("/keyword/byg/모델-제조사")
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((makerList) => {
        setBikeMakerList(makerList);
      })
      .catch((e) => {
        console.error(e);
      });

    fetchGet("/keyword/byg/모델-연식")
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((agedList) => {
        setBikeAgedList(agedList);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setItemSelected(selected.has("_id"));
  }, [selected]);

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    if (
      !formValues.bike_maker ||
      formValues.bike_maker.length === 0 ||
      !formValues.bike_model ||
      formValues.bike_model.length === 0 ||
      !formValues.bike_aged
    ) {
      alert("제조사, 차량모델, 연식은 반드시 채워져야 합니다");
      return;
    }

    (showFix
      ? fetchPut(`/model/${selected.get("_id")}`, formValues)
      : fetchPost("/model", formValues)
    )
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("완료되었습니다");
        setAdd(false);
        setFix(false);
        if (showFix) contentRefresh(formValues);
        else navigate(0);
      })
      .catch((err) => console.error(err));
  }

  function handleRemoveButtonClick(e) {
    e.preventDefault();

    if (
      window.confirm(
        `해당 ${selected.get(
          "bike_model"
        )} 모델을 삭제하시겠습니까? 연관된 모든 정비 그룹 및 품목이 삭제됩니다`
      )
    ) {
      fetchDelete(`/model/${selected.get("_id")}`).then((res) => {
        if (res.ok) {
          alert("삭제되었습니다");
          navigate(0);
          return;
        }
        alert("삭제에 실패했습니다. 관리자에게 문의해주세요");
      });
    }
  }

  return (
    <>
      {children}
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button
              variant="primary"
              onClick={() => {
                setAdd(true);
                setFormValues({});
              }}
            >
              등록
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => {
                setFix(true);
                // selected to formValues
                setFormValues(Object.fromEntries(selected));
              }}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleRemoveButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {
        // #region add modal
      }
      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>차량 모델 등록하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>제조사</Form.Label>
              <Stack gap={3} direction="horizontal">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    제조사 선택
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {bikeMakerList.map((ele) => (
                      <Dropdown.Item
                        key={ele}
                        onClick={() => {
                          handleFormValueChangeBase("bike_maker", ele);
                        }}
                      >
                        {ele}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                  disabled
                  name="bike_maker"
                  onChange={handleFormValueChange}
                  value={formValues.bike_maker ?? ""}
                  placeholder="좌측에서 선택해주세요"
                />
              </Stack>
            </Form.Group>

            <Form.Group>
              <Form.Label>차량 모델</Form.Label>
              <Form.Control
                name="bike_model"
                onChange={handleFormValueChange}
                value={formValues.bike_model ?? ""}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>연식</Form.Label>
              <Stack gap={3} direction="horizontal">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    연식 선택
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {bikeAgedList.map((ele) => (
                      <Dropdown.Item
                        key={ele}
                        onClick={() => {
                          handleFormValueChangeBase("bike_aged", ele);
                        }}
                      >
                        {ele}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                  disabled
                  name="bike_aged"
                  value={formValues.bike_aged ?? ""}
                  placeholder="좌측에서 선택해주세요"
                />
              </Stack>
            </Form.Group>

            <Form.Group>
              <Form.Label>배기량</Form.Label>
              <Form.Control
                type="number"
                name="bike_cc"
                onChange={handleFormValueChange}
                value={formValues.bike_cc ?? 0}
              />
            </Form.Group>
          </Form>

          <Stack className="mt-4" direction="horizontal">
            <div className="me-auto" />
            <Button onClick={handleSubmitButtonClick}>등록하기</Button>
          </Stack>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {
        // #region fix modal
      }
      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>차량 모델 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>제조사</Form.Label>
              <Stack gap={3} direction="horizontal">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    제조사 선택
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {bikeMakerList.map((ele) => (
                      <Dropdown.Item
                        key={ele}
                        onClick={() => {
                          handleFormValueChangeBase("bike_maker", ele);
                        }}
                      >
                        {ele}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                  disabled
                  name="bike_maker"
                  onChange={handleFormValueChange}
                  value={formValues.bike_maker ?? ""}
                  placeholder="왼쪽에서 선택해주세요"
                />
              </Stack>
            </Form.Group>

            <Form.Group>
              <Form.Label>차량 모델</Form.Label>
              <Form.Control
                name="bike_model"
                onChange={handleFormValueChange}
                value={formValues.bike_model ?? ""}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>연식</Form.Label>
              <Stack gap={3} direction="horizontal">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    연식 선택
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {bikeAgedList.map((ele) => (
                      <Dropdown.Item
                        key={ele}
                        onClick={() => {
                          handleFormValueChangeBase("bike_aged", ele);
                        }}
                      >
                        {ele}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                  disabled
                  name="bike_aged"
                  value={formValues.bike_aged ?? ""}
                  placeholder="좌측에서 선택해주세요"
                />
              </Stack>
            </Form.Group>

            <Form.Group>
              <Form.Label>배기량</Form.Label>
              <Form.Control
                type="number"
                name="bike_cc"
                onChange={handleFormValueChange}
                value={formValues.bike_cc ?? 0}
              />
            </Form.Group>
          </Form>

          <Stack className="mt-4" direction="horizontal">
            <div className="me-auto" />
            <Button onClick={handleSubmitButtonClick}>수정하기</Button>
          </Stack>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

export default BikeModelActionComponent;
