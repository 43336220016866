import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete } from "utils/functions";
import LogEditFormComponent from "./logedit-form-comp";
import LogDetailComponent from "./logdetail-comp";

/**
 *
 * @param {Map} props.selected selected with default new Map()
 * @param {JSX} props.children Title jsx
 * @returns
 */
function CenterLogActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
  children,
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showFix, setFix] = useState(false);
  const [showDetail, setDetail] = useState(false);

  useEffect(() => {
    setItemSelected(selected.has("bike_id"));
  }, [selected]);

  function handleDeleteButtonClick() {
    if (
      window.confirm(`${selected.get("_id")}를 제거합니다.\n계속 하시겠습니까?`)
    ) {
      fetchDelete(`/log/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            navigate(0);
            alert(`정비 기록이 삭제되었습니다 (id: ${selected.get("_id")})`);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  return (
    <>
      {children}
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setDetail(true)}
            >
              자세히
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setFix(true)}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {/** fix log modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showFix}
        onHide={() => setFix(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>정비 이력 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LogEditFormComponent
            onSubmit={(formValues) => {
              setFix(false);
              contentRefresh(formValues);
            }}
            logId={selected.get("_id")}
          >
            <Button variant="secondary" onClick={() => setFix(false)}>
              닫기
            </Button>
          </LogEditFormComponent>
        </Modal.Body>
      </Modal>

      {/** detail log modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showDetail}
        onHide={() => setDetail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>정비 이력 자세히보기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LogDetailComponent
            logId={selected.get("_id")}
            initLogData={{
              ...Object.fromEntries(selected),
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CenterLogActionsComponent;
