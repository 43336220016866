import React from "react";
import { fetchGet, objectToQuery } from "utils/functions";
import CenterTableComponent from "../center-table-comp";

function CenterUserTableComponent({ setSelected, contentRefresh }) {
  // {title, visible, alteredTitle, hiddenTitle}
  const defaultUserTitles = new Map([
    ["user_type", { title: "계정 타입", visible: true }],
    // 관리자ADMIN / 본사CENTER / 가맹점FRANCH / 리스사LEASE / B2B-BUSINESS
    ["user_id", { title: "ID", visible: true }],
    ["user_name", { title: "이름", visible: true }],
    ["user_location", { title: "지역", visible: true }],
    ["user_ceo", { title: "대표자", visible: true }],
    ["user_mobile", { title: "휴대폰", visible: true }],
    ["user_call", { title: "연락처", visible: false }],
    ["user_email", { title: "이메일", visible: false }],
    ["user_operator", { title: "실운영자", visible: true }],
    ["user_operator_call", { title: "실운영자 연락처", visible: true }],
    ["user_company_number", { title: "사업자 등록번호", visible: false }],
    ["user_address", { title: "주소", visible: false }],
    ["createdAt", { title: "계정 등록일", visible: false }],
    ["user_contact_start_date", { title: "가맹계약 체결일", visible: false }],
    ["user_contact_end_date", { title: "가맹계약 종료일", visible: false }],
    ["user_expire_date", { title: "계정 종료일", visible: false }],
    ["user_expire_reason", { title: "계약 해지 사유", visible: false }],
    ["user_lease_info", { title: "연결리스사 정보", visible: false }],
    ["user_status", { title: "계정 상태", visible: true }], // 대기WAIT/운영OPER/종료END
    ["document_active", { title: "활성화 상태", visible: true }],
    ["additional_memo", { title: "비고", visible: false }],
    ["_id", { title: "db_id", visible: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "centerUserTable", paginationOption: 2 }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultUserTitles}
      getDataPromise={async (queryObject) => {
        const query = objectToQuery(queryObject);
        const res = await fetchGet(`/auth${query}`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        // console.log(...res.headers);
        header.total = res.headers.get("Total-Post");
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];

          for await (const row of data) {
            // reorder
            const newMapped = new Map();
            for (const key of titleMap.keys()) {
              // process
              if (!titleMap.get(key).generated && row[key] === undefined) {
                newMapped.set(key, "-");
              } else if (key === "user_type") {
                if (row[key] === "ADMIN") newMapped.set(key, "관리자");
                else if (row[key] === "CENTER") newMapped.set(key, "본사");
                else if (row[key] === "FRANCH") newMapped.set(key, "가맹점");
                else if (row[key] === "LEASE") newMapped.set(key, "리스사");
                else if (row[key] === "BUSINESS") newMapped.set(key, "B2B");
                else newMapped.set(key, `!undefined_data!\n${row[key]}`);
              } else if (key === "user_status") {
                if (row[key] === "WAIT") newMapped.set(key, "대기");
                else if (row[key] === "OPER") newMapped.set(key, "운영");
                else if (row[key] === "END") newMapped.set(key, "종료");
                else newMapped.set(key, `!undefined_data!\n${row[key]}`);
              } else if (key === "createdAt") {
                newMapped.set(key, row[key].split("T")[0]);
              } else if (
                key === "user_expire_date" ||
                key === "user_contact_start_date" ||
                key === "user_contact_end_date"
              ) {
                if (row[key]) {
                  newMapped.set(key, row[key].split("T")[0]);
                } else {
                  newMapped.set(key, "0000-00-00");
                }
              } else if (key === "document_active") {
                newMapped.set(key, row[key] ? "O" : "X");
              } else if (
                key === "user_mobile" ||
                key === "user_call" ||
                key === "user_operator_call"
              ) {
                newMapped.set(key, row[key].toMobileString());
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default CenterUserTableComponent;
