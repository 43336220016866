import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "react-bootstrap";

function SidebarButton({ name, link, selected, children }) {
  const navigate = useNavigate();

  function handleButtonClick(event) {
    event.preventDefault();

    navigate(event.target.name, { replace: false });
  }

  return (
    <Button
      variant={selected !== name ? "outline-primary" : "primary"}
      onClick={handleButtonClick}
      name={link}
    >
      {children}
    </Button>
  );
}

function CenterSidebarComponent({ selected }) {
  return (
    <Stack gap={3}>
      <SidebarButton name="main" link="/c/main" selected={selected}>
        통계
      </SidebarButton>
      <SidebarButton name="bike" link="/c/table/bike" selected={selected}>
        차량
      </SidebarButton>
      <SidebarButton name="log" link="/c/table/log" selected={selected}>
        정비 이력
      </SidebarButton>
      <SidebarButton name="user" link="/c/table/user" selected={selected}>
        유저
      </SidebarButton>
      <SidebarButton name="acc" link="/c/table/acc" selected={selected}>
        사고
      </SidebarButton>
      <SidebarButton
        name="customer"
        link="/c/table/customer"
        selected={selected}
      >
        고객
      </SidebarButton>
      <hr />
      <SidebarButton name="keyword" link="/c/table/keyword" selected={selected}>
        대분류
      </SidebarButton>
      <SidebarButton name="model" link="/c/table/model" selected={selected}>
        차량 모델
      </SidebarButton>
      <SidebarButton name="igroup" link="/c/table/igroup" selected={selected}>
        품목 분류
      </SidebarButton>
      <SidebarButton name="item" link="/c/table/item" selected={selected}>
        정비 품목
      </SidebarButton>
      <hr />
      <SidebarButton
        name="announce"
        link="/c/table/announce"
        selected={selected}
      >
        공지사항
      </SidebarButton>
      <SidebarButton name="settings" link="/c/settings" selected={selected}>
        설정
      </SidebarButton>
    </Stack>
  );
}

export default CenterSidebarComponent;
