import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function CustomerDetailTableComponent({ customerId, dataObject }) {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>소유주</th>
          <th>사용자</th>
          <th>차대번호</th>
          <th>차량번호</th>
          <th>db_id</th>
        </tr>
      </thead>
      <tbody>
        {dataObject.v_assigned_bikes.map((e) => (
          <tr key={e._id}>
            <td className="text-center">
              {e.contractor_cid === customerId ? "V" : ""}
            </td>
            <td className="text-center">
              {e.customer_cid === customerId ? "V" : ""}
            </td>
            <td>{e.bike_uniq_id}</td>
            <td>{e.bike_name_id}</td>
            <td>{e._id}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default CustomerDetailTableComponent;
