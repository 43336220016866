import React from "react";
import CenterTableComponent from "component/center-table-comp";
import { fetchGet } from "utils/functions";

function AccidentTableComponent({ setSelected, contentRefresh }) {
  const defaultAccidentTitles = new Map([
    ["createdAt", { title: "문서생성일", visible: true }],
    ["acc_date", { title: "사고일시", visible: true }],
    ["created_user_id", { title: "생성가맹점ID", visible: false }],
    ["user_name", { title: "생성가맹점", visible: true, generated: true }],
    ["acc_bike_id", { title: "차량ID", visible: false }],
    ["bike_name_id", { title: "차량번호", visible: true, generated: true }],
    ["bike_uniq_id", { title: "차대번호", visible: true, generated: true }],
    ["acc_insurance_company", { title: "보험사", visible: true }],
    ["acc_receipt_id", { title: "접수번호", visible: true }],
    ["acc_insurance_oper", { title: "담당자명", visible: true }],
    ["acc_insurance_call", { title: "연락처", visible: true }],
    ["acc_insurance_fax", { title: "팩스", visible: true }],
    ["acc_insurance_email", { title: "이메일", visible: true }],
    ["acc_memo", { title: "메모", visible: true }],
    ["additional_memo", { title: "비고", visible: true }],
    ["_id", { title: "사고 코드(db_id)", visible: true }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "centerAccidentTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultAccidentTitles}
      getDataPromise={async () => {
        const res = await fetchGet(`/acc`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];
          for (const row of data) {
            const newMapped = new Map();
            for (const key of titleMap.keys()) {
              // process
              if (!titleMap.get(key).generated && row[key] === undefined) {
                newMapped.set(key, "-");
              } else if (key === "acc_date") {
                newMapped.set(key, row[key]?.slice(0, 16).replace("T", " / "));
              } else if (key === "createdAt") {
                newMapped.set(key, new Date(row[key]).toLocaleDateString());
              } else if (key === "user_name") {
                newMapped.set(key, row.user_info?.user_name ?? "");
              } else if (key === "bike_name_id" || key === "bike_uniq_id") {
                newMapped.set(key, row?.bike_info ? row?.bike_info[key] : "");
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default AccidentTableComponent;

/**
 * 
 * 
  acc_date: { type: Date }, // 사고일
  acc_insurance_company: { type: String }, // 보험사
  acc_receipt_id: { type: String }, // 접수번호
  acc_insurance_oper: { type: String }, // 보험사 담당자명
  acc_insurance_call: { type: String }, // 보험사 연락처
  acc_insurance_fax: { type: String }, // 보험사 팩스
  acc_memo: { type: String }, // 메모사항

  additional_memo: { type: String }, // 비고
 */
