/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import FranchLogPreviewComponent from "component/log/franch-log-preview-comp";
import LogDetailComponent from "component/log/logdetail-comp";
import BikeDetailTableComponent from "component/bike/bikedetail-table-comp";
import LogEditFormComponent from "component/log/logedit-form-comp";
import {
  checkPermission,
  fetchGet,
  fetchPut,
  fixTypeKorDict,
  getCustomerNameById,
  getCustomerPhoneById,
  getUserNameById,
} from "utils/functions";
import CustomerAddFormComponent from "component/customer/customeradd-form-comp";

function FranchBike() {
  const { bike_id } = useParams();
  const navigate = useNavigate();
  const [bikeInfo, setBikeInfo] = useState({});
  const [logsCont, setCont] = useState([]);
  const [selected, setSelected] = useState({});

  const [showDetail, setDetail] = useState(false);
  const [showFix, setFix] = useState(false);

  const [showBikeDetail, setBikeDetail] = useState(false);

  const [showAssign, setAssign] = useState(false);
  const [showAssignContractor, setAssignContractor] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [foundCustomersList, setCustomersList] = useState([]);
  const [foundCustomer, setCustomerData] = useState({});

  const [showCusAddModal, setCusAddModal] = useState(false);

  useEffect(() => {
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }

    fetchGet(`/bike/${bike_id}`)
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(await res.text());
      })
      .then(async (data) => {
        data.bike_maintainer_name = await getUserNameById(
          data.bike_maintainer_uid,
          "-미연결-"
        );
        data.customer_name = await getCustomerNameById(
          data.customer_cid,
          "-미연결-"
        );
        data.contractor_name = await getCustomerNameById(
          data.contractor_cid,
          "-미연결-"
        );

        data.customer_phone = await getCustomerPhoneById(
          data.customer_cid,
          "-"
        );
        data.contractor_phone = await getCustomerPhoneById(
          data.contractor_cid,
          "-"
        );
        // console.log(data);
        setBikeInfo(data);
      })
      .catch((err) => console.error(err));

    fetchGet(`/log/byb/${bike_id}`)
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(await res.text());
      })
      .then((data) => {
        data.sort((a, b) => {
          return new Date(b.fix_date) - new Date(a.fix_date);
        });
        const newData = data.map((val) => {
          return {
            ...val,
            fix_type: fixTypeKorDict[val.fix_type],
          };
        });
        setCont(newData);
      })
      .catch((err) => console.error(err));
  }, []);

  function handleAddLogButtonClick(event) {
    event.preventDefault();
    navigate(`/f/log/add/${bike_id}`);
  }

  function handleFindCustomerButtonClick(e) {
    e.preventDefault();
    let findNumber = customerNumber;
    findNumber = findNumber.replace(/-/g, "");
    findNumber = findNumber.replace(/\)/g, "").trim();
    if (findNumber.length < 10 || findNumber.match(/[^0-9]/)) {
      alert("잘못된 입력입니다. 다시 작성해주세요.");
      return;
    }

    fetchGet(`/customer?customer_phone=${findNumber}`)
      .then(async (res) => {
        if (!res.ok) {
          if (res.status === 404) {
            if (window.confirm("고객을 찾을 수 없습니다. 추가하시겠습니까?")) {
              setCustomerNumber(findNumber);
              setCusAddModal(true);
            }
          }
          throw new Error("noalert");
        }
        return res.json();
      })
      .then((data) => {
        if (data.length === 1) {
          setCustomerData(data[0]);
        } else {
          setCustomersList(data);
        }
      })
      .catch((err) => {
        if (err.message !== "noalert") {
          alert(err.message);
        }
      });
  }

  function handleAssignCustomerButtonClick(e) {
    e.preventDefault();

    fetchPut(`/bike/${bikeInfo._id}`, {
      customer_cid: foundCustomer._id,
    })
      .then(async (res) => {
        if (!res.ok) {
          alert("연결에 실패했습니다. 잠시 후 다시 시도해주세요.");
          throw new Error(await res.text());
        } else {
          alert("연결이 완료되었습니다");
        }
        setAssign(false);
        navigate(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleAssignContractorButtonClick(e) {
    e.preventDefault();

    fetchPut(`/bike/${bikeInfo._id}`, {
      contractor_cid: foundCustomer._id,
    })
      .then(async (res) => {
        if (!res.ok) {
          alert("연결에 실패했습니다. 잠시 후 다시 시도해주세요.");
          throw new Error(await res.text());
        } else {
          alert("연결이 완료되었습니다");
        }
        setAssignContractor(false);
        navigate(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <Container>
        <h1>{bikeInfo.bike_name_id || ""}</h1>
        <Table bordered>
          <tbody>
            <tr>
              <th>차대번호</th>
              <td>{bikeInfo.bike_uniq_id}</td>
              <th>차량기종</th>
              <td>
                {bikeInfo.model_info
                  ? `${bikeInfo.model_info.bike_maker} ${bikeInfo.model_info.bike_model} ${bikeInfo.model_info.bike_aged}`
                  : ""}
              </td>
            </tr>
            <tr>
              <th>관리 가맹점</th>
              <td>{bikeInfo.bike_maintainer_name}</td>
              <th>차량구분</th>
              <td>{{ NORMAL: "일반", LEASE: "리스" }[bikeInfo.lease_type]}</td>
            </tr>
            <tr>
              <th>고객1 (소유주)</th>
              <td>{bikeInfo.contractor_name}</td>
              <th>고객2 (사용자)</th>
              <td>{bikeInfo.customer_name}</td>
            </tr>
            <tr>
              <th>고객1 연락처</th>
              <td>{bikeInfo.contractor_phone}</td>
              <th>고객2 연락처</th>
              <td>{bikeInfo.customer_phone}</td>
            </tr>
          </tbody>
        </Table>
        <Stack direction="horizontal" gap={3} className="m-2 mb-3">
          <div className="ms-auto" />
          <Button onClick={() => setBikeDetail(true)}>바이크 상세정보</Button>
          <Button
            onClick={() => {
              setCustomerNumber("");
              setCustomersList([]);
              setCustomerData({});
              setAssignContractor(true);
            }}
          >
            고객1 연결
          </Button>
          <Button
            onClick={() => {
              setCustomerNumber("");
              setCustomersList([]);
              setCustomerData({});
              setAssign(true);
            }}
          >
            고객2 연결
          </Button>
          <div className="ms-auto" />
        </Stack>
        <hr />
        {!bikeInfo.customer_cid && <h6>고객을 연결해주세요</h6>}
        <Button
          disabled={!bikeInfo.customer_cid}
          className="mb-3"
          type="submit"
          onClick={handleAddLogButtonClick}
        >
          정비이력 추가하기
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>정비일시</th>
              <th>정비가맹점</th>
              <th>주행거리</th>
              <th>정비유형</th>
              <th>정비품목</th>
            </tr>
          </thead>
          <tbody>
            {logsCont
              .sort((a, b) => new Date(b.fix_date) - new Date(a.fix_date))
              .map((val) => (
                <FranchLogPreviewComponent
                  key={val._id}
                  initLogData={val}
                  onClick={() => {
                    setSelected(val);
                    setDetail(true);
                  }}
                  altBikeId
                />
              ))}
          </tbody>
        </Table>
        {logsCont.length === 0 && <h6>정비 내역이 없습니다</h6>}
      </Container>

      {/* Detail log modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showDetail}
        onHide={() => setDetail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>정비 이력 상세</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LogDetailComponent logId={selected._id} />
        </Modal.Body>
        <Modal.Footer>
          {selected.fix_location === sessionStorage.getItem("_id") && (
            <Button onClick={() => setFix(true)}>수정</Button>
          )}
          <Button variant="secondary" onClick={() => setDetail(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {/** fix log modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showFix}
        onHide={() => setFix(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>정비 이력 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LogEditFormComponent
            onSubmit={(formValues) => {
              setCont((prev) => {
                const newArr = [...prev];
                const idx = newArr.findIndex(
                  (ele) => ele._id === formValues._id
                );
                if (idx >= 0) {
                  newArr[idx] = { ...newArr[idx], ...formValues };
                }
                console.log(newArr);
                console.log(idx);
                return newArr;
              });
              setFix(false);
              setDetail(false);
            }}
            logId={selected._id}
          >
            <Button variant="secondary" onClick={() => setFix(false)}>
              닫기
            </Button>
          </LogEditFormComponent>
        </Modal.Body>
      </Modal>

      {/** Bike detail modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showBikeDetail}
        onHide={() => {
          setBikeDetail(false);
        }}
      >
        <Modal.Header closeButton>바이크 상세정보</Modal.Header>
        <Modal.Body>
          <BikeDetailTableComponent bikeObject={bikeInfo} />
        </Modal.Body>
      </Modal>

      {/** Customer assign modal */}
      <Modal
        backdrop="static"
        show={showAssign}
        onHide={() => {
          setAssign(false);
        }}
      >
        <Modal.Header closeButton>고객2 - 사용자 연결</Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group>
              <Form.Label>고객의 전화번호를 입력해주세요</Form.Label>
              <Form.Control
                onChange={(e) => setCustomerNumber(e.target.value)}
                value={customerNumber}
              />
              <Button className="mt-2" onClick={handleFindCustomerButtonClick}>
                확인하기
              </Button>
            </Form.Group>

            {foundCustomersList.length > 1 && (
              <>
                <Form.Label className="mt-2">
                  아래중 맞는 고객을 눌러주세요
                </Form.Label>
                <Table hover responsive>
                  <tbody>
                    {foundCustomersList.map((e) => (
                      <tr
                        className="text-center"
                        key={e._id}
                        onClick={() => {
                          setCustomerData(e);
                          setCustomersList([]);
                        }}
                      >
                        <td>{e.customer_name}</td>
                        <td>{e.customer_phone?.toMobileString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            {!!foundCustomer._id && (
              <Form.Group>
                <Form.Label>이름을 확인해주세요</Form.Label>
                <Form.Control disabled value={foundCustomer.customer_name} />
                <Form.Label>
                  맞을 경우, 아래의 &quot;적용하기&quot;를 눌러주세요
                </Form.Label>
                <br />
                <Button
                  className="mt-2"
                  onClick={handleAssignCustomerButtonClick}
                >
                  적용하기
                </Button>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/** Contractor assign modal */}
      <Modal
        backdrop="static"
        show={showAssignContractor}
        onHide={() => {
          setAssignContractor(false);
        }}
      >
        <Modal.Header closeButton>고객1 - 소유주 연결</Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group>
              <Form.Label>고객의 전화번호를 입력해주세요</Form.Label>
              <Form.Control
                onChange={(e) => setCustomerNumber(e.target.value)}
                value={customerNumber}
              />
              <Button className="mt-2" onClick={handleFindCustomerButtonClick}>
                확인하기
              </Button>
            </Form.Group>

            {foundCustomersList.length > 1 && (
              <>
                <Form.Label className="mt-2">
                  아래중 맞는 고객을 눌러주세요
                </Form.Label>
                <Table hover responsive>
                  <tbody>
                    {foundCustomersList.map((e) => (
                      <tr
                        className="text-center"
                        key={e._id}
                        onClick={() => {
                          setCustomerData(e);
                          setCustomersList([]);
                        }}
                      >
                        <td>{e.customer_name}</td>
                        <td>{e.customer_phone?.toMobileString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            {!!foundCustomer._id && (
              <Form.Group>
                <Form.Label>이름을 확인해주세요</Form.Label>
                <Form.Control disabled value={foundCustomer.customer_name} />
                <Form.Label>
                  맞을 경우, 아래의 &quot;적용하기&quot;를 눌러주세요
                </Form.Label>
                <br />
                <Button
                  className="mt-2"
                  onClick={handleAssignContractorButtonClick}
                >
                  적용하기
                </Button>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {
        // #region Add Customer modal
      }
      <Modal
        backdrop="static"
        show={showCusAddModal}
        onHide={() => setCusAddModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>고객 추가하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerAddFormComponent
            defaultData={{
              customer_phone: customerNumber,
            }}
            onSubmit={() => {
              setCusAddModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

export default FranchBike;
