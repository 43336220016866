import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Table,
  Button,
  ButtonGroup,
  Stack,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { baseLink, fetchGet, fetchPost, fetchPut } from "utils/functions";

function AnnouncePageComponent({
  isAdd = false,
  isEdit,
  docId,
  onCloseButtonClick = () => {},
  defaultWriter = "",
  onSubmit = (formValues) => {},
}) {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    doc_writer: defaultWriter,
  });
  const [isEditing, setEditing] = useState(isAdd);

  useEffect(() => {
    if (!docId) return;

    fetchGet(`/announce/${docId}`)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((data) => setFormValues(data));
  }, []);

  function handleFormValueChange(e) {
    setFormValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    console.log(formValues);

    if (!isEditing) {
      onCloseButtonClick(e);
      return;
    }
    if (!formValues.doc_title) {
      alert("제목은 반드시 작성해야합니다");
      return;
    }

    (docId
      ? fetchPut(`/announce/${docId}`, { ...formValues, doc_viewers: [] })
      : fetchPost("/announce", formValues)
    )
      .then(async (res) => {
        if (!res.ok) {
          alert("전송에 실패했습니다");
          throw new Error(await res.text());
        }
        alert("전송이 완료되었습니다.");
        onSubmit({ ...formValues, doc_viewers: [] });
      })
      .catch((err) => console.error(err));
  }

  function handleImageUploadBefore(files, info, uploadHandler) {
    const formData = new FormData();
    formData.append("dest", "announce");
    formData.append("img", files[0]);

    fetchPost("/file", formData, {}, true).then(async (res) => {
      if (!res.ok) {
        const errObj = await res.json();
        console.error(errObj);
        uploadHandler(errObj.toString());
      }

      const imgResData = await res.json();
      console.log(imgResData.fileInfo);

      const url =
        imgResData.fileInfo.destination + imgResData.fileInfo.filename;

      uploadHandler({
        result: [
          {
            url: `${baseLink}/file/${url}`,
            name: imgResData.fileInfo.originalname,
            size: imgResData.size,
          },
        ],
      });
      return undefined;
    });
  }

  function handleImageUploadError(errorMessage, result) {
    console.error(errorMessage, result);
    alert("이미지 업로드에 실패했습니다");
    return false;
  }

  return isEdit ? (
    <Form>
      <Form.Group>
        <Form.Label>제목</Form.Label>
        <Form.Control
          disabled={!isEditing}
          name="doc_title"
          onChange={handleFormValueChange}
          value={formValues.doc_title || ""}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>작성자</Form.Label>
        <Form.Control
          disabled={!isEditing}
          name="doc_writer"
          onChange={handleFormValueChange}
          value={formValues.doc_writer || ""}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>내용</Form.Label>
        <SunEditor
          disable={!isEditing}
          hideToolbar={!isEditing}
          lang="ko"
          placeholder="여기에 작성해주세요..."
          autoFocus
          setContents={formValues.doc_content}
          onChange={(content) =>
            setFormValues((prev) => {
              return { ...prev, doc_content: content };
            })
          }
          onImageUploadBefore={handleImageUploadBefore}
          onImageUploadError={handleImageUploadError}
          setOptions={{
            height: "auto",
            minHeight: "300px",
            buttonList: [
              ["undo", "redo"],
              // "font", "fontSize",
              // ["paragraphStyle", "blockquote"],
              ["formatBlock", "bold", "underline", "italic", "strike"],
              // ['fontColor', 'hiliteColor', 'textStyle'],
              ["removeFormat"],
              ["outdent", "indent"],
              // ['align', 'horizontalRule', 'list', 'lineHeight'],
              ["table", "link", "image"], // You must add the 'katex' library at options to use the 'math' plugin.
              // ['imageGallery'], // You must add the "imageGalleryUrl".
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              // ['save', 'template'],
              // ['dir', 'dir_ltr', 'dir_rtl'],
            ],
          }}
        />
        {/**
           * <Form.Control
          as="textarea"
          rows={15}
          disabled={!isEditing}
          name="doc_content"
          onChange={handleFormValueChange}
          value={formValues.doc_content || ""}
        />
           */}
      </Form.Group>

      {docId ? <Form.Text>db_id: {docId}</Form.Text> : ""}
      <br />
      {formValues.doc_viewers ? (
        <Form.Text>확인 인원: {formValues.doc_viewers.length}</Form.Text>
      ) : (
        ""
      )}

      <Form.Group>
        <Stack className="mt-2" direction="horizontal">
          <div className="ms-auto" />
          <ButtonGroup>
            <Button disabled={isEditing} onClick={() => setEditing(!isEditing)}>
              수정
            </Button>
            <Button type="submit" onClick={handleSubmitButtonClick}>
              {isEditing ? "저장" : "닫기"}
            </Button>
          </ButtonGroup>
        </Stack>
      </Form.Group>
    </Form>
  ) : (
    <Container>
      <Table responsive="xs">
        <tbody>
          <tr>
            <th>제목</th>
            <td colSpan={3}>{formValues.doc_title}</td>
          </tr>
          <tr>
            <th>작성자</th>
            <td>{formValues.doc_writer}</td>
          </tr>
          <tr>
            <th>공지일</th>
            <td>{formValues.createdAt?.slice(0, 16).replace("T", " ")}</td>
          </tr>
          <tr>
            <th>마지막 수정일</th>
            <td>{formValues.updatedAt?.slice(0, 16).replace("T", " ")}</td>
          </tr>
        </tbody>
      </Table>
      <div className="text-start">
        <SunEditor
          disable
          hideToolbar
          setContents={formValues.doc_content}
          setOptions={{
            height: "auto",
            minHeight: "300px",
          }}
        />
      </div>
    </Container>
  );
}

export default AnnouncePageComponent;
