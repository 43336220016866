import BikeModelFindComponent from "component/bikemodel/bikemodel-find-comp";
import ItemFindComponent from "component/item/item-find-comp";
import React, { useEffect } from "react";

function TestPage() {
  return (
    <ItemFindComponent arraySelect onFound={(found) => console.log(found)} />
  );
}

export default TestPage;
