import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { checkPermission } from "utils/functions";

function FranchAdditionals() {
  const navigate = useNavigate();

  useEffect(() => {
    // check permission
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }
  }, []);

  return (
    <Container>
      <Row className="justify-content-md-center mb-3">
        <Col xs lg="6">
          <Button onClick={() => navigate("/f/mylog")}>
            내 매장 정비이력 확인하기
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col xs lg="6">
          <Button onClick={() => navigate("/f/mybike")}>
            내 매장 관리차량 확인하기
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col xs lg="6">
          <Button onClick={() => navigate("/f/customer")}>
            최근 방문 고객 확인하기
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default FranchAdditionals;
