import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AnalyticsBikeComponent from "component/analytics/analytics-bike-comp";
import AnalyticsLoginComponent from "component/analytics/analytics-login-comp";
import CenterSidebarComponent from "component/center-sidebar-comp";

function CenterMain() {
  return (
    <Container fluid>
      <Row>
        <Col md={2} sm={6} xs={6} className="m-auto mt-2">
          <CenterSidebarComponent selected="main" />
          <hr />
        </Col>
        <Col className="">
          <AnalyticsLoginComponent />
        </Col>
        <Col className="">
          <AnalyticsBikeComponent />
        </Col>
      </Row>
    </Container>
  );
}

export default CenterMain;
