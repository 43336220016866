import BikeModelFindComponent from "component/bikemodel/bikemodel-find-comp";
import ImageViewComponent from "component/image/image-comp";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  fileSizeLimit,
} from "utils/functions";

function ItemGroupActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
  children,
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);
  const [showImg, setImg] = useState(false);

  const [bikeModelText, setModelText] = useState("");

  function handleFormValueChangeBase(name, value) {
    setFormValues((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function handleFormValueChange(e) {
    handleFormValueChangeBase(e.target.name, e.target.value);
  }

  useEffect(() => {
    setItemSelected(selected.has("_id"));
  }, [selected]);

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    if (
      !formValues.bike_model_id ||
      !formValues.group_name ||
      formValues.group_name.length === 0 ||
      !formValues.group_class_number ||
      !formValues.group_class ||
      formValues.group_class.length === 0
    ) {
      alert("모든 내용이 채워져야 합니다");
      return;
    }

    (showFix
      ? fetchPut(`/igroup/${selected.get("_id")}`, formValues)
      : fetchPost("/igroup", formValues)
    )
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("완료되었습니다");
        setAdd(false);
        setFix(false);
        if (showFix) contentRefresh(formValues);
        else navigate(0);
      })
      .catch((err) => console.error(err));
  }

  function handleRemoveButtonClick(e) {
    e.preventDefault();

    if (
      window.confirm(
        `해당 ${selected.get(
          "bike_model"
        )} 모델을 삭제하시겠습니까? 연관된 모든 정비 그룹 및 품목이 삭제됩니다`
      )
    ) {
      fetchDelete(`/igroup/${selected.get("_id")}`).then((res) => {
        if (res.ok) {
          alert("삭제되었습니다");
          navigate(0);
          return;
        }
        alert("삭제에 실패했습니다. 관리자에게 문의해주세요");
      });
    }
  }

  return (
    <>
      {children}
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button
              variant="primary"
              onClick={() => {
                setAdd(true);
                setFormValues({});
              }}
            >
              등록
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => {
                setFix(true);
                // selected to formValues
                setFormValues(Object.fromEntries(selected));
                const bikeModel = selected.get("bikemodel_info");
                setModelText(
                  `${bikeModel.bike_maker} ${bikeModel.bike_model} ${bikeModel.bike_aged}`
                );
              }}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => {
                setImg(true);
              }}
            >
              도면 이미지 관리
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleRemoveButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {
        // #region group add modal
      }
      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>정비품목 분류 등록하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>차량 모델</Form.Label>
              <BikeModelFindComponent
                onFound={(e) => {
                  handleFormValueChangeBase("bike_model_id", e._id);
                  setModelText(
                    `${e.bike_maker} ${e.bike_model} ${e.bike_aged}`
                  );
                }}
              />
              <Form.Control className="mt-2" disabled value={bikeModelText} />
            </Form.Group>

            <Form.Group>
              <Form.Label>카테고리</Form.Label>
              <Form.Control
                name="group_name"
                onChange={handleFormValueChange}
                value={formValues.group_name ?? ""}
                placeholder="카테고리"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>분류</Form.Label>
              <Stack gap={2} direction="horizontal">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">분류NO</InputGroup.Text>
                  <Form.Control
                    name="group_class_number"
                    aria-describedby="basic-addon1"
                    onChange={handleFormValueChange}
                    value={formValues.group_class_number ?? ""}
                  />
                </InputGroup>
                <Form.Control
                  name="group_class"
                  onChange={handleFormValueChange}
                  value={formValues.group_class ?? ""}
                  placeholder="분류명"
                />
              </Stack>
              <Form.Control
                name="group_class_kr"
                onChange={handleFormValueChange}
                value={formValues.group_class_kr ?? ""}
                placeholder="한글 분류명"
              />
            </Form.Group>
          </Form>

          <Stack className="mt-4" direction="horizontal">
            <div className="me-auto" />
            <Button onClick={handleSubmitButtonClick}>등록하기</Button>
          </Stack>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {
        // #region group edit modal
      }
      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>정비품목 분류 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>차량 모델</Form.Label>
              <BikeModelFindComponent
                onFound={(e) => {
                  handleFormValueChangeBase("bike_model_id", e._id);
                  setModelText(
                    `${e.bike_maker} ${e.bike_model} ${e.bike_aged}`
                  );
                }}
              />
              <Form.Control className="mt-2" disabled value={bikeModelText} />
            </Form.Group>

            <Form.Group>
              <Form.Label>카테고리</Form.Label>
              <Form.Control
                name="group_name"
                onChange={handleFormValueChange}
                value={formValues.group_name ?? ""}
                placeholder="카테고리"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>분류</Form.Label>
              <Stack gap={2} direction="horizontal">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">분류번호</InputGroup.Text>
                  <Form.Control
                    name="group_class_number"
                    aria-describedby="basic-addon1"
                    onChange={handleFormValueChange}
                    value={formValues.group_class_number ?? ""}
                  />
                </InputGroup>
                <Form.Control
                  name="group_class"
                  onChange={handleFormValueChange}
                  value={formValues.group_class ?? ""}
                  placeholder="분류명"
                />
              </Stack>
              <Form.Control
                name="group_class_kr"
                onChange={handleFormValueChange}
                value={formValues.group_class_kr ?? ""}
                placeholder="한글 분류명"
              />
            </Form.Group>
          </Form>

          <Stack className="mt-4" direction="horizontal">
            <div className="me-auto" />
            <Button onClick={handleSubmitButtonClick}>수정하기</Button>
          </Stack>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {
        // #region image control modal
      }
      <Modal backdrop="static" show={showImg} onHide={() => setImg(false)}>
        <Modal.Header closeButton>
          <Modal.Title>파츠 도면 이미지 관리</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{selected.get("part_image")?.id}</h4>
          <ImageViewComponent url={selected.get("part_image")?.url} />

          <Form.Control
            id="part_image_upload"
            className="d-none"
            type="file"
            onChange={(e) => {
              if (e.target.files[0].size > fileSizeLimit) {
                alert("파일이 너무 큽니다");
                e.target.value = "";
                return;
              }

              const formData = new FormData();
              formData.append("dest", "parts");
              formData.append("img", e.target.files[0]);

              fetchPost("/file", formData, {}, true).then(async (res) => {
                if (!res.ok) {
                  const errObj = await res.json();
                  console.error(errObj);
                  alert(errObj.err);
                  e.target.value = "";
                }

                const imgResData = await res.json();
                console.log(imgResData.fileInfo);

                const url =
                  imgResData.fileInfo.destination +
                  imgResData.fileInfo.filename;

                await fetchPut(`/igroup/${selected.get("_id")}`, {
                  part_image: {
                    id: imgResData.fileInfo.originalname,
                    url,
                  },
                }).then((putres) => {
                  if (!res.ok) throw new Error(putres.status);
                  alert("완료되었습니다");
                  setImg(false);
                  contentRefresh({
                    _id: selected.get("_id"),
                    part_image: {
                      id: imgResData.fileInfo.originalname,
                      url,
                    },
                  });
                });
              });
            }}
          />
          <Stack className="mt-4" direction="horizontal">
            <div className="me-auto" />
            <Button
              onClick={() => {
                document.getElementById("part_image_upload")?.click();
              }}
            >
              새로 등록하기
            </Button>
          </Stack>
          <Stack direction="horizontal">
            <div className="me-auto" />
            <Form.Text className="text-muted">
              용량 4MB 미만의 이미지만 가능합니다
            </Form.Text>
          </Stack>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

export default ItemGroupActionsComponent;
