import React from "react";
import {
  fetchGet,
  getBikeModelById,
  getBikeNameById,
  getUserNameById,
  objectToQuery,
} from "utils/functions";
import CenterTableComponent from "../center-table-comp";

function CenterLogTableComponent({
  setSelected,
  contentRefresh,
  requestUrl = "/log",
}) {
  const defaultLogTitles = new Map([
    ["bike_id", { title: "차량ID", visible: false }],
    ["fix_date", { title: "정비일", visible: true, sorted: 2 }],
    ["bike_name_id", { title: "차량번호", visible: true, generated: true }],
    ["bike_model_id", { title: "모델ID", visible: false }],
    ["bike_model_name", { title: "차종", visible: true, generated: true }],
    ["fix_drivedist", { title: "주행거리", visible: true }],
    ["fix_type_display", { title: "정비유형", visible: true }],
    ["fix_subtype_display", { title: "작업구분", visible: true }],
    ["fix_item_display", { title: "정비품목", visible: true }],
    ["fix_location", { title: "정비점ID", visible: false }],
    ["fix_location_name", { title: "정비점", visible: true, generated: true }],
    ["fix_contractor_id", { title: "고객1 ID", visible: false }],
    [
      "contractor_name",
      { title: ["고객1", "(소유주)"], visible: true, generated: true },
    ],
    [
      "contractor_phone",
      { title: "고객1 연락처", visible: true, generated: true },
    ],
    ["fix_customer_id", { title: "고객2 ID", visible: false }],
    [
      "customer_name",
      { title: ["고객2", "(사용자)"], visible: true, generated: true },
    ],
    [
      "customer_phone",
      { title: "고객2 연락처", visible: true, generated: true },
    ],
    ["require_guarantee", { title: ["보증AS", "이미지"], visible: true }],
    ["fix_outside", { title: "출장 정비", visible: false }], // 내점false / 출장true
    ["additional_memo", { title: "비고 및 특이사항", visible: false }],
    ["_id", { title: "db_id", visible: false, sortable: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "centerLogTable", paginationOption: 1 }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultLogTitles}
      getDataPromise={async (queryObject) => {
        const query = objectToQuery(queryObject);
        const res = await fetchGet(`${requestUrl}${query}`);
        if (!res.ok) throw new Error("Error in fetch");
        let resData = await res.json();
        // data: [], meta: {}, customer_infos: []

        let header = {};
        // console.log(...res.headers);
        if (queryObject.page) {
          [resData] = resData;
          header = { ...resData.meta };
          const toReturn = resData.data.map((ele) => {
            const customerFound = resData.customer_infos.find(
              (cusEle) => cusEle._id === ele.fix_customer_id
            );
            const contractorFound = resData.contractor_infos.find(
              (cusEle) => cusEle._id === ele.fix_contractor_id
            );
            return {
              ...ele,
              customer_info: customerFound,
              contractor_info: contractorFound,
            };
          });
          resData = toReturn;
        }
        header.totalPost = res.headers.get("Total-Post");
        return { header, data: resData };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];

          for (const row of data) {
            // reorder
            const newMapped = new Map();
            for await (const key of titleMap.keys()) {
              // process
              if (key === "fix_outside") {
                newMapped.set(key, row[key] ? "출장" : "-");
              } else if (key === "additional_memo") {
                newMapped.set(key, row[key] ?? "");
              } else if (key === "customer_name" || key === "customer_phone") {
                newMapped.set(
                  key,
                  row.customer_info ? row.customer_info[key] : "-"
                );
              } else if (key === "contractor_name") {
                newMapped.set(
                  key,
                  row.contractor_info ? row.contractor_info.customer_name : "-"
                );
              } else if (key === "contractor_phone") {
                newMapped.set(
                  key,
                  row.contractor_info ? row.contractor_info.customer_phone : "-"
                );
              } else if (key === "require_guarantee") {
                newMapped.set(key, row[key] ? "O" : "X");
              } else if (key === "fix_item_display") {
                newMapped.set(
                  key,
                  row[key]
                    ? row[key].slice(0, 10) +
                        (row[key].length > 10 ? "..." : "")
                    : ""
                );
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else if (key === "fix_date") {
                newMapped.set(key, row[key].slice(0, 16).replace("T", " / "));
              } else if (key === "bike_name_id") {
                newMapped.set(key, await getBikeNameById(row.bike_id));
              } else if (key === "fix_location_name") {
                newMapped.set(key, await getUserNameById(row.fix_location));
              } else if (key === "bike_model_name") {
                newMapped.set(key, await getBikeModelById(row.bike_id));
              } else if (key === "fix_drivedist") {
                newMapped.set(key, `${row[key].toLocaleString()}km`);
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default CenterLogTableComponent;
