import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import {
  getBikeNameById,
  getItemNameById,
  getUserNameById,
  fixTypeKorDict,
} from "utils/functions";

/**
 *
 * @param {*} props.initLogData full log into object
 * @param {*} props.onClick callback function when click on table
 * @returns
 */
function FranchLogPreviewComponent({
  initLogData,
  onClick = () => {},
  altBikeId = false,
}) {
  const [logData, setLogData] = useState({
    fix_location_name: "",
    fix_item_display: "",
    fix_drivedist: 0,
  });

  useEffect(() => {
    (async () => {
      const nextLogData = { ...logData, ...initLogData };

      nextLogData.fix_location_name = await getUserNameById(
        initLogData.fix_location
      );
      nextLogData.bike_name_id = await getBikeNameById(initLogData.bike_id);

      setLogData(nextLogData);
    })();
  }, [initLogData]);

  return (
    <tr onClick={onClick}>
      <td>{logData.fix_date?.slice(0, 16).replace("T", " / ")}</td>
      <td>{altBikeId ? logData.fix_location_name : logData.bike_name_id}</td>
      <td>{logData.fix_drivedist?.toLocaleString()}km</td>
      <td>{logData.fix_type_display}</td>
      <td>
        <Form.Control
          size="sm"
          disabled
          as="textarea"
          value={logData.fix_item_display}
        />
      </td>
    </tr>
  );
}

export default FranchLogPreviewComponent;
