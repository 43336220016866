import ImageViewComponent from "component/image/image-comp";
import ItemGroupFindComponent from "component/itemgroup/itemgroup-find-comp";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { fetchGet } from "utils/functions";

function UndraggableTd({ children }) {
  return (
    <td
      style={{
        WebkitUserSelect: "none" /* Safari */,
        msUserSelect: "none" /* IE 10 and IE 11 */,
        userSelect: "none" /* Standard syntax */,
      }}
    >
      {children}
    </td>
  );
}

function ItemFindComponent({
  selectedModel = {},
  arraySelect = false,
  onFound = (found) => {},
}) {
  const [itemGroupId, setItemGroupId] = useState("");
  const [itemGroupImageTitle, setItemGroupImageTitle] = useState("");
  const [itemGroupImage, setItemGroupImage] = useState("");
  const [foundItems, setFoundItems] = useState([]);

  const [selected, setSelected] = useState([]);

  const [showGroupFind, setGroupFind] = useState(true);
  const [itemSearch, setItemSearch] = useState("");

  const [useDuplicate, setDuplicate] = useState(0);
  const [dupSelected, setDupSelected] = useState([]);

  useEffect(() => {
    if (itemGroupId.length === 0) return;

    fetchGet(`/item/byg/${itemGroupId}`)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());

        return res.json();
      })
      .then((data) => {
        setFoundItems(data);
      })
      .catch((err) => {
        console.error(err);
        setFoundItems([]);
      })
      .finally(() => {
        setSelected([]);
      });
  }, [itemGroupId]);

  function findItemListBySearch(e) {
    e.preventDefault();
    if (itemSearch.length === 0) return;
    if (!selectedModel._id) return;

    setGroupFind(false);

    fetchGet(`/item/bym/${selectedModel._id}?item_name_kr=*${itemSearch}*`)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());

        return res.json();
      })
      .then((data) => {
        setFoundItems(data[0].data);
      })
      .catch((err) => {
        console.error(err);
        setFoundItems([]);
      })
      .finally(() => {
        setSelected([]);
      });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          {showGroupFind ? (
            <ItemGroupFindComponent
              selectedModel={selectedModel}
              onFound={(found) => {
                setItemGroupId(found._id);
                setItemGroupImageTitle(found.part_image?.id ?? "");
                setItemGroupImage(found.part_image?.url ?? "");
              }}
            />
          ) : (
            <Button onClick={() => setGroupFind(true)}>그룹 선택 보이기</Button>
          )}
        </Col>
        <Col>
          {!!selectedModel._id && (
            <Stack direction="horizontal">
              <Form.Control
                className="me-2"
                placeholder="부품 한글명으로 검색"
                onChange={(e) => setItemSearch(e.target.value)}
              />
              <Button onClick={findItemListBySearch}>검색</Button>
            </Stack>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl={6} className="mb-2">
          <h4>{itemGroupImageTitle}</h4>
          <ImageViewComponent disableContext url={itemGroupImage} />
        </Col>
        <Col xl={6}>
          <Stack className="mb-2" direction="horizontal">
            <div className="ms-auto" />
            {arraySelect && `선택 개수: ${selected.length}개`}
            <Button
              className="ms-2"
              disabled={!selected.length}
              onClick={() => onFound(arraySelect ? selected : selected[0])}
            >
              선택
            </Button>
          </Stack>
          <Table responsive bordered hover size="sm">
            <thead>
              <tr>
                <th>NO</th>
                <th>부품명</th>
                <th>
                  제조사
                  <br />
                  품번
                </th>
                <th>공급</th>
                <th>호환</th>
                <th>공급시작일</th>
                <th>공급종료일</th>
                <th>부품갱신일</th>
              </tr>
            </thead>
            <tbody>
              {foundItems
                .sort((a, b) => a.item_number - b.item_number)
                .map((element) => {
                  return (
                    <tr
                      className={
                        selected.findIndex((val) => val._id === element._id) >
                        -1
                          ? "selected-row"
                          : ""
                      }
                      key={element._id}
                      onClick={() => {
                        if (arraySelect) {
                          const indexFound = selected.findIndex(
                            (val) => val._id === element._id
                          );
                          if (indexFound > -1) {
                            selected.splice(indexFound, 1);
                            setSelected([...selected]);
                          } else {
                            setSelected(selected.concat(element));
                          }
                        } else {
                          setSelected([element]);
                        }
                      }}
                    >
                      <UndraggableTd>{element.item_number}</UndraggableTd>
                      <UndraggableTd>
                        <p className="mb-0 text-start">
                          {element.item_name_kr}
                        </p>
                        <p className="mb-0 small text-start">
                          {element.item_name_en}
                        </p>
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.item_company_partid}
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.item_is_providing ? "Y" : "N"}
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.item_is_compatible ? "Y" : "N"}
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.item_providing_start?.slice(0, 10)}
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.item_providing_end?.slice(0, 10)}
                      </UndraggableTd>
                      <UndraggableTd>
                        {element.updatedAt?.slice(0, 10)}
                      </UndraggableTd>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Stack direction="horizontal">
            <div className="ms-auto" />
            {arraySelect && `선택 개수: ${selected.length}개`}
            <Button
              className="ms-2"
              disabled={!selected.length}
              onClick={() => {
                if (
                  arraySelect &&
                  selected.length === 1 &&
                  selected[0].item_name_kr.includes("직접입력")
                ) {
                  const countStr = prompt("추가할 갯수를 입력해주세요");
                  const count = parseInt(countStr, 10);
                  if (!Number.isNaN(count)) {
                    for (let i = 0; i < count - 1; i += 1) {
                      selected.push(selected[0]);
                    }
                  }
                }
                onFound(arraySelect ? selected : selected[0]);
              }}
            >
              선택
            </Button>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default ItemFindComponent;
