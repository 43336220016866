import React from "react";
import { fetchGet, getUserNameById } from "utils/functions";
import CenterTableComponent from "../center-table-comp";

function CenterCustomerTableComponent({ setSelected, contentRefresh }) {
  const defaultCustomerTitles = new Map([
    ["customer_type", { title: "고객 유형", visible: true }], // NORMAL 일반고객 / DELIVERY 배달기사
    ["customer_name", { title: "고객명", visible: true }],
    ["customer_phone", { title: "전화번호", visible: true }],
    ["customer_created", { title: "관리가맹점 ID", visible: false }],
    [
      "customer_created_name",
      { title: "관리가맹점", visible: true, generated: true },
    ],
    ["customer_last_used", { title: "최근 이용가맹점 ID", visible: false }],
    [
      "customer_last_used_name",
      { title: "최근 이용 가맹점", visible: true, generated: true },
    ],
    ["customer_including", { title: "고객 소속", visible: true }],
    ["v_assigned_bikes", { title: "연결 차량", visible: true }],
    ["createdAt", { title: "계정 등록일", visible: false }],
    ["_id", { title: "db_id", visible: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "centerCustomerTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultCustomerTitles}
      getDataPromise={async () => {
        const res = await fetchGet(`/customer`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];
          for (const row of data) {
            const newMapped = new Map();
            for (const key of titleMap.keys()) {
              // process
              if (key === "v_assigned_bikes") {
                newMapped.set(key, row[key]?.length ?? 0);
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else if (key === "customer_phone") {
                newMapped.set(key, row[key].toMobileString());
              } else if (key === "customer_type") {
                if (row[key] === "NORMAL") {
                  newMapped.set(key, "일반");
                } else if (row[key] === "DELIVERY") {
                  newMapped.set(key, "배달종사자");
                } else {
                  newMapped.set(key, "-");
                }
              } else if (key === "customer_created_name") {
                newMapped.set(
                  "customer_created_name",
                  await getUserNameById(row.customer_created, "-")
                );
              } else if (key === "customer_last_used_name") {
                newMapped.set(
                  "customer_last_used_name",
                  await getUserNameById(row.customer_last_used, "-")
                );
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default CenterCustomerTableComponent;
