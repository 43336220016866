import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "utils/functions";

function KeywordActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);

  const [formValues, setFormValues] = useState({});

  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    fetchGet("/keyword/groups")
      .then(async (res) => {
        if (!res.ok) throw new Error(res.status);
        const list = await res.json();
        setGroupList(list);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const isSel = selected.has("_id");
    setItemSelected(isSel);
  }, [selected]);

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    if (!formValues.keyword_group || !formValues.keyword_name) {
      alert("모든 내용을 작성해주세요");
      return;
    }

    fetchPost(`/keyword`, formValues)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("완료되었습니다");
        navigate(0);
      })
      .catch((err) => console.error(err));
  }

  function handleFixButtonClick(e) {
    e.preventDefault();

    if (!formValues.keyword_group || !formValues.keyword_name) {
      alert("모든 내용을 작성해주세요");
      return;
    }

    fetchPut(`/keyword/${selected.get("_id")}`, formValues)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("완료되었습니다");
        setAdd(false);
        setFix(false);
        contentRefresh(formValues);
      })
      .catch((err) => console.error(err));
  }

  function handleDeleteButtonClick(e) {
    e.preventDefault();

    if (
      window.confirm(
        `'${selected.get(
          "keyword_name"
        )}' 대분류를 제거합니다.\n계속 하시겠습니까?`
      )
    ) {
      fetchDelete(`/keyword/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            navigate(0);
            alert(`대분류 "${selected.get("keyword_name")}"가 삭제되었습니다`);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  return (
    <>
      <h2>대분류 열람</h2>

      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button
              variant="primary"
              onClick={() => {
                setFormValues({});
                setAdd(true);
              }}
            >
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => {
                setFormValues(Object.fromEntries(selected));
                setAdd(true);
                setFix(true);
              }}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>대분류 {showFix ? "수정" : "추가"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>그룹</Form.Label>
              <Dropdown>
                <Dropdown.Toggle>
                  {formValues.keyword_group ?? "선택"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {groupList.map((val) => (
                    <Dropdown.Item
                      key={val}
                      onClick={() =>
                        setFormValues((prev) => {
                          return {
                            ...prev,
                            keyword_group: val,
                          };
                        })
                      }
                    >
                      {val}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Group>
              <Form.Label>대분류</Form.Label>
              <Form.Control
                value={formValues.keyword_name}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      keyword_name: e.target.value,
                    };
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={showFix ? handleFixButtonClick : handleSubmitButtonClick}
          >
            {showFix ? "수정" : "추가"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default KeywordActionsComponent;
