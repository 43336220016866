import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AnnouncePageComponent from "component/announce/announce-page-comp";
import { fetchGet } from "utils/functions";

function FranchAnnounce() {
  const navigate = useNavigate();
  const [doFetch, setFetch] = useState(true);
  const [docsList, setDocsList] = useState([]);
  const [docId, setDocId] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("doc_id");
    setDocId(query);

    if (!doFetch) return;
    if (docId) return;

    fetchGet("/announce/list")
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((data) => setDocsList(data.filter((e) => e.doc_visible)));

    setFetch(false);
  });

  function handleRowClick(e, element) {
    const params = new URLSearchParams(window.location.search);
    params.set("doc_id", element._id);
    navigate({
      search: params.toString(),
    });
    setFetch(true);
  }

  return (
    <Container>
      {docId ? (
        <AnnouncePageComponent docId={docId} />
      ) : (
        <Table bordered hover>
          <thead>
            <tr>
              <th>공지일</th>
              <th>제목</th>
              <th>확인여부</th>
            </tr>
          </thead>
          <tbody>
            {docsList
              .sort((a, b) => b.doc_fixed - a.doc_fixed)
              .map((e) => (
                <tr key={e._id} onClick={(ev) => handleRowClick(ev, e)}>
                  <td>{e.createdAt.slice(0, 10)}</td>
                  <td>{e.doc_title}</td>
                  <td>{e.v_viewed ? "O" : "X"}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

export default FranchAnnounce;
