import BikeModelFindComponent from "component/bikemodel/bikemodel-find-comp";
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { fetchGet } from "utils/functions";

function ItemGroupFindComponent({
  onFound = (found) => {},
  buttonText = "조회",
  className = "",
  selectedModel = {},
}) {
  const [targetBikeModelId, setTargetModelId] = useState("");
  const [baseList, setBaseList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [hideTable, setHideTable] = useState(false);

  function itemGroupSortFunction(a, b) {
    if (
      Number.isNaN(a.group_class_number) ||
      Number.isNaN(b.group_class_number)
    ) {
      if (a.group_class_number > b.group_class_number) return 1;
      if (a.group_class_number < b.group_class_number) return -1;
    } else {
      return a.group_class_number - b.group_class_number;
    }
    if (a.group_name > b.group_name) return 1;
    if (a.group_name < b.group_name) return -1;
    return 0;
  }

  useEffect(() => {
    if (selectedModel._id) setTargetModelId(selectedModel._id);
  }, []);

  useEffect(() => {
    if (targetBikeModelId === undefined || targetBikeModelId.length === 0) {
      return;
    }

    fetchGet(`/igroup/bybm/${targetBikeModelId}`)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());

        return res.json();
      })
      .then((data) => {
        const sorted = data.sort(itemGroupSortFunction);
        setBaseList(sorted);
        setFilteredList(
          searchValue.length === 0
            ? sorted
            : sorted.filter(
                (el) =>
                  el.group_class
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1
              )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [targetBikeModelId]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setFilteredList(baseList);
      return;
    }

    setFilteredList(
      baseList.filter(
        (el) =>
          el.group_class.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 || el.group_class_kr.replace(" ", "").indexOf(searchValue) > -1
      )
    );
  }, [searchValue]);

  return (
    <div className={className}>
      <BikeModelFindComponent
        preselectedModel={selectedModel}
        onFound={(found) => {
          setTargetModelId(found._id);
          setHideTable(false);
        }}
        buttonText={buttonText}
      />
      {baseList.length === 0 || hideTable ? (
        <div />
      ) : (
        <div className="mt-2 mb-2">
          <Form.Control
            placeholder="분류명/한글명으로 검색..."
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
          <Table hover striped size="sm">
            <thead>
              <tr>
                <th>그룹</th>
                <th>분류NO</th>
                <th>분류명</th>
                <th>한글명</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((ele) => (
                <tr
                  key={ele._id}
                  onClick={() => {
                    setHideTable(true);
                    onFound(ele);
                  }}
                >
                  <td>{ele.group_name}</td>
                  <td>{ele.group_class_number}</td>
                  <td className="text-start">{ele.group_class}</td>
                  <td className="text-start">{ele.group_class_kr}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p>해당 분류를 클릭해 진행합니다</p>
        </div>
      )}
    </div>
  );
}

export default ItemGroupFindComponent;
