import AccidentAddFormComponent from "component/accident/accidentadd-form-comp";
import ImageViewModalComponent from "component/image/image-view-modal-comp";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import {
  fetchGet,
  fixTypeKorDict,
  getAccCompanyById,
  getItemNameById,
  getUserNameById,
  leaseTypeKorDict,
} from "utils/functions";

function LogDetailColComponent({ label = "", content = "", hidden = false }) {
  return hidden ? (
    <>
      <Col className="me-2" xs="3" md="2" />
      <Col />
    </>
  ) : (
    <>
      <Col className="log-detail-content-label" xs="3" md="2">
        <b>{label}</b>
      </Col>
      <Col className="log-detail-content me-2">{content}</Col>
    </>
  );
}

function LogDetailListTableComponent({ content = [] }) {
  const [itemTextArr, setItemTextArr] = useState([]);
  const [costSum, setSum] = useState(0);

  useEffect(() => {
    if (!content.length) return;

    (async () => {
      const nameArray = [];
      let sum = 0;
      for (let i = 0; i < content.length; i += 1) {
        const element = content[i];
        const cost = parseInt(element.fix_price, 10);
        if (!Number.isNaN(cost)) {
          sum += cost;
        }
        const itemName = await getItemNameById(element.fix_item_id);
        nameArray.push({ idx: element.index, text: itemName });
      }
      setItemTextArr(nameArray);
      setSum(sum);
    })();
  }, [content]);

  return (
    <>
      <Table className="mt-4 log-detail-listtable" size="sm" bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>정비유형</th>
            <th>작업구분</th>
            <th>정비품목</th>
            <th>정비세부내역</th>
            <th>정비금액(원)</th>
            <th>정비사진</th>
          </tr>
        </thead>
        <tbody>
          {content
            .sort((a, b) => a.index - b.index)
            .map((ele) => (
              <tr key={ele.index}>
                <td>{ele.index + 1}</td>
                <td>{fixTypeKorDict[ele.fix_type]}</td>
                <td>{fixTypeKorDict[ele.fix_subtype]}</td>
                <td>
                  {itemTextArr.find((e) => e.idx === ele.index)?.text ?? ""}
                </td>
                <td>{ele.fix_memo}</td>
                <td className="text-end">{ele.fix_price?.toLocaleString()}</td>
                <td>
                  {ele.fix_image_url?.id ? (
                    <ImageViewModalComponent
                      buttonClassName=""
                      buttonSize="sm"
                      url={ele.fix_image_url?.url}
                    >
                      확인
                    </ImageViewModalComponent>
                  ) : (
                    <p className="mb-0">미등록</p>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Stack direction="horizontal">
        <div className="me-auto" />
        <p>정비금액 합계: {costSum.toLocaleString()}원 (VAT포함)</p>
      </Stack>
    </>
  );
}

/**
 * Show details of log
 * @param {*} initLogData full log into object
 */
function LogDetailComponent({ logId, children }) {
  const [logData, setLogData] = useState({});
  const [bikeInfo, setBikeInfo] = useState({});
  const [modelInfo, setModelInfo] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [maintainerInfo, setMaintainerInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [contractorInfo, setContractorInfo] = useState({});

  const [showAccidentDetail, setAccidentDetail] = useState(false);

  useEffect(() => {
    fetchGet(`/log/${logId}`)
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then(async (data) => {
        if (data.fix_log_details.length) {
          for (let i = 0; i < data.fix_log_details.length; i += 1) {
            data.fix_log_details[i].fix_item_name = await getItemNameById(
              data.fix_log_details[i].fix_item_id
            );
          }
        }

        if (data.accident_id) {
          data.accident_company_name = await getAccCompanyById(
            data.accident_id,
            "-"
          );
          const accRes = await fetchGet(`/acc/${data.accident_id}`);
          data.accident_data = await accRes.json();
        }

        setLogData(data);
        setBikeInfo(data.bike_info);
        setMaintainerInfo(data.maintainer_info);

        setLocationInfo(data.location_info);
        setModelInfo(data.model_info);
        setCustomerInfo(data.customer_info);
        setContractorInfo(data.contractor_info);
      })
      .catch((e) => console.error(e));
  }, []);

  return logData && bikeInfo && modelInfo ? (
    <>
      {children}
      <div className="log-detail-header">
        <b>차량정보</b>
      </div>
      <Container className="mb-2">
        <Row className="mb-2">
          <LogDetailColComponent
            label="차량번호"
            content={bikeInfo.bike_name_id}
          />
          <LogDetailColComponent
            label="차대번호"
            content={bikeInfo.bike_uniq_id}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="제조사"
            content={modelInfo.bike_maker}
          />
          <LogDetailColComponent
            label="차량모델"
            content={modelInfo.bike_model}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent label="연식" content={modelInfo.bike_aged} />
          <LogDetailColComponent
            label="배기량 (cc)"
            content={modelInfo.bike_cc}
          />
        </Row>
      </Container>
      <div className="log-detail-header">
        <b>운영정보</b>
      </div>
      <Container className="mb-2">
        <Row className="mb-2">
          <LogDetailColComponent
            label="등록 가맹점"
            content={maintainerInfo?.user_name ?? "-미등록-"}
          />
          <LogDetailColComponent
            label="가맹점 연락처"
            content={maintainerInfo?.user_operator_call?.toMobileString() ?? ""}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="고객1 (소유주)"
            content={contractorInfo?.customer_name ?? "-"}
          />
          <LogDetailColComponent
            label="고객1 연락처"
            content={contractorInfo?.customer_phone?.toMobileString() ?? "-"}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="고객2 (사용자)"
            content={customerInfo?.customer_name ?? "-미등록-"}
          />
          <LogDetailColComponent
            label="고객2 연락처"
            content={customerInfo?.customer_phone?.toMobileString() ?? ""}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="소유구분"
            content={leaseTypeKorDict[bikeInfo.lease_type]}
          />
          <LogDetailColComponent
            label="리스조건"
            hidden={bikeInfo.lease_type !== "LEASE"}
            content={bikeInfo.lease_status ? "인수" : "반납"}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="리스계약 시작일"
            hidden={bikeInfo.lease_type !== "LEASE"}
            content={bikeInfo.lease_start_date?.slice(0, 10)}
          />
          <LogDetailColComponent
            label="리스계약 종료일"
            hidden={bikeInfo.lease_type !== "LEASE"}
            content={bikeInfo.lease_end_date?.slice(0, 10)}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="운영리스정비"
            hidden={bikeInfo.lease_type !== "LEASE"}
            content={bikeInfo.lease_bike_fee_included ? "예" : "아니오"}
          />
          <Col xs="6" />
        </Row>
      </Container>
      <div className="log-detail-header">
        <b>정비정보</b>
      </div>
      <Container className="mb-2">
        <Row className="mb-2">
          <LogDetailColComponent
            label="정비일시"
            content={logData.fix_date?.slice(0, 16).replace("T", " ")}
          />
          <LogDetailColComponent
            label="주행거리 (km)"
            content={logData.fix_drivedist?.toLocaleString()}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="정비 가맹점"
            content={locationInfo.user_name}
          />
          <LogDetailColComponent
            label="출장여부"
            content={logData.fix_outside ? "출장" : "내점"}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="보험사"
            content={logData.accident_company_name}
          />
          <Col xs="6">
            {logData.accident_id && (
              <Button
                variant="link"
                size="sm"
                onClick={() => {
                  setAccidentDetail(true);
                }}
              >
                상세 정보 보기
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      {logData.fix_log_details?.length && (
        <LogDetailListTableComponent content={logData.fix_log_details} />
      )}
      {logData.require_guarantee && (
        <>
          <div className="log-detail-header">
            <b>보증AS 공통</b>
          </div>
          <Table className="log-detail-listtable" bordered>
            <thead>
              <tr>
                <th>차량번호</th>
                <th>계기판</th>
                <th>차량 전면</th>
                <th>차량 후면</th>
                <th>차량 좌측면</th>
                <th>차량 우측면</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {["number", "dashboard", "front", "back", "left", "right"].map(
                  (nameKey) => {
                    return (
                      <td key={nameKey}>
                        {logData.fix_guarantee_images[nameKey]?.id && (
                          <ImageViewModalComponent
                            buttonSize="sm"
                            buttonClassName="mx-2"
                            url={logData.fix_guarantee_images[nameKey].url}
                          >
                            확인
                          </ImageViewModalComponent>
                        )}
                      </td>
                    );
                  }
                )}
              </tr>
            </tbody>
          </Table>
        </>
      )}

      <Modal
        backdrop="static"
        show={showAccidentDetail}
        onHide={() => setAccidentDetail(false)}
      >
        <Modal.Header closeButton>보험 상세 정보</Modal.Header>
        <Modal.Body>
          <AccidentAddFormComponent
            disabled
            defaultData={logData.accident_data}
          />
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <div />
  );
}

export default LogDetailComponent;
