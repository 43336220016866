import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { fetchGet } from "utils/functions";

function AnalyticsLoginComponent() {
  const [bikeData, setBikeData] = useState({});
  const [loginData, setLoginData] = useState({});
  const [customerData, setCustomerData] = useState({});

  const customerCategory = [
    {
      key: "NORMAL".toLowerCase(),
      kr: "일반 고객",
    },
    {
      key: "DELIVERY".toLowerCase(),
      kr: "배달종사자",
    },
    {
      key: "fullCount",
      kr: "총",
    },
  ];

  const bikeCategory = [
    {
      key: "NORMAL".toLowerCase(),
      kr: "일반",
    },
    {
      key: "LEASE".toLowerCase(),
      kr: "리스",
    },
    {
      key: "fullCount",
      kr: "총",
    },
  ];

  useEffect(() => {
    (async () => {
      const res = await fetchGet("/analytics/bike");
      if (!res.ok) return;
      const data = await res.json();
      setBikeData((prev) => {
        return { ...prev, ...data };
      });
    })();
    (async () => {
      const res = await fetchGet("/analytics/login");
      if (!res.ok) return;
      const data = await res.json();
      setLoginData((prev) => {
        return { ...prev, ...data };
      });
    })();
    (async () => {
      const res = await fetchGet("/analytics/user");
      if (!res.ok) return;
      const data = await res.json();
      setCustomerData((prev) => {
        return { ...prev, ...data };
      });
    })();
  }, []);

  return (
    <>
      <h2>접속 통계</h2>
      <hr />
      <Table>
        <tbody>
          <tr>
            <th>전일~현재</th>
            <td>{loginData.todayCount}</td>
          </tr>
          <tr>
            <th>금주</th>
            <td>{loginData.weeklyCount}</td>
          </tr>
          <tr>
            <th>전주</th>
            <td>{loginData.lastWeekCount}</td>
          </tr>
        </tbody>
      </Table>
      <hr />
      <h2>고객 통계</h2>
      <hr />
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>전일~현재</th>
            <th>당월</th>
            <th>전월</th>
            <th>누적</th>
          </tr>
        </thead>
        <tbody>
          {customerCategory.map((e) => {
            if (!customerData.todayCount) return <tr key={e.key} />;
            return (
              <tr key={e.key}>
                <th>{e.kr}</th>
                <td>{customerData.todayCount[e.key]}</td>
                <td>{customerData.monthlyCount[e.key]}</td>
                <td>{customerData.lastMonthCount[e.key]}</td>
                <td>{customerData.fullCount[e.key]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <hr />
      <h2>차량 통계</h2>
      <hr />
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>전일~현재</th>
            <th>당월</th>
            <th>전월</th>
            <th>누적</th>
          </tr>
        </thead>
        <tbody>
          {bikeCategory.map((e) => {
            if (!bikeData.todayCount) return <tr key={e.key} />;
            return (
              <tr key={e.key}>
                <th>{e.kr}</th>
                <td>{bikeData.todayCount[e.key]}</td>
                <td>{bikeData.monthlyCount[e.key]}</td>
                <td>{bikeData.lastMonthCount[e.key]}</td>
                <td>{bikeData.fullCount[e.key]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <hr />
    </>
  );
}

export default AnalyticsLoginComponent;
