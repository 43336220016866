import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  checkPermission,
  fetchGet,
  getCustomerNameById,
  getUserNameById,
} from "utils/functions";

function FranchAssignedBike() {
  const [myList, setMyList] = useState([]);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const myFranchId = sessionStorage.getItem("_id");

  useEffect(() => {
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }

    fetchGet(`/bike/byf/${myFranchId}`)
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(await res.text());
      })
      .then(async (data) => {
        return data.map(async (val) => {
          return {
            ...val,
            customer_name: await getCustomerNameById(val.customer_cid),
            contractor_name: await getCustomerNameById(val.contractor_cid),
          };
        });
      })
      .then((promises) => Promise.all(promises))
      .then((data) => {
        console.log(data);
        setMyList(data);
      })
      .catch((err) => console.error(err));
  }, []);

  function handleBikeTableClick(bike_id) {
    if (selected === bike_id) {
      navigate(`/f/bike/${bike_id}`);
      return;
    }
    setSelected(bike_id);
  }

  return (
    <>
      <h2>연결된 차량</h2>
      <h6>차량을 두번 클릭해 확인할 수 있습니다.</h6>
      <Container>
        <Table striped bordered responsive hover>
          <thead>
            <tr>
              <th>차대번호</th>
              <th>차량번호</th>
              <th>차종</th>
              <th>고객1 (소유주)</th>
              <th>고객2 (사용자)</th>
              <th>리스사</th>
            </tr>
          </thead>
          <tbody>
            {myList.map((val) => (
              <tr
                className={selected === val._id ? "selected-row" : ""}
                key={val._id}
                onClick={() => handleBikeTableClick(val._id)}
              >
                <td>{val.bike_uniq_id}</td>
                <td>{val.bike_name_id}</td>
                <td>{val.model_info?.bike_model}</td>
                <td>{val.contractor_name}</td>
                <td>{val.customer_name}</td>
                <td>{val.lease_name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default FranchAssignedBike;
