import React, { useEffect, useState } from "react";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { fetchGet } from "utils/functions";

function BikeModelFindComponent({
  onFound = (found) => {},
  buttonText = "선택",
  preselectedModel = {},
}) {
  const [bikeMakerList, setBikeMakerList] = useState([]);
  const [bikeModelList, setModelList] = useState([]);
  const [modelFilteredList, setFilteredList] = useState([]);

  const [firstFlow, setFirstFlow] = useState(true);

  const [selectedMaker, setMaker] = useState("");
  const [selectedModel, setModel] = useState("");
  const [selectedAged, setAged] = useState(0);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setSelected(preselectedModel);
    if (preselectedModel._id) {
      setMaker(preselectedModel.bike_maker);
    }

    fetchGet("/keyword/byg/모델-제조사")
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((makerList) => {
        setBikeMakerList(makerList);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (selectedMaker.length === 0) return;
    if (preselectedModel._id && firstFlow) {
      setModel(preselectedModel.bike_model);
      return;
    }

    fetchGet(`/model?bike_maker=${selectedMaker}`)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((data) => {
        setModelList(data);
        if (data.length === 1) {
          setSelected(data[0]);
          setModel(data[0].bike_model);
          setFilteredList(data);
          setAged(data[0].bike_aged);
        } else {
          setModel("");
          setAged(0);
          setFilteredList([]);
          setSelected({});
        }
      })
      .catch((err) => console.error(err));
  }, [selectedMaker]);

  useEffect(() => {
    if (selectedModel.length === 0) {
      setFilteredList([]);
      setSelected({});
      setAged(0);
      return;
    }
    if (preselectedModel._id && firstFlow) {
      setAged(preselectedModel.bike_aged);
      setFirstFlow(false);
      return;
    }

    const newFiltered = bikeModelList.filter(
      (e) => e.bike_model === selectedModel
    );
    setFilteredList(newFiltered);
    if (newFiltered.length === 1) {
      setSelected(newFiltered[0]);
      setAged(newFiltered[0].bike_aged);
    }
  }, [selectedModel]);

  return (
    <Stack direction="horizontal" gap={3}>
      <Dropdown>
        <Dropdown.Toggle>
          {selectedMaker.length === 0 ? "제조사" : selectedMaker}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {bikeMakerList.map((ele) => (
            <Dropdown.Item key={ele} onClick={() => setMaker(ele)}>
              {ele}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle disabled={bikeModelList.length === 0}>
          {selectedModel.length === 0 ? "차량모델" : selectedModel}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {bikeModelList
            .filter((item, pos) => {
              return (
                bikeModelList.findIndex(
                  (ele) => ele.bike_model === item.bike_model
                ) === pos
              );
            })
            .map((ele) => (
              <Dropdown.Item
                key={ele.bike_model}
                onClick={() => setModel(ele.bike_model)}
              >
                {ele.bike_model}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle disabled={modelFilteredList.length === 0}>
          {selectedAged === 0 ? "연식" : selectedAged}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {modelFilteredList.map((ele) => (
            <Dropdown.Item
              key={ele.bike_aged}
              onClick={() => {
                setAged(ele.bike_aged);
                setSelected(ele);
              }}
            >
              {ele.bike_aged}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Button
        disabled={!selected._id && !preselectedModel._id}
        onClick={() => onFound(selected._id ? selected : preselectedModel)}
      >
        {buttonText}
      </Button>
    </Stack>
  );
}

export default BikeModelFindComponent;
