import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchPost, fetchPut } from "utils/functions";

/**
 *
 * @param props
 * selected
 * @returns
 */
function CenterUserActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);
  const [fixMode, setFixMode] = useState(false);
  const [showChangepass, setChangepass] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [newPassValue, setNewPassValue] = useState("");

  useEffect(() => {
    setItemSelected(selected.has("user_id"));
  }, [selected]);

  function handleFormValueChange(event) {
    setFormValues((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const radioDict = {
    가맹점: "FRANCH",
    본사: "CENTER",
    관리자: "ADMIN",
    리스사: "LEASE",
    B2B: "BUSINESS",
    대기: "WAIT",
    운영: "OPER",
    종료: "END",
    FRANCH: "FRANCH",
    CENTER: "CENTER",
    ADMIN: "ADMIN",
    LEASE: "LEASE",
    BUSINESS: "BUSINESS",
    WAIT: "WAIT",
    OPER: "OPER",
    END: "END",
  };

  function handleFormRadioValueChange(event, newVal) {
    setFormValues((prevState) => {
      return { ...prevState, [event.target.name]: radioDict[newVal] };
    });
  }

  // #region Add modal
  const userCheckData = [
    [
      "user_type",
      {
        kr: "유저 타입",
        req: true,
        form: (
          <Form.Group key="user_type" controlId="user_type">
            <Form.Label>
              유저 타입 <span className="text-danger">*</span>
            </Form.Label>
            {["가맹점", "리스사", "B2B", "본사", "관리자"].map((type) => (
              <Form.Check
                disabled={showFix}
                key={`${type}_radio`}
                name="user_type"
                type="radio"
                onChange={(e) => handleFormRadioValueChange(e, type)}
                label={type}
                defaultChecked={
                  formValues.user_type === type ||
                  formValues.user_type === radioDict[type]
                }
              />
            ))}
          </Form.Group>
        ),
      },
    ],
    ["user_id", { kr: "유저 ID", req: true }],
    ["user_name", { kr: "상호명(계정명)", req: true }],
    ["user_location", { kr: "지역", req: true }],
    ["user_company_number", { kr: "사업자 등록번호", req: true }],
    ["user_ceo", { kr: "대표자명", req: true }],
    ["user_mobile", { kr: "모바일 연락처", req: true, type: "tel" }],
    ["user_address", { kr: "주소", req: true }],
    [
      "user_status",
      {
        kr: "계정 상태",
        req: true,
        form: (
          <Form.Group key="user_status" controlId="user_status">
            <Form.Label>
              계정 상태 <span className="text-danger">*</span>
            </Form.Label>
            {["대기", "운영", "종료"].map((type) => (
              <Form.Check
                disabled={showFix && !fixMode}
                key={`${type}_radio`}
                name="user_status"
                type="radio"
                onChange={(e) => handleFormRadioValueChange(e, type)}
                label={type}
                defaultChecked={
                  formValues.user_status === type ||
                  formValues.user_status === radioDict[type]
                }
              />
            ))}
          </Form.Group>
        ),
      },
    ],
    ["user_call", { kr: "매장 연락처", req: false, type: "tel" }],
    ["user_email", { kr: "이메일", req: false }],
    ["user_operator", { kr: "운영자명", req: false }],
    ["user_operator_call", { kr: "운영자 연락처", req: false, type: "tel" }],
    ["createdAt", { kr: "계정 생성일", noAdd: true, req: false, type: "date" }],
    [
      "user_contact_start_date",
      { kr: "가맹계약 체결일", req: false, type: "date" },
    ],
    [
      "user_contact_end_date",
      { kr: "가맹계약 종료일", req: false, type: "date" },
    ],
    ["user_expire_date", { kr: "계정 만료일", req: false, type: "date" }],
    ["user_expire_reason", { kr: "계약 해지 사유", req: false }],
    ["user_lease_info", { kr: "연결리스사 정보", req: false }],
    [
      "additional_memo",
      {
        kr: "비고",
        req: false,
        form: (
          <Form.Group key="additional_memo" controlId="additional_memo">
            <Form.Label>비고</Form.Label>
            <textarea
              name="additional_memo"
              className="form-control"
              rows={2}
              onChange={handleFormValueChange}
              value={formValues.fix_memo}
            />
          </Form.Group>
        ),
      },
    ],
  ];

  function handleAddModalOpenButtonClick() {
    setAdd(true);
    setFormValues({
      user_expire_date: new Date().toISOString().split("T")[0],
    });
  }

  function handleAddFormSubmitButtonClick(event) {
    event.preventDefault();

    // check required fields
    for (const [key, value] of userCheckData) {
      if (value.req && !formValues[key]) {
        alert(`${value.kr}을(를) 입력해주세요.`);
        return;
      }
    }

    fetchPost("/auth", {
      user_passwd: "",
      ...formValues,
      user_expire_date: `${formValues.user_expire_date}Z`,
    })
      .then(async (res) => {
        if (res.ok) {
          setAdd(false);
          navigate(0);
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  }
  // #endregion

  // #region Fix modal
  function handleFixModalOpenButtonClick() {
    setFix(true);
    const sel = new Map(selected);
    const checkData = new Map(userCheckData);
    for (const [key, value] of selected) {
      if (typeof value === "string" && value.startsWith("-")) {
        sel.set(key, undefined);
      }
      if (checkData.has(key) && checkData.get(key).type === "date") {
        sel.set(key, value?.slice(0, 10));
      }
    }
    setFormValues(Object.fromEntries(sel));
  }

  function handleFixFormSubmitButtonClick(event) {
    event.preventDefault();

    // check required fields
    for (const [key, value] of userCheckData) {
      if (value.req && !formValues[key]) {
        alert(`${value.kr}을(를) 입력해주세요.`);
        return;
      }
    }

    fetchPut(`/auth/${selected.get("_id")}`, {
      ...formValues,
      user_type: undefined,
      _id: undefined,
      createdAt: undefined,
      user_status: radioDict[formValues.user_status],
    })
      .then(async (res) => {
        if (res.ok) {
          setFix(false);
          setFixMode(false);
          contentRefresh({
            ...formValues,
            user_status: radioDict[formValues.user_status],
          });
          alert("수정이 완료되었습니다");
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  }
  // #endregion

  // #region change password modal
  function handleChangepassButtonClick() {
    setChangepass(true);
    setNewPassValue("");
  }

  function handleChangepassSubmitButtonClick(event) {
    event.preventDefault();

    fetchPut(`/auth/passwd/${selected.get("_id")}`, {
      user_passwd: newPassValue,
    })
      .then(async (res) => {
        if (res.ok) {
          setChangepass(false);
          alert("비밀번호가 변경되었습니다.");
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  }
  // #endregion

  function handleDeleteButtonClick(event) {
    event.preventDefault();

    if (
      window.confirm(
        `${selected.get("user_name")}의 계정을 종료합니다.\n계속 하시겠습니까?\n
데이터의 완전삭제는 관리자에게 문의해주세요`
      )
    ) {
      fetchDelete(`/auth/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            navigate(0);
            alert(`계정 "${selected.get("user_name")}"이(가) 종료되었습니다`);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => {
          console.error(err);
          alert(err);
        });
    }
  }

  return (
    <>
      <h2>사용자 현황</h2>
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button variant="primary" onClick={handleAddModalOpenButtonClick}>
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleFixModalOpenButtonClick}
            >
              열람 및 수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleChangepassButtonClick}
            >
              비밀번호 변경
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleDeleteButtonClick}
            >
              종료
            </Button>
          </Col>
        </Row>
      </Container>

      {/** add bike modal */}
      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>유저 추가하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {userCheckData
              .filter(([_, { noAdd }]) => !noAdd)
              .map(
                ([key, { kr, req, type, form }]) =>
                  form ?? (
                    <Form.Group key={key} controlId={`add-${key}`}>
                      <Form.Label>
                        {kr} {req && <span className="text-danger">*</span>}
                      </Form.Label>
                      <Form.Control
                        name={key}
                        type={type ?? "text"}
                        onChange={handleFormValueChange}
                        required={req}
                        value={formValues[key] || ""}
                      />
                    </Form.Group>
                  )
              )}
            <Form.Text>
              계정 추가 후 비밀번호 변경을 해주시기 바랍니다.
              <br />
              ID와 유저 타입은 변경할 수 없습니다.
            </Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddFormSubmitButtonClick}>
            저장
          </Button>
          <Button variant="secondary" onClick={() => setAdd(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {/** fix bike modal */}
      <Modal
        backdrop="static"
        size="lg"
        show={showFix}
        onHide={() => {
          setFix(false);
          setFixMode(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>유저 열람 및 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className="me-2" onClick={() => setFixMode(true)}>
            수정
          </Button>
          <Button
            className="me-2"
            disabled={!fixMode}
            variant="primary"
            onClick={handleFixFormSubmitButtonClick}
          >
            저장
          </Button>
          <Button
            className="me-2"
            variant="secondary"
            onClick={() => {
              setFix(false);
              setFixMode(false);
            }}
          >
            닫기
          </Button>
          <Form>
            <Form.Label>db_id</Form.Label>
            <Form.Control disabled value={formValues._id} />
            {userCheckData.map(
              ([key, { kr, req, type, form }]) =>
                form ?? (
                  <Form.Group key={key} controlId={`fix-${key}`}>
                    <Form.Label>
                      {kr} {req && <span className="text-danger">*</span>}
                    </Form.Label>
                    <Form.Control
                      name={key}
                      type={type ?? "text"}
                      onChange={handleFormValueChange}
                      required={req}
                      value={formValues[key] || ""}
                      disabled={key === "user_type" || !fixMode}
                    />
                  </Form.Group>
                )
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setFixMode(true)}>수정</Button>
          <Button
            disabled={!fixMode}
            variant="primary"
            onClick={handleFixFormSubmitButtonClick}
          >
            저장
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setFix(false);
              setFixMode(false);
            }}
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {/** change password */}
      <Modal
        backdrop="static"
        show={showChangepass}
        onHide={() => setChangepass(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>비밀번호 수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="user_passwd">
              <Form.Label>새로운 비밀번호</Form.Label>
              <Form.Control
                name="user_passwd"
                type="password"
                value={newPassValue}
                onChange={(e) => setNewPassValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangepassSubmitButtonClick}>
            저장
          </Button>
          <Button variant="secondary" onClick={() => setChangepass(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CenterUserActionsComponent;
