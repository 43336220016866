import React from "react";
import { createEnteredText, fetchGet, objectToQuery } from "utils/functions";
import CenterTableComponent from "../center-table-comp";

function ItemGroupTableComponent({ setSelected, contentRefresh }) {
  const defaultItemGroupTitles = new Map([
    ["bike_model_id", { title: "차량 모델 ID", visible: false }],
    ["bike_maker", { title: "제조사", visible: true }],
    ["bike_model", { title: "차량모델", visible: true }],
    ["bike_aged", { title: "연식", visible: true }],
    ["group_name", { title: "카테고리", visible: true }],
    ["group_class_number", { title: "도면NO.", visible: true }],
    ["group_class", { title: "도면 타이틀", visible: true, leftAlign: true }],
    [
      "group_class_kr",
      { title: "도면 타이틀 (한글)", visible: true, leftAlign: true },
    ],
    [
      "part_image",
      { title: "파츠 도면 이미지", visible: false, leftAlign: true },
    ],
    [
      "part_image_last_edited",
      {
        title: ["도면 이미지", "최종수정일"],
        visible: false,
      },
    ],
    ["createdAt", { title: "등록일", visible: true }],
    ["_id", { title: "db_id", visible: false, sortable: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "itemGroupTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultItemGroupTitles}
      getDataPromise={async (queryObject) => {
        const query = objectToQuery(queryObject);
        const res = await fetchGet(`/igroup${query}`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        // console.log(...res.headers);
        header.totalPost = res.headers.get("Total-Post");
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];

          for (const row of data) {
            // reorder
            const newMapped = new Map();
            for await (const key of titleMap.keys()) {
              // process
              if (key === "createdAt" || key === "part_image_last_edited") {
                newMapped.set(key, row[key].slice(0, 10));
              } else if (
                key === "bike_maker" ||
                key === "bike_model" ||
                key === "bike_aged"
              ) {
                newMapped.set(key, row.bikemodel_info[key]);
              } else if (key === "part_image") {
                newMapped.set(key, row[key]?.id ?? "-이미지 없음-");
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default ItemGroupTableComponent;
