import BikeModelFindComponent from "component/bikemodel/bikemodel-find-comp";
import React, { useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { fetchPost, fetchPut, isBikeNumber } from "utils/functions";

/**
 *
 * @param {object} defaultData
 */
function BikeAddFormComponent({
  defaultData = {},
  isRawData = false,
  onFormSubmit = (formValues) => {},
  isRestricted = false,
  children = <div />,
  onCancelButtonClick = () => {},
  isFixMode = false,
}) {
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const convertedFormValues = {
      lease_status: false,
      lease_bike_fee_included: false,
      ...defaultData,
    };

    if (typeof defaultData.lease_start_date === "string") {
      convertedFormValues.lease_start_date = defaultData.lease_start_date.slice(
        0,
        10
      );
    }
    if (typeof defaultData.lease_end_date === "string") {
      convertedFormValues.lease_end_date = defaultData.lease_end_date.slice(
        0,
        10
      );
    }
    if (defaultData.model_info) {
      convertedFormValues.bike_model_text = `${defaultData.model_info.bike_maker} ${defaultData.model_info.bike_model} ${defaultData.model_info.bike_aged}`;
    }

    setFormValues(convertedFormValues);
  }, []);

  function handleFormValueChange(event) {
    setFormValues((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  const bikeCheckData = [
    [
      "bike_model_id",
      {
        kr: "차량 모델",
        req: true,
        form: (
          <Form.Group key="bike_model_id">
            <Form.Label>차량 모델 *</Form.Label>
            <BikeModelFindComponent
              onFound={(found) => {
                setFormValues((prevState) => {
                  return {
                    ...prevState,
                    bike_model_id: found._id,
                    bike_model_text: `${found.bike_maker} ${found.bike_model} ${found.bike_aged}`,
                  };
                });
              }}
            />
            <Form.Control
              className="mt-2"
              disabled
              value={formValues.bike_model_text ?? ""}
            />
          </Form.Group>
        ),
      },
    ],
    ["bike_uniq_id", { kr: "차대번호" }],
    ["bike_name_id", { kr: "차량번호 (한글4/5자 + 숫자4자)", req: true }],
    [
      "lease_type",
      {
        kr: "차량 구분",
        req: true,
        form: (
          <Form.Group key="lease_type" controlId="lease_type">
            <Form.Label>
              차량 구분 <span className="text-danger">*</span>
            </Form.Label>
            {["일반", "리스"].map((type) => (
              <Form.Check
                key={`${type}_radio`}
                name="lease_type"
                type="radio"
                required
                onChange={() => {
                  setFormValues((prevState) => {
                    return {
                      ...prevState,
                      lease_type: { 일반: "NORMAL", 리스: "LEASE" }[type],
                    };
                  });
                }}
                label={type}
                checked={
                  formValues.lease_type === type ||
                  formValues.lease_type ===
                    { 일반: "NORMAL", 리스: "LEASE" }[type]
                }
              />
            ))}
          </Form.Group>
        ),
      },
    ],
    ["lease_name", { kr: "리스사명", req: false }],
    ["lease_start_date", { kr: "계약 시작일", req: false, type: "date" }],
    ["lease_end_date", { kr: "계약 종료일", req: false, type: "date" }],
    [
      "lease_bike_fee_included",
      {
        kr: "정비비 포함 여부",
        req: false,
        form: (
          <Form.Group
            key="lease_bike_fee_included"
            controlId="lease_bike_fee_included"
          >
            <Form.Label>정비비 포함 여부 *</Form.Label>
            <Form.Check
              label={formValues.lease_bike_fee_included ? "포함" : "미포함"}
              type="switch"
              name="lease_bike_fee_included"
              onChange={(e) => {
                setFormValues((prevState) => {
                  return {
                    ...prevState,
                    lease_bike_fee_included: e.target.checked,
                  };
                });
              }}
              defaultChecked={formValues.lease_bike_fee_included}
            />
          </Form.Group>
        ),
      },
    ],
    [
      "lease_status",
      {
        kr: "조건(반납/인수)",
        req: false,
        type: "switch",
        form: (
          <Form.Group key="lease_status" controlId="lease_status">
            <Form.Label>조건(반납/인수) *</Form.Label>
            <Form.Check
              label={formValues.lease_status ? "인수" : "반납"}
              type="switch"
              name="lease_status"
              onChange={(e) => {
                setFormValues((prevState) => {
                  return {
                    ...prevState,
                    lease_status: e.target.checked,
                  };
                });
              }}
              defaultChecked={formValues.lease_status}
            />
          </Form.Group>
        ),
      },
    ],
    [
      "additional_memo",
      {
        kr: "비고",
        req: false,
        form: (
          <Form.Group key="additional_memo" controlId="additional_memo">
            <Form.Label>비고</Form.Label>
            <textarea
              name="additional_memo"
              className="form-control"
              rows={2}
              onChange={handleFormValueChange}
              value={formValues.additional_memo}
            />
          </Form.Group>
        ),
      },
    ],
  ];

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    // check required fields
    for (const [key, value] of bikeCheckData) {
      if (value.req && !formValues[key]) {
        alert(`${value.kr}은(는) 필수 입력 항목입니다`);
        return;
      }
    }
    if (!isBikeNumber(formValues.bike_name_id)) {
      alert("차량번호 형식이 올바르지 않습니다");
      return;
    }
    if (formValues.bike_type === "LEASE") {
      if (formValues.lease_bike_fee_included === undefined) {
        alert("리스 차량은 정비비 포함 여부를 체크해야 합니다");
        return;
      }
    }

    const sendBody = {
      ...formValues,
      _id: undefined,
      bike_type: {
        리스: "LEASE",
        일반: "NORMAL",
        LEASE: "LEASE",
        NORMAL: "NORMAL",
      }[formValues.bike_type],
      lease_start_date: formValues.lease_start_date
        ? `${formValues.lease_start_date}Z`
        : undefined,
      lease_end_date: formValues.lease_end_date
        ? `${formValues.lease_end_date}Z`
        : undefined,
    };

    (isFixMode
      ? fetchPut(`/bike/${formValues._id}`, sendBody)
      : fetchPost("/bike", sendBody)
    )
      .then(async (res) => {
        if (res.ok) {
          alert(`${isFixMode ? "수정이" : "추가가"} 완료되었습니다`);
          onFormSubmit(await res.json());
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <Form>
      {bikeCheckData.map(
        ([key, { kr, req, type, form }]) =>
          form ?? (
            <Form.Group key={key} controlId={`add-${key}`}>
              <Form.Label>
                {kr} {req && <span className="text-danger">*</span>}
              </Form.Label>
              <Form.Control
                disabled={isRestricted && key === "bike_name_id"}
                name={key}
                type={type ?? "text"}
                onChange={handleFormValueChange}
                required={req}
                value={formValues[key] || ""}
              />
            </Form.Group>
          )
      )}
      <Form.Text>{children}</Form.Text>
      <hr />
      <Stack direction="horizontal">
        <div className="ms-auto" />
        <Button onClick={handleSubmitButtonClick}>저장</Button>
        <Button
          className="ms-2"
          variant="secondary"
          onClick={onCancelButtonClick}
        >
          취소
        </Button>
      </Stack>
    </Form>
  );
}

export default BikeAddFormComponent;
