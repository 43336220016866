import React, { useState } from "react";
import { createEnteredText, fetchGet, objectToQuery } from "utils/functions";
import BikeModelFindComponent from "component/bikemodel/bikemodel-find-comp";
import CenterTableComponent from "../center-table-comp";

function CenterItemTableComponent({ setSelected, contentRefresh }) {
  const [toSearchBikeModelId, setBikeModelId] = useState("");

  const defaultItemTitles = new Map([
    ["bike_model_id", { title: "차량모델 ID", visible: false }],
    ["group_id", { title: "정비분류 ID", visible: false }],
    [
      "group_class_number",
      { title: "도면no.", visible: true, generated: true, sorted: 1 },
    ],
    [
      "group_class",
      { title: "도면 타이틀", visible: true, generated: true, leftAlign: true },
    ],
    [
      "group_class_kr",
      {
        title: "한글 도면타이틀",
        visible: true,
        generated: true,
        leftAlign: true,
      },
    ],
    ["item_number", { title: "부품no.", visible: true }],
    ["item_name_en", { title: "품목명(영문)", visible: true, leftAlign: true }],
    ["item_name_kr", { title: "품목명(한글)", visible: true, leftAlign: true }],
    ["item_company_partid", { title: "제조사 품번", visible: true }],
    ["item_amount_per_bike", { title: "적용수량", visible: false }],
    ["item_is_providing", { title: "공급", visible: true }],
    ["item_is_compatible", { title: "호환", visible: false }],
    ["item_providing_start", { title: "공급시작일", visible: false }],
    ["item_providing_end", { title: "공급종료일", visible: true }],
    [
      "jinbike_dist_code",
      {
        title: ["진바이크", "품번"],
        visible: true,
      },
    ],
    ["item_working_time", { title: ["표준", "작업시간"], visible: true }],
    ["jinbike_supply_price", { title: ["진바이크", "출고가"], visible: true }],
    ["customer_price", { title: "표준소비자가", visible: true }],
    [
      "customer_price_last_edited",
      {
        title: ["표준소비자가", "최종수정일"],
        visible: true,
      },
    ],
    ["_id", { title: "db_id", visible: false }],
  ]);

  return (
    <>
      <div className="mx-auto">
        <BikeModelFindComponent
          buttonText="조회"
          onFound={(found) => {
            setBikeModelId(found?._id ?? "");
          }}
        />
      </div>
      {!!toSearchBikeModelId && (
        <CenterTableComponent
          key={toSearchBikeModelId}
          tableMetadata={{ name: "centerItemTable", paginationOption: 2 }}
          contentRefresh={contentRefresh}
          setSelected={setSelected}
          matchedTitle={defaultItemTitles}
          getDataPromise={async (queryObject) => {
            const query = objectToQuery(queryObject);
            const res = await fetchGet(
              `/item/bym/${toSearchBikeModelId}${query}`
            );
            if (!res.ok) throw new Error("Error in fetch");
            let resData = await res.json();
            // data: [], meta: {}, customer_infos: []

            let header = {};
            // console.log(...res.headers);
            [resData] = resData;
            header = { ...resData.meta };
            const toReturn = resData.data.map((ele) => {
              const groupFound = resData.itemgroup_infos.find(
                (cusEle) => cusEle._id === ele.group_id
              );
              return { ...ele, itemgroup_info: groupFound };
            });
            resData = toReturn;
            header.totalPost = res.headers.get("Total-Post");
            return { header, data: resData };
          }}
          processPromise={(data, titleMap) => {
            return new Promise(async (resolve) => {
              const outputData = [];
              for (const row of data) {
                const newMapped = new Map();
                for (const key of titleMap.keys()) {
                  // process
                  if (
                    key === "group_class_number" ||
                    key === "group_class" ||
                    key === "group_class_kr"
                  ) {
                    newMapped.set(
                      key,
                      row.itemgroup_info ? row.itemgroup_info[key] : "-"
                    );
                  } else if (
                    key === "item_is_providing" ||
                    key === "item_is_compatible"
                  ) {
                    newMapped.set(key, row[key] ? "Y" : "N");
                  } else if (
                    !titleMap.get(key).generated &&
                    row[key] === undefined
                  ) {
                    newMapped.set(key, "-");
                  } else if (
                    key === "jinbike_supply_price" ||
                    key === "customer_price"
                  ) {
                    newMapped.set(key, `${row[key].toLocaleString()}원`);
                  } else if (
                    key === "customer_price_last_edited" ||
                    key === "item_providing_start" ||
                    key === "item_providing_end"
                  ) {
                    newMapped.set(key, row[key]?.slice(0, 10));
                  } else {
                    newMapped.set(key, row[key] || "-");
                  }
                }
                outputData.push(newMapped);
              }

              resolve(outputData);
            });
          }}
        />
      )}
    </>
  );
}

export default CenterItemTableComponent;
