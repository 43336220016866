import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { fetchPost, fetchPut } from "utils/functions";

/**
 * form for adding/fixing new customer data
 * @param {object} defaultData
 * @param {boolean} putAction
 */
function CustomerAddFormComponent({
  defaultData = {},
  putAction = false,
  onSubmit = (formValues) => {},
}) {
  const [formValues, setFormValues] = useState({
    customer_type: "DELIVERY",
    customer_name: "",
    customer_phone: "",
    customer_including: "",
  });

  function handleFormValueChange(e) {
    setFormValues((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  useEffect(() => {
    if (Object.keys(defaultData).length === 0) {
      return;
    }
    setFormValues(() => {
      const output = { ...defaultData };

      output.customer_type = {
        일반: "NORMAL",
        배달종사자: "DELIVERY",
        NORMAL: "NORMAL",
        DELIVERY: "DELIVERY",
      }[defaultData.customer_type];
      output.customer_last_used = sessionStorage.getItem("_id");

      return output;
    });
  }, []);

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    // check required fields
    if (formValues.customer_name === "") {
      alert("이름을 입력하세요");
      return;
    }
    if (formValues.customer_phone === "") {
      alert("전화번호를 입력하세요");
      return;
    }
    formValues.customer_created = sessionStorage.getItem("_id");

    // post or put
    (() => {
      if (putAction) {
        return fetchPut(`/customer/${defaultData._id}`, formValues);
      }
      return fetchPost(`/customer`, formValues);
    })()
      .then(async (res) => {
        if (res.ok) {
          alert("저장되었습니다");
          onSubmit(formValues);
        } else {
          const text = await res.json();
          alert(text.err);
          throw new Error(JSON.stringify(text));
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mx-auto">
          <Form>
            <Form.Group>
              <Form.Label>고객 타입</Form.Label>
              {[
                { name: "일반", value: "NORMAL" },
                { name: "배달종사자", value: "DELIVERY" },
              ].map((item) => {
                return (
                  <Form.Check
                    key={item.value}
                    type="radio"
                    name="customer_type"
                    label={item.name}
                    value={item.value}
                    onChange={handleFormValueChange}
                    checked={formValues.customer_type === item.value}
                  />
                );
              })}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                이름 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customer_name"
                onChange={handleFormValueChange}
                defaultValue={formValues.customer_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                전화번호 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="customer_phone"
                onChange={handleFormValueChange}
                defaultValue={formValues.customer_phone}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>고객 소속</Form.Label>
              <Form.Control
                type="text"
                name="customer_including"
                onChange={handleFormValueChange}
                defaultValue={formValues.customer_including}
              />
            </Form.Group>

            <hr />
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmitButtonClick}
            >
              {putAction ? "수정하기" : "추가하기"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default CustomerAddFormComponent;
