import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getCustomerNameById,
  getUserNameById,
} from "utils/functions";
import BikeAddFormComponent from "./bikeadd-form-comp";
import BikeDetailTableComponent from "./bikedetail-table-comp";

/**
 *
 * @param props
 * selected
 * @returns
 */
function CenterBikeActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showAssign, setAssign] = useState(false);
  const [showFix, setFix] = useState(false);
  const [showDetail, setDetail] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [assignFormValues, setAssignFormValues] = useState({
    bike_maintainer: "",
    bike_maintainer__type: "user_name",
    lease: "",
    lease__type: "user_name",
    customer: "",
    customer__type: "customer_name",
    contractor: "",
    contractor__type: "customer_name",
  });
  const [assignNames, setAssignNames] = useState({
    bike_maintainer: "",
    contractor: "",
    customer: "",
  });
  const [newAssign, setNewAssign] = useState({});

  const linkedUsers = [
    {
      table: "user",
      type: "bike_maintainer",
      title: "관리 가맹점",
    },
    { table: "customer", type: "contractor", title: "고객1 (소유주)" },
    { table: "customer", type: "customer", title: "고객2 (사용자)" },
  ];

  useEffect(() => {
    if (selected.has("bike_name_id")) {
      setItemSelected(true);
      setFormValues(Object.fromEntries(selected));
    } else {
      setItemSelected(false);
    }
    setAssignFormValues({
      bike_maintainer: "",
      bike_maintainer__type: "user_name",
      lease: "",
      lease__type: "user_name",
      customer: "",
      customer__type: "customer_name",
      contractor: "",
      contractor__type: "customer_name",
    });
    setNewAssign({});

    async function getLinkedUserNames() {
      const newNames = { ...assignNames };
      for (let i = 0; i < linkedUsers.length; i += 1) {
        const userInfo = linkedUsers[i];
        const id = selected.get(
          `${userInfo.type}_${userInfo.table === "customer" ? "cid" : "uid"}`
        );
        if (id) {
          if (userInfo.table === "user") {
            newNames[userInfo.type] = await getUserNameById(id, "");
          } else if (userInfo.table === "customer") {
            newNames[userInfo.type] = await getCustomerNameById(id, "");
          }
        }
      }
      setAssignNames(newNames);
    }
    getLinkedUserNames();
  }, [selected]);

  // #region Assign modal
  function handleAssignModalOpenButtonClick() {
    setAssign(true);
    setNewAssign({});
  }

  function handleAssignFormValueChange(event) {
    setAssignFormValues((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  }

  /**
   *
   * @param {*} type 'bike_maintainer' | 'customer' | 'contractor'
   */
  function handleAssignCheckButtonClick(type) {
    const matching = {
      bike_maintainer: "FRANCH",
      customer: false,
      contractor: false,
    };

    if (matching[type]) {
      // when maintainer or lease
      if (!assignFormValues[type].length) {
        return;
      }
      let advSearchQuery = "";
      if (assignFormValues[`${type}__type`] === "user_name") {
        advSearchQuery = `user_name=*${assignFormValues[type]}*`;
      } else if (assignFormValues[`${type}__type`] === "_id") {
        advSearchQuery = `_id=${assignFormValues[type]}`;
      }
      fetchGet(`/auth/?user_type=${matching[type]}&${advSearchQuery}`)
        .then(async (res) => {
          if (!res.ok) {
            switch (res.status) {
              case 404:
                throw new Error("유저가 존재하지 않습니다");
              default:
                throw new Error(await res.text());
            }
          }
          return res.json();
        })
        .then((usr) => {
          if (!usr) throw new Error("User not found!");
          setNewAssign((prev) => {
            const nextState = { ...prev };
            nextState[`${type}Id`] = usr[0]._id;
            nextState[`${type}Name`] = usr[0].user_name;
            nextState[`${type}_uid`] = usr[0]._id;
            return nextState;
          });
        })
        .catch((err) => alert(err));
    } else {
      // when customer
      if (!assignFormValues[type].length) {
        return;
      }
      let advSearchQuery = "";
      if (assignFormValues[`${type}__type`] === "customer_name") {
        advSearchQuery = `customer_name=${assignFormValues[type]}`;
      } else if (assignFormValues[`${type}__type`] === "_id") {
        advSearchQuery = `_id=${assignFormValues[type]}`;
      }
      fetchGet(`/customer/?${advSearchQuery}`)
        .then(async (res) => {
          if (!res.ok) {
            switch (res.status) {
              case 404:
                throw new Error(
                  "등록되지 않은 고객입니다. 등록 후 적용해주세요"
                );
              default:
                throw new Error(await res.text());
            }
          }
          return res.json();
        })
        .then((usr) => {
          if (!usr) throw new Error("User not found!");
          setNewAssign((prev) => {
            const nextState = {};
            nextState[`${type}Id`] = usr[0]._id;
            nextState[`${type}Name`] = usr[0].customer_name;
            nextState[`${type}_cid`] = usr[0]._id;
            return nextState;
          });
        })
        .catch((err) => alert(err.message));
    }
  }

  function handleAssignSubmitButtonClick() {
    fetchPut(`/bike/uid/${selected.get("_id")}`, newAssign)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("수정이 완료되었습니다.");
        contentRefresh({
          _id: selected.get("_id"),
          ...newAssign,
        });
      })
      .catch((err) => console.error(err));
  }
  // #endregion

  function handleDeleteButtonClick() {
    if (
      window.confirm(
        `차량 '${selected.get(
          "bike_name_id"
        )}'를 종료합니다.\n계속 하시겠습니까?\n
데이터의 완전삭제는 관리자를 문의해주세요`
      )
    ) {
      fetchDelete(`/bike/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            alert(`차량 "${selected.get("bike_name_id")}"이 종료되었습니다`);
            navigate(0);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  function handleForceDeleteButtonClick() {
    if (
      window.confirm(
        `차량 '${selected.get(
          "bike_name_id"
        )}'을 완전히 삭제합니다.\n계속 하시겠습니까?\n
삭제된 데이터는 복구할 수 없습니다`
      )
    ) {
      fetchDelete(`/bike/force/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            alert(`차량 "${selected.get("bike_name_id")}"이 삭제되었습니다`);
            navigate(0);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  return (
    <>
      <h2>차량 현황</h2>
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button variant="primary" onClick={() => setAdd(true)}>
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setDetail(true)}
            >
              열람
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setFix(true)}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleAssignModalOpenButtonClick}
            >
              가맹점/고객 연결
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleDeleteButtonClick}
            >
              종료
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleForceDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {
        // #region bike add modal
      }
      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>차량 추가하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BikeAddFormComponent
            onFormSubmit={() => {
              setAdd(false);
              navigate(0);
            }}
            onCancelButtonClick={() => setAdd(false)}
          >
            사용자, 가맹점, 리스사 연결은 추가 후 가능합니다. <br />
            차대번호는 변경할 수 없습니다.
          </BikeAddFormComponent>
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {
        // #region fix bike info modal
      }
      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>차량 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BikeAddFormComponent
            isFixMode
            onFormSubmit={(submitted) => {
              setFix(false);
              contentRefresh(submitted);
            }}
            defaultData={formValues}
            onCancelButtonClick={() => setFix(false)}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {/** detail view of bike modal */}
      <Modal
        backdrop="static"
        show={showDetail}
        size="lg"
        onHide={() => setDetail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>차량 상세</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BikeDetailTableComponent bikeObject={formValues} />
        </Modal.Body>
      </Modal>

      {/** assign bike to franch modal */}
      <Modal
        backdrop="static"
        show={showAssign}
        onHide={() => setAssign(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>차량 연결하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {linkedUsers.map((item) => (
            <div key={item.type}>
              <h5>{item.title}</h5>
              <Container className="mb-4">
                <Row>
                  <Col>
                    <Stack gap={2} direction="horizontal">
                      <Form.Select
                        style={{ width: "180px" }}
                        name={`${item.type}__type`}
                        value={assignFormValues[`${item.type}__type`]}
                        onChange={handleAssignFormValueChange}
                      >
                        <option value={`${item.table}_name`}>이름</option>
                        <option value="_id">DB_id</option>
                      </Form.Select>
                      <Form.Control
                        name={item.type}
                        type="text"
                        value={assignFormValues[item.type]}
                        onChange={handleAssignFormValueChange}
                      />
                      <Button
                        className="text-nowrap"
                        onClick={() => handleAssignCheckButtonClick(item.type)}
                      >
                        확인
                      </Button>
                      <Button
                        className="text-nowrap"
                        disabled={!newAssign[`${item.type}Id`]}
                        onClick={() => handleAssignSubmitButtonClick(item.type)}
                      >
                        적용
                      </Button>
                    </Stack>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5} className="text-center">
                    <Card>
                      <Card.Title>{assignNames[item.type]}</Card.Title>
                      <Card.Body>
                        {selected.get(
                          `${item.type}_${
                            item.table === "customer" ? "cid" : "uid"
                          }`
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="text-center">--{">"}</Col>
                  <Col sm={5} className="text-center">
                    <Card>
                      <Card.Title>{newAssign[`${item.type}Name`]}</Card.Title>
                      <Card.Body>{newAssign[`${item.type}Id`]}</Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAssign(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CenterBikeActionsComponent;
