import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Stack } from "react-bootstrap";
import CenterSidebarComponent from "component/center-sidebar-comp";
import CenterBikeTableComponent from "component/bike/bike-table-comp";
import CenterBikeActionsComponent from "component/bike/bike-actions-comp";
import CenterUserTableComponent from "component/user/user-table-comp";
import CenterUserActionsComponent from "component/user/user-actions-comp";
import CenterLogTableComponent from "component/log/log-table-comp";
import CenterLogActionsComponent from "component/log/log-actions-comp";
import CenterItemTableComponent from "component/item/item-table-comp";
import CenterItemActionsComponent from "component/item/item-actions-comp";
import CenterCustomerTableComponent from "component/customer/customer-table-comp";
import CenterCustomerActionsComponent from "component/customer/customer-actions-comp";
import AnnounceTableComponent from "component/announce/announce-table-comp";
import AnnounceActionsComponent from "component/announce/announce-actions-comp";
import AccidentActionsComponent from "component/accident/accident-actions-comp";
import AccidentTableComponent from "component/accident/accident-table-comp";
import BikeModelActionComponent from "component/bikemodel/bikemodel-action-comp";
import BikeModelTableComponent from "component/bikemodel/bikemodel-table-comp";
import ItemGroupActionsComponent from "component/itemgroup/itemgroup-actions-comp";
import ItemGroupTableComponent from "component/itemgroup/itemgroup-table-comp";
import KeywordActionsComponent from "component/keyword/keyword-actions-comp";
import KeywordTableComponent from "component/keyword/keyword-table-comp";

function CenterTable() {
  const { table_type } = useParams();
  const [selected, setSelected] = useState(new Map());
  const [contentRefresh, setContentRefresh] = useState({});

  function refreshContent(obj) {
    console.log(obj);
    if (!obj._id) return;
    setContentRefresh(obj);
  }

  function checkTableData() {
    switch (table_type) {
      case "bike":
        return (
          <Stack>
            <CenterBikeActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <CenterBikeTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "user":
        return (
          <Stack>
            <CenterUserActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <CenterUserTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "log":
        return (
          <Stack>
            <CenterLogActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            >
              <h2>정비 이력 현황</h2>
            </CenterLogActionsComponent>
            <CenterLogTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "acc":
        return (
          <Stack>
            <AccidentActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <AccidentTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "item":
        return (
          <Stack>
            <CenterItemActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <CenterItemTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "customer":
        return (
          <Stack>
            <CenterCustomerActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <CenterCustomerTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "announce":
        return (
          <Stack>
            <AnnounceActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <AnnounceTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "model":
        return (
          <Stack>
            <BikeModelActionComponent
              selected={selected}
              contentRefresh={refreshContent}
            >
              <h2>차량 모델 현황</h2>
            </BikeModelActionComponent>
            <BikeModelTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "igroup":
        return (
          <Stack>
            <ItemGroupActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            >
              <h2>정비품목 분류 현황</h2>
            </ItemGroupActionsComponent>
            <ItemGroupTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      case "keyword":
        return (
          <Stack>
            <KeywordActionsComponent
              selected={selected}
              contentRefresh={refreshContent}
            />
            <KeywordTableComponent
              setSelected={setSelected}
              contentRefresh={contentRefresh}
            />
          </Stack>
        );
      default:
        break;
    }
    return <div />;
  }

  return (
    <Container fluid>
      <Row>
        <Col md={2} sm={6} xs={6} className="m-auto mt-2">
          <CenterSidebarComponent selected={table_type} />
          <hr />
        </Col>
        <Col md={10}>{checkTableData()}</Col>
      </Row>
    </Container>
  );
}

export default CenterTable;
