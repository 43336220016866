import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchPut } from "utils/functions";
import AnnouncePageComponent from "./announce-page-comp";

function AnnounceActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);

  useEffect(() => {
    setItemSelected(selected.has("_id"));
  }, [selected]);

  function handleDeleteButtonClick(e) {
    e.preventDefault();

    if (!window.confirm("선택한 공지를 삭제하시겠습니까?")) return;

    fetchDelete(`/announce/${selected.get("_id")}`).then(async (res) => {
      if (!res.ok) throw new Error(await res.text());
      alert("삭제가 완료되었습니다");
      navigate(0);
    });
  }

  return (
    <>
      <h2>공지사항 현황</h2>
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button variant="primary" onClick={() => setAdd(true)}>
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setFix(true)}
            >
              열람 및 수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {/** 추가/수정용 modal */}
      <Modal
        backdrop="static"
        show={showAdd || showFix}
        size="lg"
        onHide={() => {
          setAdd(false);
          setFix(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>공지사항 추가/수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AnnouncePageComponent
            isAdd={showAdd}
            isEdit
            docId={showFix ? selected.get("_id") : undefined}
            onCloseButtonClick={(e) => {
              e.preventDefault();
              setAdd(false);
              setFix(false);
            }}
            onSubmit={(formValues) => {
              setAdd(false);
              setFix(false);
              if (showFix) contentRefresh(formValues);
              else navigate(0);
            }}
            defaultWriter={sessionStorage.getItem("user_name")}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AnnounceActionsComponent;
