import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ImageViewComponent from "./image-comp";

function ImageViewModalComponent({
  url,
  buttonClassName = "me-2 mb-2",
  buttonSize = "md",
  children,
}) {
  const [showModal, setModal] = useState(false);

  return (
    <>
      <Button
        size={buttonSize}
        className={buttonClassName}
        onClick={() => setModal(true)}
      >
        {children}
      </Button>

      <Modal
        backdrop="static"
        size="xl"
        show={showModal}
        onHide={() => setModal(false)}
      >
        <Modal.Header closeButton>{url}</Modal.Header>
        <Modal.Body>
          <ImageViewComponent url={url} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageViewModalComponent;
