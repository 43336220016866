import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  ModalBody,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import BikeAddFormComponent from "component/bike/bikeadd-form-comp";
import { checkPermission, fetchGet, isBikeNumber } from "utils/functions";

function FranchMain() {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [carIdToSearch, setCarIdToSearch] = useState("");
  const [showAdd, setAdd] = useState(false);
  const [addDefaultData, setAddDefaultData] = useState({});

  useEffect(() => {
    // check permission
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }

    buttonRef.current.disabled = true;
  }, []);

  function handleCarIdChange(event) {
    const isCarId = isBikeNumber(event.target.value);
    if (isCarId) {
      buttonRef.current.disabled = false;
      setCarIdToSearch(event.target.value);
    } else {
      buttonRef.current.disabled = true;
      setCarIdToSearch("");
    }
  }

  /**
   *
   * @param {event} event
   * @param {boolean} testCarId
   */
  function handleCarIdButtonClick() {
    if (carIdToSearch.length === 0) {
      return;
    }

    fetchGet(`/bike/byn/${carIdToSearch}`)
      .then(async (res) => {
        if (res.ok) {
          navigate(`/f/bike/${(await res.json())._id}`);
        } else {
          const err = await res.json();
          if (err.notFound) {
            // 차가 존재하지 않는 경우 -> 차량 추가로 연결
            if (window.confirm("차량이 존재하지 않습니다. 추가하시겠습니까?")) {
              setAddDefaultData({
                bike_name_id: carIdToSearch,
                bike_maintainer_uid: sessionStorage.getItem("_id"),
              });
              setAdd(true);
            }
          } else {
            throw new Error(JSON.stringify(err));
          }
        }
      })
      .catch((err) => alert(err));
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg="6">
            <Stack gap={3} className="mt-4 mx-auto">
              <FloatingLabel label="차량 번호를 입력해주세요 (입력형식 - 경기평택차0000)">
                <Form.Control
                  type="text"
                  onChange={handleCarIdChange}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter") {
                      handleCarIdButtonClick(e, true);
                    }
                  }}
                />
              </FloatingLabel>
              <Button ref={buttonRef} onClick={handleCarIdButtonClick}>
                확인하기 / 추가하기
              </Button>
              <hr />
              <Button onClick={() => navigate("/f/announce")}>공지사항</Button>
            </Stack>
          </Col>
        </Row>
      </Container>

      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header>차량 추가하기</Modal.Header>
        <Modal.Body>
          <BikeAddFormComponent
            defaultData={addDefaultData}
            onFormSubmit={(newBike) => {
              navigate(`/f/bike/${newBike._id}`);
            }}
            onCancelButtonClick={() => setAdd(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FranchMain;
