import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchDelete,
  fetchGet,
  fetchPut,
  getUserNameById,
} from "utils/functions";
import CustomerAddFormComponent from "./customeradd-form-comp";
import CustomerDetailTableComponent from "./customerdetail-table-comp";

/**
 * @param props
 * selected
 */
function CenterCustomerActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);
  // const [showAssign, setAssign] = useState(false);
  const [showDetail, setDetail] = useState(false);
  // const [assignFormValues, setAssignFormValues] = useState({});
  // const [assignAdditionals, setAssignAdditionals] = useState({});

  useEffect(() => {
    const isSel = selected.has("_id");
    setItemSelected(isSel);

    if (isSel) {
      (async () => {
        const assignedName = await getUserNameById(
          selected.get("customer_assigned")
        );
        /*
        setAssignAdditionals({
          assignedName,
        }); */
      })();
    }
  }, [selected]);

  function handleDeleteButtonClick(e) {
    e.preventDefault();

    if (
      window.confirm(
        `'${selected.get(
          "customer_name"
        )}' 이름의 고객을 제거합니다.\n계속 하시겠습니까?`
      )
    ) {
      fetchDelete(`/customer/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            navigate(0);
            alert(`"${selected.get("customer_name")}" 고객이 삭제되었습니다`);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  return (
    <>
      <h2>고객 현황</h2>

      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button variant="primary" onClick={() => setAdd(true)}>
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setFix(true)}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="danger"
              onClick={handleDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => {
                if (selected.get("v_assigned_bikes").length === 0) {
                  alert("연결된 차량이 없습니다");
                  return;
                }
                setDetail(true);
              }}
            >
              연결차량 확인
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>고객 추가</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerAddFormComponent onSubmit={() => navigate(0)} />
        </Modal.Body>
      </Modal>

      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>고객 수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerAddFormComponent
            putAction
            onSubmit={(formValues) => {
              contentRefresh(formValues);
              setFix(false);
            }}
            defaultData={Object.fromEntries(selected)}
          />
        </Modal.Body>
      </Modal>

      {/* unused modal
      <Modal show={showAssign} onHide={() => setAssign(false)}>
        <Modal.Header closeButton>
          <Modal.Title>고객 지점 연결</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>기존 연결지점</Form.Label>
              <Form.Control
                disabled
                value={selected.get("customer_assigned")}
              />
              <Form.Control
                disabled
                value={assignAdditionals.assignedName ?? ""}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>신규 연결지점</Form.Label>
              <Form.Control
                onChange={(e) =>
                  setAssignFormValues((prev) => {
                    return {
                      ...prev,
                      newAssignId: e.target.value,
                      newAssignName: undefined,
                    };
                  })
                }
                value={assignFormValues.newAssignId ?? ""}
                placeholder="새로 연결할 지점의 db_id를 넣어주세요"
              />
              <Form.Control
                disabled
                value={assignFormValues.newAssignName ?? ""}
              />
              <Button
                className="me-2 mt-2"
                disabled={!assignFormValues.newAssignId}
                onClick={() => {
                  fetchGet(`/auth/${assignFormValues.newAssignId}`)
                    .then(async (res) => {
                      if (!res.ok) {
                        alert("유저 데이터가 존재하지 않습니다");
                        throw new Error(await res.text());
                      }

                      return res.json();
                    })
                    .then((user) => {
                      setAssignFormValues((prev) => {
                        return {
                          ...prev,
                          newAssignName: user.user_name,
                          newAssign: user,
                        };
                      });
                    })
                    .catch((e) => console.error(e));
                }}
              >
                확인하기
              </Button>
              <Button
                className="mt-2"
                disabled={!assignFormValues.newAssignName}
                onClick={() => {
                  fetchPut(`/customer/${selected.get("_id")}`, {
                    customer_assigned: assignFormValues.newAssignId,
                  }).then((res) => {
                    if (res.ok) {
                      alert("연결에 성공했습니다");
                      setAssign(false);
                      navigate(0);
                    } else {
                      alert("연결에 실패했습니다");
                    }
                  });
                }}
              >
                적용하기
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      */}

      <Modal
        backdrop="static"
        size="lg"
        show={showDetail}
        onHide={() => setDetail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>고객 연결차량</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerDetailTableComponent
            customerId={selected.get("_id")}
            dataObject={Object.fromEntries(selected)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CenterCustomerActionsComponent;
