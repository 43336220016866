import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Stack } from "react-bootstrap";
import { fetchPost, fetchPut } from "utils/functions";

function AccidentAddFormComponent({
  defaultData = {},
  putAction = false,
  disabled = false,
  onFormSubmit = (result) => {},
}) {
  const [formValues, setFormValues] = useState({});

  const accCheckData = [
    [
      "acc_date",
      {
        kr: "사고일시",
        type: "datetime-local",
      },
    ],
    [
      "acc_insurance_company",
      {
        kr: "보험사",
      },
    ],
    [
      "acc_receipt_id",
      {
        kr: "접수번호",
      },
    ],
    ["acc_insurance_oper", { kr: "담당자명" }],
    ["acc_insurance_call", { kr: "연락처" }],
    [
      "acc_insurance_fax",
      {
        kr: "팩스번호",
      },
    ],
    [
      "acc_insurance_email",
      {
        kr: "담당자 이메일",
      },
    ],
    [
      "acc_memo",
      {
        kr: "메모",
      },
    ],
  ];

  function handleFormValueChange(e) {
    setFormValues((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  useEffect(() => {
    if (Object.keys(defaultData).length === 0) {
      return;
    }
    setFormValues(() => {
      const output = { ...defaultData };

      output.acc_date = defaultData.acc_date?.slice(0, 16);

      return output;
    });
  }, []);

  function handleSubmitButtonClick(e) {
    e.preventDefault();

    if (formValues.acc_date) {
      formValues.acc_date += "Z";
    }

    // post or put
    (() => {
      if (putAction) {
        return fetchPut(`/acc/${defaultData._id}`, formValues);
      }
      return fetchPost(`/acc`, formValues);
    })()
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.json();
          alert(text.err);
          throw new Error(JSON.stringify(text));
        }
        alert("저장되었습니다");
        if (putAction) {
          onFormSubmit(formValues);
          return formValues;
        }
        const data = await res.json();
        onFormSubmit(data);
        return data;
      })
      .catch((err) => console.error(err));
  }

  return (
    <Container>
      <Row>
        <Col xs={12} md={8} className="mx-auto">
          <Form>
            {accCheckData
              .filter(([_, { hide }]) => !hide)
              .map(
                ([key, { kr, form, type }]) =>
                  form ?? (
                    <Form.Group key={key} controlId={key}>
                      <Form.Label>{kr}</Form.Label>
                      <Form.Control
                        disabled={disabled}
                        name={key}
                        type={type || "text"}
                        onChange={handleFormValueChange}
                        value={formValues[key] || ""}
                      />
                    </Form.Group>
                  )
              )}

            {!disabled && (
              <>
                <hr />
                <Stack direction="horizontal">
                  <div className="me-auto" />
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmitButtonClick}
                  >
                    저장
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default AccidentAddFormComponent;
