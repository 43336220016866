import AccidentAddFormComponent from "component/accident/accidentadd-form-comp";
import ImageViewModalComponent from "component/image/image-view-modal-comp";
import ItemFindComponent from "component/item/item-find-comp";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  fileSizeLimit,
  fixTypeKorDict,
  getItemNameById,
} from "utils/functions";

function LogDetailColComponent({ label = "", content = "" }) {
  return (
    <>
      <Col className="log-detail-content-label" xs="3" md="2">
        <b>{label}</b>
      </Col>
      <Col className="log-detail-content me-2">{content}</Col>
    </>
  );
}

function LogEditColComponent({ label = "", children }) {
  return (
    <Col className="mb-2">
      <Stack direction="horizontal">
        <p className="me-2">
          <b>{label}</b>
        </p>
        <div>{children}</div>
      </Stack>
    </Col>
  );
}

function LogEditListTableComponent({
  modelData = {},
  baseContent = [],
  receiveData = (data) => {},
}) {
  const [itemArr, setItemArr] = useState([]);

  const [showItemSelect, setItemSelect] = useState(false);
  const [showItemsAdd, setItemsAdd] = useState(false);
  const [itemFoundIndex, setItemFoundIndex] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);

  const [costSum, setSum] = useState(0);

  function updateItemArr(index, data) {
    const items = [...itemArr];
    items[index] = { ...items[index], ...data };
    setItemArr(items);
  }

  function updateAllItems(key, value) {
    setItemArr(
      itemArr.map((ele) => {
        return { ...ele, [key]: value };
      })
    );
  }

  useEffect(() => {
    if (baseContent.length > 0) {
      setItemArr([...baseContent].sort((a, b) => a.index - b.index));
    }
  }, [baseContent]);

  useEffect(() => {
    let sum = 0;
    itemArr.forEach((ele, idx) => {
      ele.index = idx;
      const num = parseInt(ele.fix_price, 10);
      if (!Number.isNaN(num)) {
        sum += num;
      }
    });
    setSum(sum);
    receiveData(itemArr);
  }, [itemArr]);

  return (
    <>
      <hr />
      <Button
        className="me-2"
        onClick={() => {
          setItemsAdd(true);
        }}
      >
        품목 검색
      </Button>
      <Button
        disabled={selectedRows.length === 0}
        variant="danger"
        onClick={() => {
          const newArr = [...itemArr];
          selectedRows.sort((a, b) => b - a);
          selectedRows.forEach((idxVal) => {
            newArr.splice(idxVal, 1);
          });
          setItemArr(newArr);
          setSelectedRows([]);
        }}
      >
        삭제 (번호를 눌러 선택)
      </Button>
      <Table className="mt-2 log-detail-listtable" size="sm" bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>
              정비유형
              <Dropdown>
                <Dropdown.Toggle size="sm">일괄입력</Dropdown.Toggle>

                <Dropdown.Menu>
                  {[
                    "TYPE_NORMAL",
                    "TYPE_MAINTAIN",
                    "TYPE_ACCIDENT",
                    "TYPE_GUARANTEE",
                  ].map((typeEle) => (
                    <Dropdown.Item
                      key={typeEle}
                      onClick={() => updateAllItems("fix_type", typeEle)}
                    >
                      {fixTypeKorDict[typeEle]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>
              작업구분
              <Dropdown>
                <Dropdown.Toggle size="sm">일괄입력</Dropdown.Toggle>

                <Dropdown.Menu>
                  {[
                    "SUB_CHANGE",
                    "SUB_FIX",
                    "SUB_MAINTAIN",
                    "SUB_TACH",
                    "SUB_OVERHAUL",
                  ].map((typeEle) => (
                    <Dropdown.Item
                      key={typeEle}
                      onClick={() => updateAllItems("fix_subtype", typeEle)}
                    >
                      {fixTypeKorDict[typeEle]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>정비품목</th>
            <th>정비세부내역</th>
            <th>정비금액(원)</th>
            <th>정비사진</th>
          </tr>
        </thead>
        <tbody>
          {itemArr.map((ele, idx) => (
            <tr
              className={
                selectedRows.findIndex((arEle) => arEle === ele.index) > -1
                  ? "selected-row"
                  : ""
              }
              key={ele.index}
            >
              <td
                onClick={() => {
                  const idxFound = selectedRows.findIndex(
                    (arEle) => arEle === ele.index
                  );
                  if (idxFound > -1) {
                    selectedRows.splice(idxFound, 1);
                    setSelectedRows([...selectedRows]);
                  } else {
                    setSelectedRows(selectedRows.concat(ele.index));
                  }
                }}
              >
                {ele.index + 1}
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle size="sm">
                    {fixTypeKorDict[ele.fix_type] ?? "선택"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {[
                      "TYPE_NORMAL",
                      "TYPE_MAINTAIN",
                      "TYPE_ACCIDENT",
                      "TYPE_GUARANTEE",
                    ].map((typeEle) => (
                      <Dropdown.Item
                        key={typeEle}
                        onClick={() =>
                          updateItemArr(idx, { fix_type: typeEle })
                        }
                      >
                        {fixTypeKorDict[typeEle]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle size="sm">
                    {fixTypeKorDict[ele.fix_subtype] ?? "선택"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {[
                      "SUB_CHANGE",
                      "SUB_FIX",
                      "SUB_MAINTAIN",
                      "SUB_TACH",
                      "SUB_OVERHAUL",
                    ].map((typeEle) => (
                      <Dropdown.Item
                        key={typeEle}
                        onClick={() =>
                          updateItemArr(idx, { fix_subtype: typeEle })
                        }
                      >
                        {fixTypeKorDict[typeEle]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                {ele.fix_item_name ?? ""}
                <Button
                  size="sm"
                  className="ms-2"
                  onClick={() => {
                    setItemFoundIndex(idx);
                    setItemSelect(true);
                  }}
                >
                  변경
                </Button>
              </td>
              <td>
                <Form.Control
                  as="textarea"
                  rows={1}
                  size="sm"
                  style={{ resize: "none", overflow: "hidden" }}
                  onChange={(e) => {
                    updateItemArr(idx, { fix_memo: e.target.value });
                    e.target.style.height = "auto";
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  value={ele.fix_memo}
                />
              </td>
              <td style={{ width: "100px" }}>
                <Form.Control
                  className="input-number-hidearrow text-end"
                  size="sm"
                  type="number"
                  onChange={(e) =>
                    updateItemArr(idx, { fix_price: e.target.value })
                  }
                  value={ele.fix_price ?? 0}
                />
              </td>
              <td>
                {ele.fix_image_url?.id ? (
                  <Stack direction="horizontal">
                    <ImageViewModalComponent
                      buttonSize="sm"
                      buttonClassName="mx-2"
                      url={ele.fix_image_url.url}
                    >
                      확인
                    </ImageViewModalComponent>
                    <Button
                      size="sm"
                      onClick={() => {
                        document
                          .getElementById(`part_image_upload${ele.index}`)
                          ?.click();
                      }}
                    >
                      수정
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    size="sm"
                    onClick={() => {
                      document
                        .getElementById(`part_image_upload${ele.index}`)
                        ?.click();
                    }}
                  >
                    추가
                  </Button>
                )}
                <Form.Control
                  id={`part_image_upload${ele.index}`}
                  className="d-none"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files[0].size > fileSizeLimit) {
                      alert("파일이 너무 큽니다");
                      e.target.value = "";
                      return;
                    }

                    const formData = new FormData();
                    formData.append("dest", "fixlog");
                    formData.append("img", e.target.files[0]);

                    fetchPost("/file", formData, {}, true).then(async (res) => {
                      console.log(ele.index);
                      if (!res.ok) {
                        const errObj = await res.json();
                        console.error(errObj);
                        alert(errObj.err);
                        e.target.value = "";
                        return;
                      }

                      const imgResData = await res.json();
                      console.log(imgResData.fileInfo);

                      const url =
                        imgResData.fileInfo.destination +
                        imgResData.fileInfo.filename;

                      updateItemArr(idx, {
                        fix_image_url: {
                          id: imgResData.fileInfo.originalname,
                          url,
                        },
                      });
                    });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Stack direction="horizontal">
        <div className="me-auto" />
        <p>정비금액 합계: {costSum.toLocaleString()}원 (VAT포함)</p>
      </Stack>

      {
        // #region item find modal
      }
      <Modal
        backdrop="static"
        fullscreen
        show={showItemSelect}
        onHide={() => setItemSelect(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>정비품목 검색 - (분류 선택후 품목 선택)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemFindComponent
            selectedModel={modelData}
            onFound={(found) => {
              updateItemArr(itemFoundIndex, {
                fix_item_id: found._id,
                fix_item_name: found.item_name_kr,
              });
              setItemSelect(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }

      {
        // #region multiple item add modal
      }
      <Modal fullscreen show={showItemsAdd} onHide={() => setItemsAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>정비품목 검색 - (분류 선택후 품목 선택)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemFindComponent
            selectedModel={modelData}
            arraySelect
            onFound={(found) => {
              const newIdx = itemArr.length;
              const newArr = [...itemArr];
              for (let i = 0; i < found.length; i += 1) {
                const current = found[i];
                const toAdd = {
                  index: newIdx + i,
                  fix_item_id: current._id,
                  fix_item_name: current.item_name_kr,
                };
                newArr.push(toAdd);
              }
              setItemArr(newArr);
              setItemsAdd(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

/**
 * Form component to put log
 */
function LogEditFormComponent({
  logId,
  defaultData,
  onSubmit = (formValues) => {},
  children,
}) {
  const navigate = useNavigate();
  const [bikeInfo, setBikeInfo] = useState({});
  const [modelInfo, setModelInfo] = useState({});
  const [accidentInfo, setAccInfo] = useState({});
  const [formValues, setFormValues] = useState({});
  const [itemArr, setItemArr] = useState([]);
  const [guaranteeImages, setGuaranteeImages] = useState({
    number: {},
    dashboard: {},
    front: {},
    back: {},
    left: {},
    right: {},
  });

  const [showAccAssign, setAccAssign] = useState(false);

  function changeFormValue(name, value) {
    setFormValues((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function handleFormValueChange(e) {
    changeFormValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    if (!logId) {
      // 정비이력을 추가하는 경우 기본정보
      if (!defaultData.bike_id) {
        alert(
          "정비이력 생성 중 문제가 발생했습니다. 새로고침 후 다시 이용해주세요"
        );
        navigate(-1);
        return;
      }
      fetchGet(`/bike/${defaultData.bike_id}`)
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.json();
        })
        .then((data) => {
          const date = new Date();
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
          setFormValues({
            model_info: data.model_info,
            fix_date: date.toISOString(),
            fix_log_details: [],
            fix_drivedist: 0,
          });
          setBikeInfo(data);
          setModelInfo(data.model_info);
        });
    } else {
      // 정비 이력을 수정하는 경우
      fetchGet(`/log/${logId}`)
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.json();
        })
        .then(async (data) => {
          if (data.fix_log_details.length) {
            for (let i = 0; i < data.fix_log_details.length; i += 1) {
              data.fix_log_details[i].fix_item_name = await getItemNameById(
                data.fix_log_details[i].fix_item_id
              );
            }
          }
          setFormValues(data);
          setBikeInfo(data.bike_info);
          setModelInfo(data.model_info);
          if (data.fix_guarantee_images) {
            setGuaranteeImages(data.fix_guarantee_images);
          }
          if (data.accident_id) {
            const accRes = await fetchGet(`/acc/${data.accident_id}`);
            setAccInfo(await accRes.json());
          }
        })
        .catch((err) => console.error(err));
    }
  }, []);

  function verifyFormValues() {
    if (!formValues.fix_date) {
      alert("시간을 지정해주세요");
      return false;
    }
    for (let i = 0; i < itemArr.length; i += 1) {
      const detail = itemArr[i];
      if (!detail.fix_type) {
        alert("모든 정비내역의 정비유형을 입력해주세요");
        return false;
      }
      if (!detail.fix_subtype) {
        alert("모든 정비내역의 작업 구분을 입력해주세요");
        return false;
      }
      if (!detail.fix_item_id) {
        alert("모든 정비내역의 정비 품목을 입력해주세요");
        return false;
      }
      if (detail.fix_type === "TYPE_GUARANTEE" && !detail.fix_image_url?.id) {
        alert("보증AS의 경우 정비 사진을 반드시 첨부해야 합니다");
        return false;
      }
    }
    if (!itemArr.length) {
      alert(`정비 품목이 반드시 하나 이상 등록되어야 합니다. 
정비이력 삭제가 필요하신 경우 본사 (가맹본부 상담톡)으로 요청해 주세요`);
      return false;
    }
    if (itemArr.findIndex((ele) => ele.fix_type === "TYPE_GUARANTEE") > -1) {
      for (const [_, value] of Object.entries(guaranteeImages)) {
        if (!value.id || !value.url) {
          alert("보증AS의 경우 모든 공통 사진을 첨부해야 합니다");
          return false;
        }
      }
    }

    return true;
  }

  function getDisplayText() {
    let fix_type_display = "";
    let fix_type_postfix = false;
    let fix_subtype_display = "";
    let fix_subtype_postfix = false;
    let fix_item_display = "";
    const postfix = " 외";

    if (itemArr.length) {
      fix_type_display = itemArr[0].fix_type;
      fix_subtype_display = itemArr[0].fix_subtype;
      fix_item_display = itemArr[0].fix_item_name;
      for (let i = 1; i < itemArr.length; i += 1) {
        const detail = itemArr[i];
        fix_item_display += `, ${detail.fix_item_name}`;
        if (!fix_type_postfix && fix_type_display !== detail.fix_type) {
          fix_type_postfix = true;
        }
        if (
          !fix_subtype_postfix &&
          fix_subtype_display !== detail.fix_subtype
        ) {
          fix_subtype_postfix = true;
        }
        if (fix_type_postfix && fix_subtype_postfix) break;
      }
    }

    fix_type_display = fixTypeKorDict[fix_type_display];
    if (fix_type_postfix) fix_type_display += postfix;

    fix_subtype_display = fixTypeKorDict[fix_subtype_display];
    if (fix_subtype_postfix) fix_subtype_display += postfix;

    return { fix_type_display, fix_subtype_display, fix_item_display };
  }

  function handleEditButtonClick(e) {
    e.preventDefault();

    if (!verifyFormValues()) {
      return;
    }

    const convertedTime = new Date(formValues.fix_date.slice(0, 16));
    convertedTime.setMinutes(
      convertedTime.getMinutes() - convertedTime.getTimezoneOffset()
    );

    const { fix_type_display, fix_subtype_display, fix_item_display } =
      getDisplayText();
    const require_guarantee =
      itemArr.findIndex((ele) => ele.fix_type === "TYPE_GUARANTEE") > -1;

    const toSend = {
      fix_date: convertedTime.toISOString(),
      fix_drivedist: formValues.fix_drivedist,
      fix_outside: !!formValues.fix_outside,
      accident_id: formValues.accident_id,
      fix_log_details: itemArr.map((ele) => {
        return {
          ...ele,
          accident_id: formValues.accident_id,
          fix_log_id: formValues._id,
        };
      }),
      fix_type_display,
      fix_subtype_display,
      fix_item_display,
      require_guarantee,
    };

    if (require_guarantee) {
      toSend.fix_guarantee_images = guaranteeImages;
    }

    fetchPut(`/log/${formValues._id}`, toSend)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("수정이 완료되었습니다");
        onSubmit({ ...toSend, _id: logId });
      })
      .catch((err) => {
        alert("정비 이력 수정에 실패했습니다");
        console.error(err);
      });
  }

  function handleAddButtonClick(e) {
    e.preventDefault();

    if (!verifyFormValues()) {
      return;
    }

    const convertedTime = new Date(formValues.fix_date.slice(0, 16));
    convertedTime.setMinutes(
      convertedTime.getMinutes() - convertedTime.getTimezoneOffset()
    );

    const { fix_type_display, fix_subtype_display, fix_item_display } =
      getDisplayText();
    const require_guarantee =
      itemArr.findIndex((ele) => ele.fix_type === "TYPE_GUARANTEE") > -1;

    const toSend = {
      ...defaultData,
      fix_date: convertedTime.toISOString(),
      fix_drivedist: formValues.fix_drivedist,
      fix_outside: !!formValues.fix_outside,
      accident_id: formValues.accident_id,
      fix_log_details: itemArr.map((ele) => {
        return {
          ...ele,
          accident_id: formValues.accident_id,
          fix_log_id: formValues._id,
        };
      }),
      fix_type_display,
      fix_subtype_display,
      fix_item_display,
      require_guarantee,
    };

    if (require_guarantee) {
      toSend.fix_guarantee_images = guaranteeImages;
    }

    fetchPost(`/log`, toSend)
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        alert("전송이 완료되었습니다");
        onSubmit({ ...toSend, _id: logId });
      })
      .catch((err) => {
        alert("정비 이력 생성에 실패했습니다");
        console.error(err);
      });
  }

  return (
    <>
      <div className="log-detail-header">
        <b>차량정보</b>
      </div>
      <Container className="mb-2">
        <Row className="mb-2">
          <LogDetailColComponent
            label="차량번호"
            content={bikeInfo.bike_name_id}
          />
          <LogDetailColComponent
            label="차대번호"
            content={bikeInfo.bike_uniq_id}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent
            label="제조사"
            content={modelInfo.bike_maker}
          />
          <LogDetailColComponent
            label="차량모델"
            content={modelInfo.bike_model}
          />
        </Row>
        <Row className="mb-2">
          <LogDetailColComponent label="연식" content={modelInfo.bike_aged} />
          <LogDetailColComponent
            label="배기량 (cc)"
            content={modelInfo.bike_cc}
          />
        </Row>
      </Container>
      <div className="log-detail-header">
        <b>정비정보</b>
      </div>
      <Container>
        <Row>
          <LogEditColComponent label="정비일시">
            <Form.Control
              name="fix_date"
              type="datetime-local"
              onChange={handleFormValueChange}
              value={formValues.fix_date?.slice(0, 16) ?? ""}
            />
          </LogEditColComponent>
          <LogEditColComponent label="출장여부">
            <Form.Check
              name="fix_outside"
              className="m-2"
              type="switch"
              onChange={(e) => changeFormValue(e.target.name, e.target.checked)}
              label={formValues.fix_outside ? "출장" : "내점"}
              checked={!!formValues.fix_outside}
            />
          </LogEditColComponent>
        </Row>
        <Row>
          <LogEditColComponent label="주행거리">
            <InputGroup>
              <Form.Control
                name="fix_drivedist"
                type="number"
                onChange={handleFormValueChange}
                value={formValues.fix_drivedist ?? 0}
              />
              <InputGroup.Text>km</InputGroup.Text>
            </InputGroup>
          </LogEditColComponent>
          <LogEditColComponent label="사고 정보">
            <Button
              className="m-2"
              size="sm"
              onClick={() => setAccAssign(true)}
            >
              보험 정보 {formValues.accident_id ? "수정" : "추가"}
            </Button>
          </LogEditColComponent>
        </Row>
      </Container>
      <LogEditListTableComponent
        modelData={formValues.model_info}
        baseContent={formValues.fix_log_details}
        receiveData={setItemArr}
      />
      {
        // #region additional form for TYPE_GUARANTEE
        /*
        보증AS의 경우 공통 사진을 올려야함
        차량번호사진, 계기판사진, 차량사진(전,후,좌,우)
        */
        itemArr.findIndex((ele) => ele.fix_type === "TYPE_GUARANTEE") > -1 && (
          <>
            <hr />
            <div className="log-detail-header">
              <b>보증AS 공통</b>
            </div>
            <Table className="log-detail-listtable" bordered>
              <thead>
                <tr>
                  <th>차량번호</th>
                  <th>계기판</th>
                  <th>차량 전면</th>
                  <th>차량 후면</th>
                  <th>차량 좌측면</th>
                  <th>차량 우측면</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {[
                    "number",
                    "dashboard",
                    "front",
                    "back",
                    "left",
                    "right",
                  ].map((nameKey) => {
                    return (
                      <td key={nameKey}>
                        {guaranteeImages[nameKey]?.id ? (
                          <Stack direction="horizontal">
                            <ImageViewModalComponent
                              buttonSize="sm"
                              buttonClassName="mx-2"
                              url={guaranteeImages[nameKey].url}
                            >
                              확인
                            </ImageViewModalComponent>
                            <Button
                              size="sm"
                              onClick={() => {
                                document
                                  .getElementById(`part_image_upload${nameKey}`)
                                  ?.click();
                              }}
                            >
                              수정
                            </Button>
                          </Stack>
                        ) : (
                          <Button
                            size="sm"
                            onClick={() => {
                              document
                                .getElementById(`part_image_upload${nameKey}`)
                                ?.click();
                            }}
                          >
                            추가
                          </Button>
                        )}
                        <Form.Control
                          id={`part_image_upload${nameKey}`}
                          className="d-none"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files[0].size > fileSizeLimit) {
                              alert("파일이 너무 큽니다");
                              e.target.value = "";
                              return;
                            }

                            const formData = new FormData();
                            formData.append("dest", "guarantee");
                            formData.append("img", e.target.files[0]);

                            fetchPost("/file", formData, {}, true).then(
                              async (res) => {
                                if (!res.ok) {
                                  const errObj = await res.json();
                                  console.error(errObj);
                                  alert(errObj.err);
                                  e.target.value = "";
                                  return;
                                }

                                const imgResData = await res.json();
                                console.log(imgResData.fileInfo);

                                const url =
                                  imgResData.fileInfo.destination +
                                  imgResData.fileInfo.filename;

                                setGuaranteeImages((prev) => {
                                  return {
                                    ...prev,
                                    [nameKey]: {
                                      id: nameKey,
                                      url,
                                    },
                                  };
                                });
                              }
                            );
                          }}
                        />
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </>
        )
        // #endregion
      }
      <hr />
      <Stack gap={2} direction="horizontal">
        <div className="ms-auto" />
        {logId ? (
          <Button onClick={handleEditButtonClick}>수정</Button>
        ) : (
          <Button onClick={handleAddButtonClick}>저장</Button>
        )}
        {children}
      </Stack>

      {
        // #region link accident id modal
      }
      <Modal
        backdrop="static"
        show={showAccAssign}
        onHide={() => setAccAssign(false)}
      >
        <Modal.Header closeButton>보험 정보 추가/수정</Modal.Header>
        <Modal.Body>
          <AccidentAddFormComponent
            putAction={!!formValues.accident_id}
            defaultData={{
              ...accidentInfo,
              created_user_id: sessionStorage.getItem("_id"),
              acc_bike_id: bikeInfo._id,
            }}
            onFormSubmit={(result) => {
              changeFormValue("accident_id", result._id);
              setAccInfo(result);
              setAccAssign(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
        /**
         * <Stack className="mb-3" gap={2}>
            <Row>
              <Col>
                <Button
                  className="w-100"
                  variant={
                    accAssignMode === "NEW" ? "primary" : "outline-primary"
                  }
                  onClick={() => setAccAssignMode("NEW")}
                >
                  사고 정보를 알고있음
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100"
                  variant={
                    accAssignMode === "NEWEMPTY" ? "primary" : "outline-primary"
                  }
                  onClick={() => setAccAssignMode("NEWEMPTY")}
                >
                  사고 관련 정보를 모름
                </Button>
              </Col>
            </Row>
            <p className="mx-auto mb-1">또는</p>
            <Button
              variant={
                accAssignMode === "KNOWN" ? "primary" : "outline-primary"
              }
              onClick={() => setAccAssignMode("KNOWN")}
            >
              사고 코드를 알고있음
            </Button>
          </Stack>
          <hr />

          {accAssignJsx[accAssignMode]}
         */
      }
    </>
  );
}

export default LogEditFormComponent;
