import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import {
  getCustomerNameById,
  getCustomerPhoneById,
  getUserNameById,
} from "utils/functions";

/**
 *
 * @param {object} bikeObject
 */
function BikeDetailTableComponent({ bikeObject }) {
  const [tableVisible, setTableVisible] = useState({});

  useEffect(() => {
    (async () => {
      const newObj = { ...bikeObject };

      newObj.bike_maintainer_name =
        bikeObject.bike_maintainer_name ??
        (await getUserNameById(bikeObject.bike_maintainer_uid, "-미연결-"));
      newObj.customer_name =
        bikeObject.customer_name ??
        (await getCustomerNameById(bikeObject.customer_cid, "-미연결-"));
      newObj.contractor_name =
        bikeObject.contractor_name ??
        (await getCustomerNameById(bikeObject.contractor_cid, "-"));
      newObj.customer_phone =
        bikeObject.customer_phone ??
        (await getCustomerPhoneById(bikeObject.customer_cid, "-"));
      newObj.contractor_phone =
        bikeObject.contractor_phone ??
        (await getCustomerPhoneById(bikeObject.contractor_cid, "-"));

      setTableVisible(newObj);
    })();
  }, []);

  return (
    <Table bordered>
      <tbody>
        <tr>
          <th>차량번호</th>
          <td>{tableVisible.bike_name_id || ""}</td>
          <th>차대번호</th>
          <td>{tableVisible.bike_uniq_id || ""}</td>
        </tr>
        <tr>
          <th>제조사</th>
          <td>{tableVisible.model_info?.bike_maker || ""}</td>
          <th>모델</th>
          <td>{tableVisible.model_info?.bike_model || ""}</td>
        </tr>
        <tr>
          <th>배기량</th>
          <td>{`${tableVisible.model_info?.bike_cc || 0}cc`}</td>
          <th>연식</th>
          <td>{tableVisible.model_info?.bike_aged || ""}</td>
        </tr>
        <tr>
          <th>연결 가맹점</th>
          <td>{tableVisible.bike_maintainer_name || ""}</td>
          <th>차량 분류</th>
          <td>
            {{
              NORMAL: "일반",
              LEASE: "리스",
            }[tableVisible.lease_type] || ""}
          </td>
        </tr>
        <tr>
          <th>고객2 (사용자)</th>
          <td>{tableVisible.customer_name || ""}</td>
          <th>고객1 (소유주)</th>
          <td>{tableVisible.contractor_name || ""}</td>
        </tr>
        <tr>
          <th>고객2 연락처</th>
          <td>{tableVisible.customer_phone || ""}</td>
          <th>고객1 연락처</th>
          <td>{tableVisible.contractor_phone || ""}</td>
        </tr>
        {tableVisible.lease_type === "LEASE" && (
          <>
            <tr>
              <th>리스사</th>
              <td>{tableVisible.lease_name}</td>
              <th>조건</th>
              <td>{tableVisible.lease_status ? "인수" : "반납"}</td>
            </tr>
            <tr>
              <th>계약 시작일</th>
              <td>{tableVisible.lease_start_date?.slice(0, 10)}</td>
              <th>계약 종료일</th>
              <td>{tableVisible.lease_end_date?.slice(0, 10)}</td>
            </tr>
            <tr>
              <th>정비비 포함 여부</th>
              <td>
                {tableVisible.lease_bike_fee_included ? "포함" : "미포함"}
              </td>
            </tr>
          </>
        )}
        <tr>
          <th>비고</th>
          <td colSpan={3}>{tableVisible.additional_memo}</td>
        </tr>
        <tr>
          <th>DB_ID</th>
          <td colSpan={3}>{tableVisible._id || ""}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default BikeDetailTableComponent;
