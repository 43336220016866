import React, { useEffect, useState } from "react";
import { Form, Button, Stack, Modal, Table, Container } from "react-bootstrap";
import CustomerAddFormComponent from "component/customer/customeradd-form-comp";
import { fetchGet, getUserNameById } from "utils/functions";
import { useNavigate } from "react-router-dom";

function FranchCustomer() {
  const navigate = useNavigate();

  const [showDetail, setShowDetail] = useState(false);
  const [isSearchName, setSearchMode] = useState(false);
  const [isSearched, setSearched] = useState(false);
  const [searchNumber, setSearchNumber] = useState("");
  const [showAddModal, setAddModal] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [needConvert, setNeedConvert] = useState(false);

  function handleMyCustomerButtonClick(event) {
    event?.preventDefault();
    setShowDetail(false);
    setSearched(false);

    Promise.all([
      fetchGet(`/customer?customer_last_used=${sessionStorage.getItem("_id")}`),
      fetchGet(`/customer?customer_created=${sessionStorage.getItem("_id")}`),
    ])
      .then(async (res) => {
        if (!res[0].ok && res[0].status !== 404) {
          throw new Error(await res[0].text());
        }
        if (!res[1].ok && res[1].status !== 404) {
          throw new Error(await res[1].text());
        }

        let res0 = await res[0].json();
        if (!res0.length) {
          res0 = [];
        }
        let res1 = await res[1].json();
        if (!res1.length) {
          res1 = [];
        }
        res1.forEach((element) => {
          if (
            res0.findIndex((innerEle) => innerEle._id === element._id) === -1
          ) {
            res0.push(element);
          }
        });

        return res0;
      })
      .then((data) => {
        setCustomerData(data);
        setSearched(true);
        setNeedConvert(true);
      })
      .catch((err) => console.error(err));
  }

  function handleSearchButtonClick(e) {
    setSearched(false);

    if (isSearchName) {
      fetchGet(`/customer?customer_name=${searchNumber.trim()}`)
        .then(async (res) => {
          if (!res.ok) {
            throw new Error(await res.text());
          }

          return res.json();
        })
        .then((data) => {
          setShowDetail(true);
          setCustomerData(data);
          setSearched(true);
          setNeedConvert(true);
        })
        .catch((err) => console.error(err));
    } else {
      if (searchNumber.length < 1) return;

      let findNumber = searchNumber;
      findNumber = findNumber.replace(/-/g, "");
      findNumber = findNumber.replace(/\)/g, "").trim();

      if (findNumber.match(/[^0-9]/)) {
        alert("잘못된 입력입니다. 다시 작성해주세요.");
        return;
      }

      fetchGet(`/customer?customer_phone=${findNumber}`)
        .then(async (res) => {
          if (!res.ok) {
            throw new Error(await res.text());
          }

          return res.json();
        })
        .then((data) => {
          setShowDetail(true);
          setCustomerData(data);
          setSearched(true);
          setNeedConvert(true);
        })
        .catch((err) => console.error(err));
    }
  }

  useEffect(() => {
    handleMyCustomerButtonClick();
  }, []);

  useEffect(() => {
    if (!needConvert) return;
    (async () => {
      const newData = customerData;
      for (let i = 0; i < newData.length; i += 1) {
        newData[i].customer_last_used = await getUserNameById(
          customerData[i].customer_last_used,
          ""
        );
        newData[i].customer_created = await getUserNameById(
          customerData[i].customer_created,
          ""
        );
      }
      setCustomerData(newData);
      setNeedConvert(false);
    })();
  }, [needConvert]);

  return (
    <Container>
      <Stack className="mt-4 mb-4" direction="horizontal" gap={3}>
        <Button
          type="submit"
          onClick={() => setSearchMode(!isSearchName)}
          className="text-nowrap"
        >
          {isSearchName ? "이름" : "전화번호"}
        </Button>
        <Form.Control
          type="text"
          placeholder={
            isSearchName
              ? "고객의 이름을 입력해주세요"
              : "고객의 전화번호를 입력해주세요"
          }
          onChange={(e) => setSearchNumber(e.target.value)}
        />
        <Button
          type="submit"
          onClick={handleSearchButtonClick}
          className="text-nowrap"
        >
          확인
        </Button>
        <Button onClick={() => setAddModal(true)} className="text-nowrap">
          추가
        </Button>
        <Button onClick={handleMyCustomerButtonClick} className="text-nowrap">
          내 점포
        </Button>
      </Stack>

      {showDetail ? (
        customerData.map((e) => (
          <div key="onlyOne">
            <h2>기본 정보</h2>
            <Table
              key={e._id}
              className={isSearched ? "" : "invisible"}
              bordered
            >
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>{e.customer_name || ""}</td>
                </tr>
                <tr>
                  <th>전화번호</th>
                  <td>{e.customer_phone?.toMobileString() || ""}</td>
                </tr>
                <tr>
                  <th>고객 타입</th>
                  <td>
                    {{
                      NORMAL: "일반",
                      DELIVERY: "배달종사자",
                    }[e.customer_type] || ""}
                  </td>
                </tr>
                <tr>
                  <th>소속</th>
                  <td>{e.customer_including || ""}</td>
                </tr>
                <tr>
                  <th>관리 가맹점</th>
                  <td>{e.customer_created || ""}</td>
                </tr>
                <tr>
                  <th>최근 이용 가맹점</th>
                  <td>{e.customer_last_used || ""}</td>
                </tr>
              </tbody>
            </Table>

            <h2>할당 차량</h2>
            <Table bordered>
              <thead>
                <tr>
                  <th>차대번호</th>
                  <th>차량번호</th>
                </tr>
              </thead>
              <tbody>
                {e.v_assigned_bikes.map((bikeData) => (
                  <tr key={bikeData._id}>
                    <td>{bikeData.bike_uniq_id}</td>
                    <td>{bikeData.bike_name_id}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))
      ) : (
        <Table bordered hover>
          <thead>
            <tr>
              <th>이름</th>
              <th>전화번호</th>
              <th>고객 타입</th>
              <th>소속</th>
              <th>최근 이용 가맹점</th>
              <th>관리 가맹점</th>
            </tr>
          </thead>
          <tbody>
            {customerData.map((e) => (
              <tr
                key={e._id}
                onClick={() => {
                  setCustomerData([e]);
                  setShowDetail(true);
                }}
              >
                <td>{e.customer_name || ""}</td>
                <td>{e.customer_phone?.toMobileString() || ""}</td>
                <td>
                  {{
                    NORMAL: "일반",
                    DELIVERY: "배달 종사자",
                  }[e.customer_type] || ""}
                </td>
                <td>{e.customer_including || ""}</td>
                <td>{e.customer_last_used || ""}</td>
                <td>{e.customer_created || ""}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        backdrop="static"
        show={showAddModal}
        onHide={() => setAddModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>고객 추가하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerAddFormComponent
            onSubmit={() => {
              setAddModal(false);
              navigate(0);
            }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default FranchCustomer;
