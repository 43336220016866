import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import {
  Container,
  Navbar,
  Button,
  Nav,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import FranchAdditionals from "./pages/franch-additionals";
import FranchMain from "./pages/franch-main";
import Login from "./pages/login";
import FranchBike from "./pages/franch-bike";
import FranchLogAdd from "./pages/franch-addlog";
import CenterMain from "./pages/center-main";
import CenterTable from "./pages/center-table";
import FranchAssignedBike from "./pages/franch-assignedbike";
import FranchAssignedLog from "./pages/franch-assignedlog";
import TestPage from "./pages/testpage";
import FranchCustomer from "./pages/franch-customer";
import FranchAnnounce from "./pages/franch-announce";
import CenterSettings from "./pages/center-settings";
import { fetchGet, homeLink } from "./utils/functions";

function App() {
  const navigate = useNavigate();
  const [titleName, setTitleName] = useState("");
  function handleLogoutButtonClick(e) {
    e.preventDefault();

    if (!sessionStorage.getItem("user_type")) {
      sessionStorage.clear();
      return;
    }

    if (window.confirm("로그아웃 하시겠습니까?")) {
      sessionStorage.clear();
      fetchGet("/logout");
      document.location.href = homeLink;
    }
  }

  function handleHomeButtonClick(e) {
    e?.preventDefault();
    const myType = sessionStorage.getItem("user_type");
    if (myType) {
      navigate(`/${myType[0].toLowerCase()}/main`);
      // document.location.href = `${homeLink}/${myType[0].toLowerCase()}/main`;
    } else {
      navigate("/");
      // document.location.href = homeLink;
    }
  }

  useEffect(() => {
    const userName = sessionStorage.getItem("user_name");
    setTitleName(userName);
    if (userName) {
      document.title = `진바이크 시스템 - ${userName}`;
    } else {
      document.title = `진바이크 시스템`;
    }
  }, []);

  return (
    <div className="App">
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#" onClick={handleHomeButtonClick}>
            Jinbike System
          </Navbar.Brand>
          <Navbar.Text className="me-auto">{titleName}</Navbar.Text>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="me-auto" />
            <Button
              className="d-flex me-2 mt-2"
              variant="outline-light"
              onClick={handleHomeButtonClick}
            >
              홈
            </Button>
            <Routes>
              <Route
                path="/f/*"
                element={
                  <Button
                    className="d-flex me-2 mt-2"
                    variant="outline-light"
                    onClick={() => navigate("/f/additional")}
                  >
                    추가기능
                  </Button>
                }
              />
              <Route path="*" element={<div />} />
            </Routes>
            <Button
              className="d-flex mt-2"
              variant="outline-danger"
              onClick={handleLogoutButtonClick}
            >
              로그아웃
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-2" fluid>
        <Routes>
          <Route path="/" element={<Login setTitleName={setTitleName} />} />
          <Route path="/test" element={<TestPage />} />
          {sessionStorage.getItem("user_type") && (
            <>
              {/** Center 본사 */}
              <Route path="/c/main" element={<CenterMain />} />
              <Route path="/c/table/:table_type" element={<CenterTable />} />
              <Route path="/c/announce/:doc_id" element={<CenterTable />} />
              <Route path="/c/settings" element={<CenterSettings />} />
              {/** Franch 프랜차이즈 */}
              <Route path="/f/main" element={<FranchMain />} />
              <Route path="/f/additional" element={<FranchAdditionals />} />
              <Route path="/f/mybike" element={<FranchAssignedBike />} />
              <Route path="/f/mylog" element={<FranchAssignedLog />} />
              <Route path="/f/bike/:bike_id" element={<FranchBike />} />
              <Route path="/f/log/add/:bike_id" element={<FranchLogAdd />} />
              <Route path="/f/customer" element={<FranchCustomer />} />
              <Route path="/f/announce" element={<FranchAnnounce />} />
            </>
          )}

          {/** 공통 */}
          <Route path="*" element={<Login setTitleName={setTitleName} />} />
        </Routes>
      </Container>
      <div
        className="position-relative border-top w-100 py-2"
        style={{
          backgroundColor: "#eeeeee",
          marginTop: "20vh",
        }}
      >
        <footer className="my-2 text-start">
          <Container>
            <Row>
              <Col className="text-md-end row-fluid" md={5}>
                <img
                  src={`${process.env.PUBLIC_URL}/logo.gif`}
                  alt="logo"
                  height={90}
                />
              </Col>
              <Col>
                <Stack>
                  <p className="m-0">
                    <b>㈜진바이크</b>
                  </p>
                  <p className="m-0">대표번호 : 070-8648-1720</p>
                  <p className="m-0">
                    경기도 화성시 영천동 652-5 더퍼스트타워1차 1912호
                  </p>
                  <p className="m-0">
                    Copyright © 2023 JINBIKE all rights reserved.
                  </p>
                </Stack>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default App;
