import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AccidentAddFormComponent from "./accidentadd-form-comp";

function AccidentActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showFix, setFix] = useState(false);

  useEffect(() => {
    setItemSelected(selected.has("_id"));
  }, [selected]);

  return (
    <>
      <h2>사고정보 현황</h2>
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={() => setFix(true)}
            >
              수정
            </Button>
          </Col>
        </Row>
      </Container>

      {
        // #region fix bike info modal
      }
      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>사고정보 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AccidentAddFormComponent
            putAction
            onFormSubmit={(formValues) => {
              setFix(false);
              contentRefresh(formValues);
            }}
            defaultData={Object.fromEntries(selected)}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

export default AccidentActionsComponent;
