import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { fetchGet } from "utils/functions";

function ImageViewComponent({ url, disableContext = false }) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (url === undefined || url === null) {
      setImageUrl("");
      return;
    }
    if (typeof url === "string" && url.length === 0) {
      setImageUrl("");
      return;
    }

    fetchGet(`/file/${url}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }
      })
      .then((imgBlob) => {
        const imageObjectURL = URL.createObjectURL(imgBlob);
        setImageUrl(imageObjectURL);
      });

    return () => {
      // use for clean up
      window.URL.revokeObjectURL(imageUrl);
    };
  }, [url]);

  return imageUrl.length === 0 ? (
    <p>이미지 없음</p>
  ) : (
    <Image
      onContextMenu={(e) => (disableContext ? e.preventDefault() : "")}
      className={disableContext ? "disabled-image" : ""}
      src={imageUrl}
      fluid
      rounded
    />
  );
}

export default ImageViewComponent;
