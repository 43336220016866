import React from "react";
import {
  fetchGet,
  getCustomerNameById,
  getUserNameById,
} from "utils/functions";
import CenterTableComponent from "../center-table-comp";

/**
 *
 * @param {*} props
 * setSelected
 * @returns
 */
function CenterBikeTableComponent({ setSelected, contentRefresh }) {
  const defaultBikeTitles = new Map([
    ["createdAt", { title: "관리등록일", visible: true, sorted: 1 }],
    ["bike_uniq_id", { title: "차대번호", visible: true }],
    ["bike_name_id", { title: "차량번호", visible: true }],
    ["bike_maintainer_uid", { title: "관리가맹점ID", visible: false }],
    [
      "bike_maintainer_name",
      { title: "관리가맹점", visible: true, generated: true },
    ],
    ["bike_maker", { title: "제조사", visible: false }],
    ["bike_model", { title: "차종", visible: true }],
    ["bike_cc", { title: "배기량", visible: false }],
    ["bike_aged", { title: "연식", visible: false }],
    ["contractor_cid", { title: "고객1 ID", visible: false }],
    [
      "contractor_name",
      { title: ["고객1", "(소유주)"], visible: true, generated: true },
    ],
    ["customer_cid", { title: "고객2 ID", visible: false }],
    [
      "customer_name",
      { title: ["고객2", "(사용자)"], visible: true, generated: true },
    ],
    ["lease_type", { title: "차량구분", visible: true }], // 일반NORMAL, 리스LEASE
    ["lease_uid", { title: "리스ID", visible: false }],
    ["lease_name", { title: "리스사명", visible: false, generated: true }],
    ["lease_start_date", { title: "계약시작일", visible: false }],
    ["lease_end_date", { title: "계약종료일", visible: false }],
    ["lease_bike_fee_included", { title: "정비비 포함여부", visible: false }], // 포함true, 미포함false
    ["lease_status", { title: "인수여부", visible: false }], // 인수true, 반납false
    ["document_active", { title: "활성화 상태", visible: true }],
    ["additional_memo", { title: "비고", visible: false }],
    ["_id", { title: "db_id", visible: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "centerBikeTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultBikeTitles}
      getDataPromise={async () => {
        const res = await fetchGet(`/bike`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];

          for (const row of data) {
            // reorder
            const newMapped = new Map();
            for await (const key of titleMap.keys()) {
              // process
              if (
                key === "bike_maker" ||
                key === "bike_model" ||
                key === "bike_cc"
              ) {
                newMapped.set(key, row.model_info ? row.model_info[key] : "");
              } else if (key === "bike_aged") {
                newMapped.set(
                  key,
                  row.model_info
                    ? `${row.model_info[key].toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}년`
                    : ""
                );
              } else if (key === "bike_maintainer_name") {
                newMapped.set(
                  key,
                  await getUserNameById(row.bike_maintainer_uid, "-미연결-")
                );
              } else if (key === "customer_name") {
                newMapped.set(
                  key,
                  await getCustomerNameById(row.customer_cid, "-미연결-")
                );
              } else if (key === "contractor_name") {
                newMapped.set(
                  key,
                  await getCustomerNameById(row.contractor_cid, "-")
                );
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else if (key === "createdAt") {
                newMapped.set(key, row[key]?.slice(0, 10));
              } else if (key === "lease_type") {
                if (row[key] === "NORMAL") newMapped.set(key, "일반");
                else if (row[key] === "LEASE") newMapped.set(key, "리스");
                else newMapped.set(key, `!undefined_data!\n${row[key]}`);
              } else if (key === "lease_status") {
                newMapped.set(key, row[key] ? "인수" : "반납");
              } else if (key === "lease_bike_fee_included") {
                newMapped.set(key, row[key] ? "포함" : "미포함");
              } else if (
                key === "lease_start_date" ||
                key === "lease_end_date"
              ) {
                newMapped.set(key, row[key].split("T")[0]);
              } else if (key === "document_active") {
                newMapped.set(key, row[key] ? "O" : "X");
              } else {
                newMapped.set(key, row[key] ?? "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default CenterBikeTableComponent;
