import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchGet, fetchPut } from "utils/functions";

function AnnounceTableComponent({ setSelected, contentRefresh }) {
  const navigate = useNavigate();
  const [docsData, setDocsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    fetchGet("/announce/list")
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        return res.json();
      })
      .then((data) => setDocsData(data));
  }, []);

  useEffect(() => {
    if (contentRefresh._id) {
      const changed = docsData.findIndex(
        (ele) => ele._id === contentRefresh._id
      );
      setSelected(new Map());
      if (changed !== -1) {
        setDocsData((prev) => {
          prev[changed] = { ...prev[changed], ...contentRefresh };
          return prev;
        });
      }
    }
  }, [contentRefresh]);

  function handleRowClick(e, element) {
    setSelected(new Map(Object.entries(element)));
    setSelectedRow(element);
  }

  function handlePutButtonClick(docId, putObject) {
    fetchPut(`/announce/${docId}`, putObject).then((res) => {
      if (res.ok) {
        alert("변경이 완료되었습니다");
      } else {
        alert("변경에 실패했습니다");
      }
      navigate(0);
    });
  }

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>최종수정일</th>
          <th>작성자</th>
          <th>제목</th>
          <th>게시</th>
          <th>상단고정</th>
        </tr>
      </thead>
      <tbody>
        {docsData
          .sort((a, b) => b.doc_fixed - a.doc_fixed)
          .map((e) => (
            <tr
              key={e._id}
              className={selectedRow._id === e._id ? "selected-row" : ""}
              onClick={(ev) => handleRowClick(ev, e)}
            >
              <td>{e.updatedAt}</td>
              <td>{e.doc_writer}</td>
              <td>{e.doc_title}</td>
              <td>
                <Form.Check
                  type="switch"
                  defaultChecked={e.doc_visible}
                  onChange={() =>
                    handlePutButtonClick(e._id, {
                      doc_visible: !e.doc_visible,
                    })
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  defaultChecked={e.doc_fixed}
                  onChange={() =>
                    handlePutButtonClick(e._id, {
                      doc_fixed: !e.doc_fixed,
                    })
                  }
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default AnnounceTableComponent;
