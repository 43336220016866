import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Modal, Stack, Table } from "react-bootstrap";
import CenterLogActionsComponent from "component/log/log-actions-comp";
import CenterLogTableComponent from "component/log/log-table-comp";
import { checkPermission, fetchGet } from "utils/functions";
import FranchLogActionsComponent from "component/log/franch-log-actions-comp";

function FranchAssignedLog() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(new Map());
  const [contentRefresh, setContentRefresh] = useState({});

  const myFranchId = sessionStorage.getItem("_id");

  useEffect(() => {
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }
  }, []);

  function refreshContent(obj) {
    console.log(obj);
    if (!obj._id) return;
    setContentRefresh(obj);
  }

  return (
    <Container>
      <Stack>
        <FranchLogActionsComponent
          selected={selected}
          contentRefresh={refreshContent}
        >
          <h2>내가 작성한 정비 이력</h2>
          <h6>이력을 터치해 선택할 수 있습니다.</h6>
        </FranchLogActionsComponent>
        <CenterLogTableComponent
          setSelected={setSelected}
          contentRefresh={contentRefresh}
          requestUrl={`/log/byf/${myFranchId}`}
        />
      </Stack>
    </Container>
  );
}

export default FranchAssignedLog;
