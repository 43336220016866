import CenterTableComponent from "component/center-table-comp";
import React from "react";
import { fetchGet } from "utils/functions";

function KeywordTableComponent({ setSelected, contentRefresh }) {
  const defaultKeywordTitles = new Map([
    ["keyword_group", { title: "그룹", visible: true, sorted: 1 }],
    ["keyword_name", { title: "대분류", visible: true }],
    ["createdAt", { title: "생성일", visible: true }],
    ["updatedAt", { title: "수정일", visible: true }],
    ["_id", { title: "db_id", visible: false }],
  ]);

  return (
    <CenterTableComponent
      tableMetadata={{ name: "keywordTable" }}
      contentRefresh={contentRefresh}
      setSelected={setSelected}
      matchedTitle={defaultKeywordTitles}
      getDataPromise={async () => {
        const res = await fetchGet(`/keyword`);
        if (!res.ok) throw new Error("Error in fetch");
        const header = {};
        const data = await res.json();
        return { header, data };
      }}
      processPromise={(data, titleMap) => {
        return new Promise(async (resolve) => {
          const outputData = [];
          for (const row of data) {
            const newMapped = new Map();
            for (const key of titleMap.keys()) {
              // process
              if (key === "createdAt" || key === "updatedAt") {
                newMapped.set(key, row[key]?.slice(0, 10) ?? "");
              } else if (
                !titleMap.get(key).generated &&
                row[key] === undefined
              ) {
                newMapped.set(key, "-");
              } else {
                newMapped.set(key, row[key] || "-");
              }
            }
            outputData.push(newMapped);
          }

          resolve(outputData);
        });
      }}
    />
  );
}

export default KeywordTableComponent;
