import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchPost } from "utils/functions";

function Login({ setTitleName = (_) => {} }) {
  const loginButtonRef = useRef(null);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [save, setSave] = useState({
    saveId: !!localStorage.getItem("saveId"),
    savePw: !!localStorage.getItem("savePw"),
  });

  useEffect(() => {
    if (save.saveId) {
      const newId = localStorage.getItem("saveId");
      setId(newId);
    }
    if (save.savePw) {
      const newPw = localStorage.getItem("savePw");
      setPw(newPw);
    }
  }, []);

  function handleIdChange(event) {
    setId(event.target.value);
  }

  function handlePwChange(event) {
    setPw(event.target.value);
  }

  function handleLoginButtonClick(event) {
    event.preventDefault();
    if (!(id.length > 0 && pw.length > 0)) {
      console.error("ID or Password is Empty!");
      return;
    }

    fetchPost(`/login`, {
      user_id: id,
      user_passwd: pw,
    })
      .then(async (res) => {
        if (res.status === 200) {
          return res.json();
        }
        const text = await res.text();
        alert(text);
        throw new Error(text);
      })
      .then((data) => {
        if (save.saveId) {
          localStorage.setItem("saveId", id);
        } else {
          localStorage.removeItem("saveId");
        }
        if (save.savePw) {
          localStorage.setItem("savePw", pw);
        } else {
          localStorage.removeItem("savePw");
        }

        sessionStorage.clear();
        Object.keys(data).forEach((key) => {
          sessionStorage.setItem(key, data[key]);
        });
        const permissionData = data.user_type;
        setTitleName(data.user_name);
        switch (permissionData) {
          case "FRANCH":
          case "CENTER":
            navigate(`/${permissionData[0].toLowerCase()}/main`, {
              replace: false,
            });
            break;
          case "LEASE":
            alert("현재 리스사 로그인은 불가능합니다");
            break;
          default:
            alert("정의되지 않은 유저타입입니다");
            break;
        }
      })
      .catch((err) => console.error(`${err}`));
  }

  return (
    <div className="mt-5">
      <h1>
        <b>진바이크 모빌리티 정비 네트워크</b>
      </h1>
      <h3 className="mb-5">고객관리시스템</h3>
      <Form gap={3} onSubmit={handleLoginButtonClick} className="mx-auto mt-3">
        <Container className="col-md-4">
          <Row>
            <Col>
              <Form.Control
                className="mb-3"
                type="text"
                placeholder="ID"
                onChange={handleIdChange}
                value={id}
              />
            </Col>
            <Col xs={1}>
              <Form.Check
                className="mt-2"
                defaultChecked={save.saveId}
                onChange={(e) =>
                  setSave((prev) => {
                    prev.saveId = e.target.checked;
                    return prev;
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                className="mb-3"
                type="password"
                placeholder="Password"
                onChange={handlePwChange}
                value={pw}
              />
            </Col>
            <Col xs={1}>
              <Form.Check
                className="mt-2"
                defaultChecked={save.savePw}
                onChange={(e) =>
                  setSave((prev) => {
                    prev.savePw = e.target.checked;
                    return prev;
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="w-50" type="submit" ref={loginButtonRef}>
                로그인
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default Login;
