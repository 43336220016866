import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Stack,
  Form,
  InputGroup,
} from "react-bootstrap";
import { utils, writeFile } from "xlsx";
import CenterSidebarComponent from "../component/center-sidebar-comp";
import { fetchGet, homeLink, usingStorageForName } from "../utils/functions";

function CenterSettings() {
  const [downloadValue, setDownloadValue] = useState({
    from: "",
    to: "",
  });

  return (
    <Container fluid>
      <Row>
        <Col md={2} sm={6} xs={6} className="m-auto mt-2">
          <CenterSidebarComponent />
          <hr />
        </Col>
        <Col className="min-vh-100">
          <Stack gap={3}>
            <h2>설정</h2>
            <hr />
            <div>
              <p>이름-dbid 매칭 리셋, id와 이름이 맞지 않을때 사용합니다.</p>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  usingStorageForName.forEach((e) => {
                    localStorage.removeItem(e);
                  });
                  alert("Done.");
                }}
              >
                Name Storage Clear
              </Button>
            </div>
            <hr />
            <div>
              <p>
                브라우저에 임시로 저장된 정보를 모두 삭제합니다. -&gt; 완료 후
                로그아웃됩니다.
              </p>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  sessionStorage.clear();
                  localStorage.clear();
                  alert("완료. 로그아웃됩니다.");
                  document.location.href = homeLink;
                }}
              >
                All Storage Clear
              </Button>
            </div>
            <hr />
            <div>
              <p>
                모든 정비이력을 연결해 다운받습니다. 서버에 부하를 주며, 엑셀
                형태로 받아오게 됩니다.
              </p>
              <Stack className="ms-4 me-4" direction="horizontal" gap={3}>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">시작일시</InputGroup.Text>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => {
                      setDownloadValue((prev) => {
                        return { ...prev, from: e.target.value };
                      });
                    }}
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroup.Text id="basic-addon1">종료일시</InputGroup.Text>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => {
                      setDownloadValue((prev) => {
                        return { ...prev, to: e.target.value };
                      });
                    }}
                  />
                </InputGroup>
                <Button
                  onClick={(event) => {
                    fetchGet(
                      `/analytics/fulllogs?from=${downloadValue.from}&to=${downloadValue.to}`
                    ).then(async (res) => {
                      const data = await res.json();
                      const worksheet = utils.json_to_sheet(data);
                      const workbook = utils.book_new();
                      utils.book_append_sheet(workbook, worksheet, "my_sheet");
                      writeFile(workbook, "json_to_excel.xlsx");
                    });
                  }}
                >
                  Download
                </Button>
              </Stack>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default CenterSettings;
