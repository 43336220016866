import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { fetchGet } from "utils/functions";

function AnalyticsBikeComponent() {
  const [fetchedData, setData] = useState({});

  const category = [
    {
      key: "TYPE_NORMAL".toLowerCase(),
      kr: "일반",
    },
    {
      key: "TYPE_ACCIDENT".toLowerCase(),
      kr: "사고",
    },
    {
      key: "TYPE_GUARANTEE".toLowerCase(),
      kr: "보증AS",
    },
    {
      key: "TYPE_MAINTAIN".toLowerCase(),
      kr: "운영리스정비",
    },
    {
      key: "distinctLogUser",
      kr: "정비등록 유저수",
    },
    {
      key: "fullLogCount",
      kr: "정비등록 건수",
    },
  ];

  useEffect(() => {
    (async () => {
      const res = await fetchGet("/analytics/logs");
      if (!res.ok) return;
      const logData = await res.json();
      setData({ ...logData });
    })();
  }, []);

  return (
    <>
      <h2>정비기록 통계</h2>
      <hr />
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>전일~현재</th>
            <th>당월</th>
            <th>전월</th>
            <th>누적</th>
          </tr>
        </thead>
        <tbody>
          {category.map((e) => {
            if (!fetchedData.todayCount) return <tr key={e.key} />;
            return (
              <tr key={e.key}>
                <th>{e.kr}</th>
                <td>{fetchedData.todayCount[e.key]}</td>
                <td>{fetchedData.monthlyCount[e.key]}</td>
                <td>{fetchedData.lastMonthCount[e.key]}</td>
                <td>{fetchedData.fullCount[e.key]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default AnalyticsBikeComponent;
