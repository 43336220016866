import ItemGroupFindComponent from "component/itemgroup/itemgroup-find-comp";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchPost, fetchPut } from "utils/functions";

/**
 * TODO: querystring을 수정해서 refresh해도 동일한 모델의 테이블을 볼 수 있도록 (다시 고르는 일이 없도록)
 * // 현재 URL 쿼리 문자열 가져오기
const queryString = window.location.search;

// URLSearchParams를 사용하여 객체 형태로 변환
const urlParams = new URLSearchParams(queryString);

// 객체 수정
urlParams.set('param1', 'value1');
urlParams.set('param2', 'value2');

// 새 URL 쿼리 문자열 생성
const newQueryString = urlParams.toString();

// 새 URL 생성
const newUrl = window.location.pathname + '?' + newQueryString;

// 브라우저 히스토리에 새 URL 추가
window.history.pushState({}, '', newUrl);
 */

/**
 *
 * @param props
 * selected
 * @returns
 */
function CenterItemActionsComponent({
  selected = new Map(),
  contentRefresh = (refreshed) => {},
}) {
  const navigate = useNavigate();
  const [isItemSelected, setItemSelected] = useState(false);
  const [showAdd, setAdd] = useState(false);
  const [showFix, setFix] = useState(false);
  const [showGroupSearch, setGroupSearch] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [groupText, setGroupText] = useState("");

  useEffect(() => {
    setItemSelected(selected.has("_id"));
  }, [selected]);

  // #region Add modal
  function handleAddModalOpenButtonClick() {
    setAdd(true);
    setFormValues({ item_number: 0 });
    setGroupText("");
  }

  function handleFormValueChangeBase(toChange) {
    setFormValues((prevState) => {
      return { ...prevState, ...toChange };
    });
  }

  function handleAddFormValueChange(event) {
    handleFormValueChangeBase({ [event.target.name]: event.target.value });
  }

  function handleAddFormSubmitButtonClick(event) {
    event.preventDefault();

    console.log(formValues);

    fetchPost("/item", {
      ...formValues,
    })
      .then(async (res) => {
        if (res.ok) {
          setAdd(false);
          alert("추가가 완료되었습니다");
          navigate(0);
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => console.error(err));
  }
  // #endregion

  // #region Fix modal
  function handleFixModalOpenButtonClick() {
    setFix(true);
    setFormValues(Object.fromEntries(selected));
    const igroup = selected.get("itemgroup_info");
    if (igroup) {
      setGroupText(`${igroup.group_name} / ${igroup.group_class}`);
    }
  }

  function handleFixFormSubmitButtonClick(event) {
    event.preventDefault();

    fetchPut(`/item/${selected.get("_id")}`, formValues)
      .then(async (res) => {
        if (res.ok) {
          setFix(false);
          contentRefresh(formValues);
          alert("수정이 완료되었습니다");
        } else {
          throw new Error(await res.text());
        }
      })
      .catch((err) => console.error(err));
  }
  // #endregion

  function handleDeleteButtonClick() {
    if (
      window.confirm(`${selected.get("_id")}를 제거합니다.\n계속 하시겠습니까?`)
    ) {
      fetchDelete(`/item/${selected.get("_id")}`)
        .then((res) => {
          if (res.ok) {
            alert(`정비품목이 삭제되었습니다 (id: ${selected.get("_id")})`);
            navigate(0);
          } else {
            throw new Error(res.text());
          }
        })
        .catch((err) => console.error(err));
    }
  }

  return (
    <>
      <h2>정비품목 현황</h2>
      <Container className="mb-2">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Button variant="primary" onClick={handleAddModalOpenButtonClick}>
              추가
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleFixModalOpenButtonClick}
            >
              수정
            </Button>
          </Col>
          <Col md="auto">
            <Button
              disabled={!isItemSelected}
              variant="primary"
              onClick={handleDeleteButtonClick}
            >
              삭제
            </Button>
          </Col>
        </Row>
      </Container>

      {/** add log modal */}
      <Modal backdrop="static" show={showAdd} onHide={() => setAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>정비 품목 추가하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="group_id">
              <Form.Label>정비 그룹 *</Form.Label>
              <Button
                className="ms-2 mb-2"
                onClick={() => setGroupSearch(true)}
              >
                선택
              </Button>
              <Form.Control
                disabled
                value={groupText}
                placeholder="위에서 선택해주세요"
              />
            </Form.Group>

            <Form.Group controlId="item_number">
              <Form.Label>정비 품목 번호 *</Form.Label>
              <Form.Control
                name="item_number"
                onChange={handleAddFormValueChange}
                value={formValues.item_number ?? 0}
              />
            </Form.Group>

            <Form.Group controlId="item_name">
              <Form.Label>정비 품목명</Form.Label>
              <Form.Control
                name="item_name_en"
                onChange={handleAddFormValueChange}
                value={formValues.item_name_en ?? ""}
                placeholder="영문명"
              />
              <Form.Control
                name="item_name_kr"
                onChange={handleAddFormValueChange}
                value={formValues.item_name_kr ?? ""}
                placeholder="한글명 *"
              />
            </Form.Group>

            <Form.Group controlId="item_company_partid">
              <Form.Label>제조사 부품번호</Form.Label>
              <Form.Control
                name="item_company_partid"
                onChange={handleAddFormValueChange}
                value={formValues.item_company_partid ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="jinbike_dist_code">
              <Form.Label>물류코드</Form.Label>
              <Form.Control
                name="jinbike_dist_code"
                onChange={handleAddFormValueChange}
                value={formValues.jinbike_dist_code ?? ""}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddFormSubmitButtonClick}>
            저장
          </Button>
          <Button variant="secondary" onClick={() => setAdd(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {/** fix log modal */}
      <Modal backdrop="static" show={showFix} onHide={() => setFix(false)}>
        <Modal.Header closeButton>
          <Modal.Title>정비 품목 수정하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="group_id">
              <Form.Label>정비 그룹</Form.Label>
              <Button
                className="ms-2 mb-2"
                onClick={() => setGroupSearch(true)}
              >
                선택
              </Button>
              <Form.Control
                disabled
                value={groupText}
                placeholder="위에서 선택해주세요"
              />
            </Form.Group>

            <Form.Group controlId="item_number">
              <Form.Label>정비 품목 번호</Form.Label>
              <Form.Control
                name="item_number"
                onChange={handleAddFormValueChange}
                value={formValues.item_number ?? 0}
              />
            </Form.Group>

            <Form.Group controlId="item_name">
              <Form.Label>정비 품목명</Form.Label>
              <Form.Control
                name="item_name_en"
                onChange={handleAddFormValueChange}
                value={formValues.item_name_en ?? ""}
                placeholder="영문명"
              />
              <Form.Control
                name="item_name_kr"
                onChange={handleAddFormValueChange}
                value={formValues.item_name_kr ?? ""}
                placeholder="한글명"
              />
            </Form.Group>

            <Form.Group controlId="item_company_partid">
              <Form.Label>제조사 부품번호</Form.Label>
              <Form.Control
                name="item_company_partid"
                onChange={handleAddFormValueChange}
                value={formValues.item_company_partid ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="item_amount_per_bike">
              <Form.Label>적용수량</Form.Label>
              <Form.Control
                type="number"
                name="item_amount_per_bike"
                onChange={handleAddFormValueChange}
                value={formValues.item_amount_per_bike ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="item_is_providing">
              <Form.Label>공급</Form.Label>
              <Form.Check
                type="switch"
                name="item_is_providing"
                onChange={(e) => {
                  handleFormValueChangeBase({
                    [e.target.name]: e.target.checked,
                  });
                }}
                defaultChecked={formValues.item_is_providing}
              />
            </Form.Group>

            <Form.Group controlId="item_is_compatible">
              <Form.Label>호환</Form.Label>
              <Form.Check
                type="switch"
                name="item_is_compatible"
                onChange={(e) => {
                  handleFormValueChangeBase({
                    [e.target.name]: e.target.checked,
                  });
                }}
                defaultChecked={formValues.item_is_compatible}
              />
            </Form.Group>

            <Form.Group controlId="item_providing_start">
              <Form.Label>공급시작일</Form.Label>
              <Form.Control
                type="date"
                name="item_providing_start"
                onChange={handleAddFormValueChange}
                value={formValues.item_providing_start ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="item_providing_end">
              <Form.Label>공급종료일</Form.Label>
              <Form.Control
                type="date"
                name="item_providing_end"
                onChange={handleAddFormValueChange}
                value={formValues.item_providing_end ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="jinbike_dist_code">
              <Form.Label>물류코드</Form.Label>
              <Form.Control
                name="jinbike_dist_code"
                onChange={handleAddFormValueChange}
                value={formValues.jinbike_dist_code ?? ""}
              />
            </Form.Group>

            <Form.Group controlId="item_working_time">
              <Form.Label>작업시간</Form.Label>
              <Form.Control
                type="number"
                name="item_working_time"
                onChange={handleAddFormValueChange}
                value={formValues.item_working_time ?? 0}
              />
            </Form.Group>

            <Form.Group controlId="jinbike_supply_price">
              <Form.Label>공급가</Form.Label>
              <Form.Control
                type="number"
                name="jinbike_supply_price"
                onChange={handleAddFormValueChange}
                value={formValues.jinbike_supply_price ?? 0}
              />
            </Form.Group>

            <Form.Group controlId="customer_price">
              <Form.Label>소비자가</Form.Label>
              <Form.Control
                type="number"
                name="customer_price"
                onChange={handleAddFormValueChange}
                value={formValues.customer_price ?? 0}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleFixFormSubmitButtonClick}>
            저장
          </Button>
          <Button variant="secondary" onClick={() => setFix(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {
        // #region item group search modal
      }
      <Modal
        backdrop="static"
        show={showGroupSearch}
        onHide={() => setGroupSearch(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>분류 그룹 검색</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemGroupFindComponent
            onFound={(found) => {
              handleFormValueChangeBase({
                bike_model_id: found.bike_model_id,
                group_id: found._id,
              });
              setGroupText(`${found.group_name} / ${found.group_class}`);
              setGroupSearch(false);
            }}
          />
        </Modal.Body>
      </Modal>
      {
        // #endregion
      }
    </>
  );
}

export default CenterItemActionsComponent;
