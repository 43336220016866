import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LogEditFormComponent from "component/log/logedit-form-comp";
import { checkPermission } from "utils/functions";
import { Container } from "react-bootstrap";

function FranchLogAdd() {
  const { bike_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkPermission("FRANCH")) {
      navigate("/", { replace: false });
    }
  }, []);

  return (
    <Container className="text-align-left">
      <LogEditFormComponent
        onSubmit={() => {
          navigate(`/f/bike/${bike_id}`);
        }}
        defaultData={{
          bike_id,
          fix_location: sessionStorage.getItem("_id"),
          fix_location_name: sessionStorage.getItem("user_name"),
        }}
      />
    </Container>
  );
}

export default FranchLogAdd;
